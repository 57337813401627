import React, {Component} from "react";
import {
	Form, ModalBody, ModalFooter, Alert, CardBody, Card,
} from "reactstrap";
import {reduxForm} from "redux-form";
import {
	WhFieldTxt,
	WhFieldTranslate
} from "../../../components/Form/whFields";
import LaddaButton, {EXPAND_LEFT} from "react-ladda";
import {getLocal} from "../../../reducers/params";
import {connect} from "react-redux";


class TypeForm extends Component {

	render = () => {
		const {error, handleSubmit, submitting, local} = this.props;

		return (

			<Form onSubmit={handleSubmit} className="form-vertical">
				<ModalBody>
					<Card>
						<CardBody>

							<WhFieldTranslate
								label="Nom :"
								name="name"
								component={WhFieldTxt}
								require
								type="text"
								translatable={local}
							/>
							{error && (
								<Alert color="danger">
									<div dangerouslySetInnerHTML={{__html: error}}/>
								</Alert>
							)}
						</CardBody>

					</Card>
				</ModalBody>

				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type="submit"
					>
						<i className="fa fa-save"/>
						&nbsp;Enregistrer
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	};
}

const mapStateToProps = state => ({
	local: getLocal(state),
});

TypeForm = connect(
	mapStateToProps,
)(TypeForm);

export default reduxForm({
	form: "TypeForm",
})(TypeForm);
