import React, { Component } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import CreateEditPopup from "./CreateEditPopup";
import WHTable from "../../components/WHTable";
import { toast } from "react-toastify";
import { Card, CardHeader, Button } from "reactstrap";
import * as userService from "../../services/user";
import { getRoles } from "../../reducers/params";

class Users extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			col: [],
			pages: null,
			loaded: true,
		};

		this.load = this.load.bind(this);
		this.columns = this.columns.bind(this);
		this.remove = this.remove.bind(this);
		this.newData = this.newData.bind(this);
	}

	columns() {
		return [
			{
				Header: "",
				accessor: "id",
				filterable: false,
				Cell: row => (
					<div>
						<Button
							color={"info"}
							onClick={() =>
								this.refs.createEditPopup.handleOpen(row.value)
							}
						>
							<i className={"fa fa-edit"} />
						</Button>
						<Button color={'success'} onClick={() => this.sendNewPassword(row.value)}>
						<i className={'fa fa-send'}/>
						</Button>
						<Button
							color={"danger"}
							onClick={() => this.remove(row.value)}
						>
							<i className={"fa fa-trash"} />
						</Button>
					</div>
				),
				maxWidth: 120,
			},
			{
				Header: "Prénom",
				accessor: "firstname",
			},
			{
				Header: "Nom",
				accessor: "lastname",
			},
			{
				Header: "Email",
				accessor: "email",
			},
			{
				Header: "Role(s)",
				accessor: "roles",
				Cell: row => <div>{row.value.join()}</div>,
			},
		];
	}

	load(state) {
		this.setState({
			loaded: false,
		});

		return userService.search(state).then(data => {
			this.setState(
				{
					data: [],
				},
				() => {
					this.setState({
						data: data["hydra:member"].filter(user => user.email != 'si@globalexploitation.com'),
						pages: Math.ceil(
							data["hydra:totalItems"] / state.pageSize,
						),
						loaded: true,
					});
				},
			);
		});
	}

	sendNewPassword(id) {
		if (
			window.confirm(
				"Êtes-vous sûr de vouloir envoyer un nouveau mot de passe ?",
			)
		) {
			this.setState({
				loaded: false,
			});

			userService
				.sendNewPassword(id)
				.then(() => {
					this.setState({
						loaded: true,
					});

					toast.success("Un noueau mot de passe a été envoyé");
				})
				.catch(() => {
					this.setState({
						loaded: true,
					});

					toast.error("Une erreur est survenue");
				});
		}
	}

	remove(toDelete) {
		if (window.confirm("Êtes-vous sûr de vouloir supprimer ce membre ?")) {
			this.setState({
				loaded: false,
			});

			userService
				.remove(toDelete)
				.then(() => {
					let lst = [...this.state.data];

					let index = lst.findIndex(el => el.id === toDelete);

					if (index > -1) {
						lst.splice(index, 1);

						this.setState({
							data: lst,
							loaded: true,
						});
					}
				})
				.catch(() => {
					alert(
						"Il existe des dépendances sur membre, il ne peut donc être supprimé",
					);
				});
		}
	}

	newData(data) {
		let lst = [...this.state.data];

		let index = lst.findIndex(el => el.id === data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({
			data: lst,
		});
	}

	render() {
		const { data, pages } = this.state;
		const { roles } = this.props;

		return (
			<div className="animated fadeIn">
				<CreateEditPopup
					ref={"createEditPopup"}
					newData={this.newData}
					roles={roles}
				/>
				<h1>Gestion des membres</h1>
				<Card>
					<CardHeader>
						<i className="fa fa-align-justify" />
						&nbsp;Gestion des membres
						<div className="card-actions">
							<button
								onClick={() =>
									this.refs.createEditPopup.handleOpen()
								}
								className={"btn btn-success"}
							>
								<i className={"fa fa-plus"} />
								&nbsp;Nouveau
							</button>
						</div>
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable={false}
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
				</Card>
			</div>
		);
	}
}

Users.defaultProps = {};

Users.propTypes = {
	roles: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
	roles: getRoles(state),
});

export default connect(mapStateToProps)(Users);
