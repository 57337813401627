import { fetch } from "../../utils/fetch";
import QueryBuilder from "../../components/WHTable/queryBuilder";
import configApi from "../../constants/ApiConfig";

const endPoints = "/api/lot_imports";

export const search = data => {
	const query = QueryBuilder(data);

	return fetch(`${configApi.url}${endPoints}`, "get", query);
};

export const view = id => fetch(`${configApi.url}${endPoints}/${id}`, "get");
export const reload = id =>
	fetch(`${configApi.url}${endPoints}/reload/${id}`, "get");

export const create = data =>
	fetch(`${configApi.url}${endPoints}`, "post", data);

export const update = data =>
	fetch(`${configApi.url}${endPoints}/${data.id}`, "put", data);

export const importPois = (csv, id) =>
	fetch(`${configApi.url}${endPoints}/import-pois/${id}`, "post", csv);

export const remove = id =>
	fetch(`${configApi.url}${endPoints}/${id}`, "delete");
