const parse = value => {
	if (value === undefined) return null;
	if (value === "") return null;

	if (Object.prototype.toString.call(value) == "[object Object]") {
		value.id = value.value;
		return value;
	}

	if (Object.prototype.toString.call(value) == "[object Array]") {
		if (value) value.map(el => (el.id = el.value));
		return value;
	}
};
export default parse;
