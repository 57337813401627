import React, { Component } from "react";
import EditPopup from "./PopupItem/PopupEdit";
import Chilren from "./Items";
import { Button, ListGroup, ListGroupItem } from "reactstrap";

class Items extends Component {
	render = () => {
		const { items, menu } = this.props;

		return (
			<ListGroup>
				<EditPopup
					ref="editPopup"
					newData={this.props.newData}
					menu={menu}
				/>

				{items.map((item, index) => (
					<ListGroupItem key={index}>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								alignItem: "center",
							}}
						>
							<div>
								<Button
									size="xs"
									color="primary"
									onClick={() =>
										this.refs.editPopup.handleOpen(item)
									}
								>
									<i className="fa fa-edit" />
								</Button>
								<Button
									size="xs"
									color="success"
									onClick={() =>
										this.refs.editPopup.handleOpen({}, item)
									}
								>
									<i className="fa fa-plus" />
								</Button>
								<Button
									size="xs"
									color="danger"
									onClick={() => this.props.remove(item.id)}
								>
									<i className="fa fa-trash" />
								</Button>
							</div>
							<div style={{ flex: 1, padding: "0 10px" }}>
								({item.position}) {item.title}
							</div>
							<div style={{ flex: 1, padding: "0 10px" }}>
								{item.page && item.page.title}
							</div>
							<div>
								<Button
									size="xs"
									onClick={() =>
										this.props.position("down", item, index)
									}
								>
									<i className="fa fa-sort-down" />
								</Button>
								<Button
									size="xs"
									onClick={() =>
										this.props.position("up", item, index)
									}
								>
									<i className="fa fa-sort-up" />
								</Button>
							</div>
						</div>

						{item.childrens && item.childrens.length > 0 && (
							<Chilren
								items={item.childrens}
								remove={this.props.remove}
								position={this.props.position}
								newData={this.props.newData}
							/>
						)}
					</ListGroupItem>
				))}
			</ListGroup>
		);
	};
}

export default Items;
