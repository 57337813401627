import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, ModalBody, ModalFooter, Alert, Row, Col } from "reactstrap";
import { Field, reduxForm, getFormValues } from "redux-form";
import {
	WhFieldTxt,
	WhFieldSelect,
	WhFieldTranslate,
} from "../../components/Form/whFields";
import { getLocal, getContactTypes } from "../../reducers/params";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { connect } from "react-redux";

class ContactForm extends Component {
	state = {
		open: false,
	};

	render() {
		const {
			error,
			handleSubmit,
			submitting,
			currentValues,
			contactTypes,
			local,
		} = this.props;

		return (
			<Form onSubmit={handleSubmit} className="form-vertical">
				<ModalBody>
					<Field
						label="Type de contact"
						name="contactType.id"
						component={WhFieldSelect}
						valueName="label"
						options={contactTypes}
					/>

					{(!currentValues.contactType ||
						currentValues.contactType.id != "1") && (
						<WhFieldTranslate
							label="Nom affiché sur la page contact : "
							name="name"
							component={WhFieldTxt}
							translatable={local}
							type="text"
						/>
					)}

					<Field
						label="Email de destination : "
						name="email"
						component={WhFieldTxt}
						type="text"
					/>

					<Field
						label="Téléphone : "
						name="phone"
						component={WhFieldTxt}
						type="text"
					/>

					{currentValues.contactType &&
						currentValues.contactType.id == "1" && (
							<WhFieldTranslate
								label="Surtaxe ? : "
								name="surtaxeField"
								component={WhFieldTxt}
								type="text"
								translatable={local}
								placeholder="N°INDIGO - 0,15 € TTC/min"
							/>
						)}

					{currentValues.contactType &&
						currentValues.contactType.id == "1" && (
							<Field
								label="Adresse : "
								name="address"
								component={WhFieldTxt}
								type="textarea"
							/>
						)}
					{currentValues.contactType &&
						currentValues.contactType.id == "1" && (
							<Row>
								<Col>
									<Field
										label="Latitude : "
										name="latitude"
										component={WhFieldTxt}
										type="text"
									/>
								</Col>
								<Col>
									<Field
										label="Longitude : "
										name="longitude"
										component={WhFieldTxt}
										type="text"
									/>
								</Col>
							</Row>
						)}

					{(!currentValues.contactType ||
						currentValues.contactType.id != "1") && (
						<Field
							label="Priorité d'apparition en liste de contacts (1 = en premier) : "
							name="priority"
							component={WhFieldTxt}
							parse={value => {
								return parseInt(value);
							}}
						/>
					)}

					{error && (
						<Alert color="danger">
							<div
								dangerouslySetInnerHTML={{
									__html: error,
								}}
							/>
						</Alert>
					)}
				</ModalBody>

				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type={"submit"}
					>
						<i className="fa fa-save" />
						&nbsp;Enregistrer
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

const formName = "FormContact";

const rxf = reduxForm({ form: formName })(ContactForm);

ContactForm.defaultProps = {
	error: "",
	currentValues: {},
	local: null,
	contactTypes: [],
};
ContactForm.propTypes = {
	error: PropTypes.string,
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	currentValues: PropTypes.shape({}),
	contactTypes: PropTypes.array.isRequired,
	local: PropTypes.string,
};

export const mapStateToProps = state => ({
	currentValues: getFormValues(formName)(state),
	local: getLocal(state),
	contactTypes: getContactTypes(state),
});

export default connect(mapStateToProps)(rxf);
