import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";

class LogPopup extends Component {
	state = {
		open: false,
		data: {},
	};

	handleOpen = data => {
		this.setState({
			open: true,
			data,
		});
	};

	handleClose = () => {
		this.setState({
			open: false,
		});
	};

	render() {
		const { open, data } = this.state;
		return (
			<Modal isOpen={open} className="sm" fade={false}>
				<ModalHeader toggle={() => this.handleClose()}>
					Logs
				</ModalHeader>
				<pre>{JSON.stringify(data.log)}</pre>
			</Modal>
		);
	}
}

export default LogPopup;
