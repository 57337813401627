import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import * as service from '../../../services/rgpd/contact';
import {SubmissionError} from 'redux-form';
import {toast} from 'react-toastify';
import Loading from '../../../components/Loading';

class SubmitPopup extends Component {


    state = {
        open: false,
        data: {},
        loaded: true
    };


    handleOpen = (id = null) => {
        this.setState({
            open: true,
			data : {id : id}
        });
    };

    handleClose = () => {
        this.setState({
            open: false,
            data: {}
        });
    };

    onSubmit = () => {
        return service.remove(this.state.data.id).then((data) => {

        	this.props.newData(data);
            toast.success('Enregistrement réussi');

            this.handleClose();
        }).catch((error) => {
            throw new SubmissionError(error);
        });
    };

    render() {
        const {className} = this.props;
        const {loaded, open} = this.state;

        return (

            <Modal isOpen={open} className={className} fade={false}>

                <ModalHeader toggle={this.handleClose}>Editer</ModalHeader>

                {!loaded &&
                	<Loading/>
                }

				{loaded &&
					<ModalBody>
						<p>Supprimer les informations ? </p>
						<Button color={"success"} onClick={this.onSubmit}>Valider</Button>
						<Button color={"danger"} onClick={this.handleClose}>Annuler</Button>
					</ModalBody>
                }

            </Modal>

        );

    }
}

export default SubmitPopup
