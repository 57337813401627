import React, { Component } from "react";
import { Form, Col, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { ModalBody, ModalFooter } from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import Icomoon from "../../../components/Form/whFields/FormBuilder/tplts/icomoon";
import { connect } from "react-redux";
import FieldTranslate from "../../../components/Form/whFields/FieldTranslate";
import PropTypes from "prop-types";
import { getLocal } from "../../../reducers/params";
import {
	WhFieldCheckbox,
	WhFieldTxt,
	WhFieldSelect,
} from "../../../components/Form/whFields";
import { WhParseNull } from "../../../components/Form/whFields";

class ServicesForm extends Component {
	state = {
		open: false,
	};

	render = () => {
		const { local, handleSubmit, submitting } = this.props;

		return (
			<Form onSubmit={handleSubmit} className="form-vertical">
				<ModalBody>
					<Field
						label="Picto :"
						name="picto"
						component={Icomoon}
						type="text"
					/>

					<FieldTranslate
						label="Nom :"
						name="name"
						translatable={local}
						component={WhFieldTxt}
						type="text"
					/>

					<FieldTranslate
						label="
                         Descriptif du service étudiant :"
						name="studentContent"
						component={WhFieldTxt}
						type="textarea"
						translatable={local}
					/>

					<FieldTranslate
						label="
                         Descriptif du service parent :"
						name="parentsContent"
						component={WhFieldTxt}
						type="textarea"
						translatable={local}
					/>

					<FieldTranslate
						label="
                         Descriptif du service général :"
						name="generalContent"
						component={WhFieldTxt}
						type="textarea"
						translatable={local}
					/>
					<Field
						label="Inclu ou en option :"
						name="isInclude"
						nullValue="Choisir"
						component={WhFieldSelect}
						options={[
							{ value: true, name: "Inclus" },
							{ value: false, name: "En option" },
						]}
					/>
					<Row>
						<Col lg={6}>
							<label>Etudiant ou parent ? :</label>
						</Col>
						<Col lg={6}>
							<Row>
								<div className="form-check form-check-inline">
									<Field
										name="pushParents"
										label="Parents"
										component={WhFieldCheckbox}
										parse={WhParseNull}
									/>
								</div>
								<div className="form-check form-check-inline">
									<Field
										name="pushStudent"
										label="Etudiant"
										component={WhFieldCheckbox}
										parse={WhParseNull}
									/>
								</div>
							</Row>
						</Col>
					</Row>
					<Field
						label="Push accueil"
						name="pushAccueil"
						component={WhFieldCheckbox}
						parse={WhParseNull}
					/>
				</ModalBody>

				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type={"submit"}
					>
						<i className="fa fa-save"> </i>
						&nbsp;Enregistrer
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	};
}

ServicesForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	local: PropTypes.string,
};
ServicesForm.defaultProps = {
	error: "",
};

const mapStateToProps = state => ({
	local: getLocal(state),
});

ServicesForm = connect(mapStateToProps)(ServicesForm);

export default reduxForm({
	form: "ServicesForm",
})(ServicesForm);
