import React, { Component } from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	getLocal,
	getLocals,
	getDefaultLocal,
} from "../../../reducers/params";
import ApiConfig from "../../../constants/ApiConfig";
import WHTable from "../../../components/WHTable";
import PopupCreate from "./PopupCreate/PopupCreate";
import { Card, CardHeader, Button } from "reactstrap";
import * as postService from "../../../services/cmsPost";
import { toast } from "react-toastify";
import CopyFilter from "../../../utils/copyFilters";

class Posts extends Component {
	_popupCreate = React.createRef();

	state = {
		data: [],
		col: [],
		pages: null,
		loaded: true,
		filters: [],
	};

	columns = () => {
		const { locals, local } = this.props;
		return [
			{
				Header: "",
				accessor: "id",
				filterable: false,
				Cell: row => (
					<div>
						<a
							href={`${ApiConfig.url}/redirect/post/${row.value}`}
							className="btn btn-info"
							target="_blank"
						>
							<i className="fa fa-eye" />
						</a>
						<Button
							color="primary"
							onClick={() => this.showdetails(row.value)}
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							color="danger"
							onClick={() => this.remove(row.value)}
						>
							<i className="fa fa-trash" />
						</Button>
					</div>
				),
				maxWidth: 120,
			},
			{
				Header: "titre de l’actualité",
				accessor: "title",
			},
			{
				Header: "Score",
				accessor: "score[gte]",
				Cell: row => (
					<div style={{ textAlign: "center" }}>
						{row.original.score}
					</div>
				),
			},
			{
				Header: "Push Ca se passe ...",
				accessor: "pushHomeNow",
				Cell: row => (
					<div style={{ textAlign: "center" }}>
						{row.value && <i className="fa fa-check" />}
					</div>
				),
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%", height: "30px" }}
						value={filter ? filter.value : ""}
					>
						<option value="">Tous</option>
						<option value="1">Oui</option>
						<option value="0">non</option>
					</select>
				),
			},
			{
				Header: "Push Populaire",
				accessor: "pushHomePopular",
				Cell: row => (
					<div style={{ textAlign: "center" }}>
						{row.value && <i className="fa fa-check" />}
					</div>
				),
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%", height: "30px" }}
						value={filter ? filter.value : ""}
					>
						<option value="">Tous</option>
						<option value="1">Oui</option>
						<option value="0">non</option>
					</select>
				),
			},
			{
				Header: "Push Side Bar Blog",
				accessor: "pushHomePopular",
				Cell: row => (
					<div style={{ textAlign: "center" }}>
						{row.value && <i className="fa fa-check" />}
					</div>
				),
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%", height: "30px" }}
						value={filter ? filter.value : ""}
					>
						<option value="">Tous</option>
						<option value="1">Oui</option>
						<option value="0">non</option>
					</select>
				),
			},
			{
				Header: "Push Side Doc Blog",
				accessor: "pushSideBarDocBlog",
				Cell: row => (
					<div style={{ textAlign: "center" }}>
						{row.value && <i className="fa fa-check" />}
					</div>
				),
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%", height: "30px" }}
						value={filter ? filter.value : ""}
					>
						<option value="">Tous</option>
						<option value="1">Oui</option>
						<option value="0">non</option>
					</select>
				),
			},
			{
				Header: "date de publication",
				accessor: "publicationDate",
				Cell: row => <Moment format="DD/MM/YYYY">{row.value}</Moment>,
				maxWidth: 150,
				filterable: false,
			},
			{
				Header: "date de fin de publication",
				accessor: "publicationDateEnd",
				Cell: row => <Moment format="DD/MM/YYYY">{row.value}</Moment>,
				maxWidth: 150,
				filterable: false,
			},
			{
				Header: "Langues",
				filterable: false,
				Cell: row => {
					return (
						<div style={{ textAlign: "right" }}>
							{locals.map((lng, index) => (
								<Button
									key={index}
									color={
										local === lng ? row.original.status === 'published'
											? "success"
											: "danger"
											: row.original.translate && row.original.translate[lng] ? row.original.translate[lng].status === 'published'
											? "success"
											: "danger"
											: "danger"
									}
								>
									{lng.toUpperCase()}
								</Button>
							))}
						</div>
					);
				},
			},
		];
	};

	showdetails = id => {
		this.props.history.push(`/posts/edit/${id}`);
	};

	remove = toDelete => {
		if (
			window.confirm("Etes vous sûre de vouloir supprimer cette page ?")
		) {
			this.setState({ loaded: false });

			postService
				.remove(toDelete)
				.then(data => {
					const lst = [...this.state.data];

					const index = lst.findIndex(el => el.id == toDelete);

					if (index > -1) {
						lst.splice(index, 1);

						this.setState({ data: lst, loaded: true });
					}

					toast.success("Page supprimée");
				})
				.catch(error => {
					alert(
						"Il existe des dépendances sur cette page, elle ne peut donc être supprimée",
					);
				});
		}
	};

	load = async (state, instance) => {
		this.setState({ loaded: false, filters: state });

		return await postService.search(state).then(data => {
			this.setState({ data: [] }, () => {
				this.setState({
					data: data["hydra:member"],
					pages: Math.ceil(data["hydra:totalItems"] / state.pageSize),
					loaded: true,
				});
			});
		});
	};

	render = () => {
		const { data, pages } = this.state;
		const { locals, defaultLocal } = this.props;

		return (
			<div className="animated fadeIn">
				<PopupCreate
					ref={this._popupCreate}
					onCreate={this.showdetails}
					defaultLocal={defaultLocal}
					locals={locals}
				/>
				<h1>Vos articles</h1>
				<Card>
					<CardHeader>
						<i className="fa fa-align-justify" /> Gestion des
						articles
						<div className="card-actions">
							<button
								onClick={() =>
									CopyFilter(postService, this.state.filters)
								}
								className={"btn btn-success"}
							>
								<i className={"fa fa-copy"} />
								&nbsp;Copier les filtres
							</button>
							<button
								onClick={() =>
									this._popupCreate.current.handleOpen()
								}
							>
								<i className="fa fa-plus" /> Créer
							</button>
						</div>
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
				</Card>
			</div>
		);
	};
}

Posts.defaultProps = {
	locals: [],
	defaultLocal: "",
};

Posts.propTypes = {
	locals: PropTypes.array,
	defaultLocal: PropTypes.string,
};

const mapStateToProps = state => ({
	locals: getLocals(state),
	local: getLocal(state),
	defaultLocal: getDefaultLocal(state),
});

export default connect(mapStateToProps)(Posts);
