import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
	getCmsStatus,
	getDefaultLocal,
	getLocal,
	getLocals,
} from "../../reducers/params";
import WHTable from "../../components/WHTable";
import {Card, CardHeader, Button} from "reactstrap";
import * as residenceService from "../../services/residence";
import ApiConfig from "../../constants/ApiConfig";
import CopyFilter from "../../utils/copyFilters";

class Residences extends Component {
	state = {
		data: [],
		pages: null,
		loaded: true,
		isFetching: 0,
	};

	columns() {
		const {cmsStatus, locals, local} = this.props;
		const viewUrl = ApiConfig.url + "/fr/logement-etudiant/";
		return [
			{
				Header: "",
				accessor: "id",
				filterable: false,
				Cell: row => (
					<div>
						<Button
							color="info"
							onClick={() =>
								this.props.history.push(
									`${this.props.match.path}/${row.value}`,
								)
							}
						>
							<i className="fa fa-edit"/>
						</Button>
						<Button
							color="danger"
							onClick={() => this.remove(row.value)}
						>
							<i className="fa fa-trash"/>
						</Button>
					</div>
				),
				maxWidth: 130,
			},
			{
				Header: "Nom de la résidence",
				accessor: "name",
			},
			{
				Header: "Ville associée",
				accessor: "address.city.title",
			},

			{
				Header: "Push Accueil",
				accessor: "pushHome",
				Cell: row => (
					<div style={{textAlign: "center"}}>
						{row.value && <i className="fa fa-check"/>}
					</div>
				),
				Filter: ({filter, onChange}) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{
							width: "100%",
							height: "30px"
						}}
						value={filter ? filter.value : ""}
					>
						<option value="">Tous</option>
						<option value="yes">Oui</option>
						<option value="no">non</option>
					</select>
				),
			},
			{
				Header: "Statut",
				accessor: "status",
				Cell: row => (
					<div style={{textAlign: "center"}}>{row.value}</div>
				),
				Filter: ({filter, onChange}) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{
							width: "100%",
							height: "30px"
						}}
						value={filter ? filter.value : ""}
					>
						<option value="">Tous</option>
						{cmsStatus.map((el, index) => (
							<option key={index} value={el.value}>
								{el.name}
							</option>
						))}
					</select>
				),
			},
			{
				Header: "Langues",
				filterable: false,
				Cell: row => {
					return (
						<div style={{textAlign: "right"}}>
							{locals.map((lng, index) => (
								<Button
									key={index}
									color={
										local === lng ? row.original.status === 'published'
											? "success"
											: "danger"
											: row.original.translate && row.original.translate[lng] ? row.original.translate[lng].status === 'published'
											? "success"
											: "danger"
											: "danger"
									}
								>
									{lng.toUpperCase()}
								</Button>
							))}
						</div>
					);
				},
			},
		];
	}

	// load = state => {
	// 	this.setState({
	// 		loaded: false,
	// 		filters: state,
	// 	});
	//
	// 	return residenceService.search(state).then(data => {
	// 		this.setState(
	// 			{
	// 				data: [],
	// 			},
	// 			() => {
	// 				this.setState({
	// 					data: data["hydra:member"],
	// 					pages: Math.ceil(
	// 						data["hydra:totalItems"] / state.pageSize,
	// 					),
	// 					loaded: true,
	// 				});
	// 			},
	// 		);
	// 	});
	// };

	load = async state => {

		// const updatedIsFetching = [...this.state.isFetching, true];
		// const idx = this.state.isFetching.length;
		const rd = Math.random();

		this.setState({
			loaded: false,
			filters: state,
			isFetching: rd,
		});

		const promises = [];
		promises.push(residenceService.search(state));
		Promise.all(promises).then(values => {
			if (this.state.isFetching !== rd) return;

				this.setState({
					data: values[0]['hydra:member'],
					pages: Math.ceil(
						values[0]["hydra:totalItems"] / state.pageSize,
					),
					loaded: true,
					// isFetching: updatedIsFetching,
				})
		})
		// .then( 	data => {
		// 	// console.log({isFetching: this.state.isFetching})
		// 	this.setState(prevState => {
		// 			console.log({isFetching: prevState.isFetching})
		//
		// 			return {date: []};
		// 		},
		// 		() => {
		// 			// updatedIsFetching[idx] = false;
		// 			this.setState({
		// 				data: data["hydra:member"],
		// 				pages: Math.ceil(
		// 					data["hydra:totalItems"] / state.pageSize,
		// 				),
		// 				loaded: true,
		// 				// isFetching: updatedIsFetching,
		// 			})
		// 		});
		// }


		// ));
	};

	remove = toDelete => {
		if (
			window.confirm("Êtes-vous sûr de vouloir supprimer cette ville ?")
		) {
			this.setState({
				loaded: false,
			});

			residenceService
				.remove(toDelete)
				.then(() => {
					const lst = [...this.state.data];

					const index = lst.findIndex(el => el.id === toDelete);

					if (index > -1) {
						lst.splice(index, 1);

						this.setState({
							data: lst,
							loaded: true,
						});
					}
				})
				.catch(() => {
					alert(
						"Il existe des dépendances sur membre, il ne peut donc être supprimé",
					);
				});
		}
	};

	newData = data => {
		const lst = [...this.state.data];

		const index = lst.findIndex(el => el.id === data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({
			data: lst,
		});
	};

	render = () => {
		const {data, pages} = this.state;


		return (
			<div className="animated fadeIn">
				<h1>Résidences</h1>
				<Card>
					<CardHeader>
						<i className="fa fa-align-justify"/>
						&nbsp;Gestion des résidences
						<div className="card-actions">
							<button
								onClick={() =>
									CopyFilter(
										residenceService,
										this.state.filters,
									)
								}
								className={"btn btn-success"}
							>
								<i className={"fa fa-copy"}/>
								&nbsp;Copier les filtres
							</button>
							<button
								onClick={() =>
									this.props.history.push("/residences/creer")
								}
								className="btn btn-primary"
							>
								<i className={"fa fa-plus"}/>
								&nbsp;Nouveau
							</button>
						</div>
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable={true}
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
				</Card>
			</div>
		);
	};
}

Residences.defaultProps = {
	local: null,
	defaultLocal: null,
};

Residences.propTypes = {
	local: PropTypes.string,
	defaultLocal: PropTypes.string,
	locals: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
	local: getLocal(state),
	defaultLocal: getDefaultLocal(state),
	locals: getLocals(state),
	cmsStatus: getCmsStatus(state),
});

export default connect(mapStateToProps)(Residences);
