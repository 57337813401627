import React from "react";
import { Button } from "reactstrap";

class History extends React.Component {
	onChange = e => {
		this.setState({ value: e.target.value });
	};

	onCharge = () => {
		this.props.onChange(this.state.value);
	};

	render = () => {
		const { value } = this.props;

		return (
			<div
				className=""
				style={{
					position: "absolute",
					padding: "10px 20px",
					width: "200px",
					height: "100px",
					background: "#f8f9fa",
					zIndex: 1,
					top: "46px",
					right: 0,
					display: "flex",
					justifyContent: "center",
					border: "1px solid #d1d4d7",
					borderTop: "none",
					borderRight: "none",
					flexDirection: "column",
				}}
			>
				<textarea onChange={this.onChange}>{value}</textarea>

				<Button
					type="button"
					color="success"
					size="xs"
					block
					onClick={() => this.onCharge()}
				>
					Charger
				</Button>
			</div>
		);
	};
}
export default History;
