import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import FormEdit from "./Form";
import { SubmissionError } from "redux-form";
import { toast } from "react-toastify";
import Loading from "../../../../components/Loading";
import * as menuItemService from "../../../../services/cmsMenuItem";
import Locals from "../../../../components/Locals";

class EditPopup extends Component {
	state = {
		open: false,
		data: {},
	};

	handleOpen = (data = null, parent = null) => {
		this.setState({ loaded: true, data, open: true, parent });
	};

	handleClose = () => {
		this.setState({ open: false, data: null, parent: null });
	};

	onSubmit = async data => {
		const { menu } = this.props;
		const { parent } = this.state;

		if (menu) data.menu = menu["@id"];
		if (parent) data.parent = parent["@id"];

		const edit = !data.id ? menuItemService.create : menuItemService.update;

		const newData = await edit(data);

		this.props.newData(newData);

		toast.success("Enregistrement réussi");

		this.handleClose();
	};

	render() {
		const { className } = this.props;
		const { loaded } = this.state;

		return (
			<Modal isOpen={this.state.open} className={className} fade={false}>
				<ModalHeader toggle={() => this.handleClose()}>
					Editer <Locals />
				</ModalHeader>

				{!loaded && <Loading />}
				{loaded && (
					<FormEdit
						onSubmit={this.onSubmit}
						initialValues={this.state.data}
					/>
				)}
			</Modal>
		);
	}
}

export default EditPopup;
