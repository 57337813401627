import React from "react";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300,
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const ChipSelect = ({ input: { value, onChange }, options }) => {
	const classes = useStyles();

	const theme = useTheme();

	function getStyles(name, value, theme) {
		return {
			fontWeight:
				value && value.indexOf(name) === -1
					? theme.typography.fontWeightRegular
					: theme.typography.fontWeightMedium,
		};
	}

	return options ? (
		<Select
			labelId="demo-mutiple-chip-label"
			id="demo-mutiple-chip"
			multiple
			value={value ? value : []}
			onChange={(stuff) => {
				if (onChange) {
					onChange(stuff);
				}
			}}
			input={<Input id="select-multiple-chip" />}
			renderValue={(selected) => {
				return (
					<div className={classes.chips}>
						{selected.map((value) => {
							const selectedOption = options
								? options.find((item) => item.value === value)
								: undefined;
							return (
								<Chip
									key={value}
									label={
										selectedOption
											? selectedOption.label
											: value
									}
									className={classes.chip}
								/>
							);
						})}
					</div>
				);
			}}
			MenuProps={MenuProps}
		>
			{options &&
				options.map(({ label, value: itemValue }) => (
					<MenuItem
						key={itemValue}
						value={itemValue}
						style={getStyles(itemValue, value, theme)}
					>
						{label}
					</MenuItem>
				))}
		</Select>
	) : null;
};

export default ChipSelect;
