import {fetch} from '../../utils/fetch';
import QueryBuilder from '../../components/WHTable/queryBuilder';
import configApi from '../../constants/ApiConfig';

const endPoints = '/api/lots';

export const search = (data) => {

    let query = QueryBuilder(data);

    return fetch(`${configApi.url}${endPoints}`, 'get', query)

};

export const view = (id) => fetch(`${configApi.url}${endPoints}/${id}`, 'get');

export const create = (data) => fetch(`${configApi.url}${endPoints}`, 'post', data);

export const update = (data) => fetch(`${configApi.url}${endPoints}/${data.id}`, 'put', data);

export const remove = (id) => fetch(`${configApi.url}${endPoints}/${id}`, 'delete',{});

export const setPositionPoint = (data) => fetch(`${configApi.url}${endPoints}/position`, 'post', data);