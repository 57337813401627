import React, {Component} from 'react';
import {Form} from 'reactstrap';
import {Field, reduxForm} from 'redux-form'
import {ModalBody, ModalFooter} from 'reactstrap';
import LaddaButton, {EXPAND_LEFT} from 'react-ladda';

import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {getLocal} from "../../../reducers/params";
import {WhFieldTxt, WhParseDate} from "../../../components/Form/whFields";


class RgpdForm extends Component {
    state = {
        open: false
    };

    render = () => {
        const {local, handleSubmit, submitting} = this.props;

        return (

            <Form onSubmit={handleSubmit} className="form-horizontal">

                <ModalBody>

					<Field
						label="Date de demande :"
						name="askDate"
						format={WhParseDate}
						component={WhFieldTxt}
						type="date"
					/>

                </ModalBody>

                <ModalFooter>

                    <LaddaButton
                        className="btn btn-success btn-ladda"
                        loading={submitting}
                        data-style={EXPAND_LEFT}
                        type={'submit'}
                    >
                        <i className="fa fa-save"> </i>
                        &nbsp;Enregistrer
                    </LaddaButton>

                </ModalFooter>

            </Form>

        );
    }
}

RgpdForm.propTypes = {
    error: PropTypes.shape({}).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    local: PropTypes.string
};
RgpdForm.defaultProps = {
    error: "",
};

const mapStateToProps = (state) => ({
    local: getLocal(state)
});

RgpdForm = connect(
    mapStateToProps
)(RgpdForm);

export default reduxForm({
    form: 'RgpdForm'
})(RgpdForm);
