import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Field } from "redux-form";
import {
	WhFieldTxt,
	WhFieldCheckbox,
	WhFieldTranslate,
	WhFieldSelect,
} from "../../../components/Form/whFields";

class SeoForm extends Component {
	test = () => {};

	render() {
		const { seoRobots, local } = this.props;

		return (
			<div>
				<WhFieldTranslate
					label="Balise title : "
					name="metaTitle"
					translatable={local}
					component={WhFieldTxt}
					type="text"
					bsSize="lg"
				/>

				<WhFieldTranslate
					label="Balise meta description : "
					name="metaDescription"
					translatable={local}
					component={WhFieldTxt}
					type="textarea"
				/>

				<Row>
					<Col md={6}>
						<Field
							label="META Robots : "
							name="robots"
							component={WhFieldSelect}
							options={seoRobots}
						/>
					</Col>
					<Col md={6}>
						<WhFieldTranslate
							label="Lien canonique (sans le domaine) : "
							name="canonical"
							translatable={local}
							component={WhFieldTxt}
							type="text"
						/>
					</Col>
				</Row>

				<Field
					label="Site map "
					name="siteMap"
					component={WhFieldCheckbox}
				/>
			</div>
		);
	}
}

export default SeoForm;
