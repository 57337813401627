import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
	Field,
	FormSection,
	reduxForm,
	change,
	getFormValues,
} from "redux-form";
import classnames from "classnames";
import {
	Label,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Form,
	Alert,
	Row,
	Col,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
} from "reactstrap";
import LaddaButton, {EXPAND_LEFT} from "react-ladda";
import Save from "../../components/Save";
import * as cityService from "../../services/city";
import {getCmsStatus, getSeoRobots} from "../../reducers/params";
import FormSeo from "../Cms/Seo/Form";
import {
	WhFieldTxt,
	WhFieldTranslate,
	WhFieldSelect,
	WhValidator,
	WhFieldSelectAsync,
	WhParseNull,
	WhFieldCkEditor, WhFieldFile
} from "../../components/Form/whFields";
import ApiConfig from "../../constants/ApiConfig";

class FormEdit extends React.Component {
	state = {
		activeTab: "1",
	};

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}


	loadCities = input => {
		return cityService
			.search({
				filtered: [{
					id: "page.title",
					value: input
				}]
			})
			.then(data => {
				const lst = data["hydra:member"].map(el => {
					return {
						id: el.id,
						label: `${el.label}`
					};
				});
				return {options: lst};
			});
	};

	render = () => {
		const {
			error,
			handleSubmit,
			submitting,
			initialValues,
			seoRobots,
			currentValues,
			changeFieldValue,
			cmsStatus,
			local,
		} = this.props;
		const viewUrl = ApiConfig.url + "/fr/logement-etudiant/";

		return (
			<Form onSubmit={handleSubmit}>
				<Row>
					<Col
						sm="8"
						xs={12}
						className="form-vertical"
						style={{marginTop: "7px"}}
					>
						<Card>
							<CardHeader>
								<Nav tabs>
									<NavItem>
										<NavLink
											className={classnames({
												active:
													this.state.activeTab ===
													"1",
											})}
											onClick={() => {
												this.toggle("1");
											}}
										>
											Contenu
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({
												active:
													this.state.activeTab ===
													"2",
											})}
											onClick={() => {
												this.toggle("2");
											}}
										>
											SEO
										</NavLink>
									</NavItem>
								</Nav>

								<Save
									data={currentValues}
									setHistory={changeFieldValue}
									type="city"
									copy={this.props.copy}
								/>
							</CardHeader>

							<TabContent activeTab={this.state.activeTab}>
								<TabPane tabId="1">
									<WhFieldTranslate
										name="name"
										label="Nom de l'école"
										component={WhFieldTxt}
										type="text"
										translatable={local}
										placeholder="Nom de l'école"
										bsSize="lg"
									/>
									<WhFieldTranslate
										name="title"
										label="Titre de la page"
										component={WhFieldTxt}
										type="text"
										translatable={local}
										placeholder="Titre de la page"
										bsSize="lg"
									/>
									<WhFieldTranslate
										name="slug"
										label="Slug"
										component={WhFieldTxt}
										type="text"
										translatable={local}
										placeholder="Slug"
										bsSize="Slug"
									/>
									<WhFieldTranslate
										name="acronym"
										label="Acronyme"
										component={WhFieldTxt}
										type="text"
										translatable={local}
										placeholder="Acronyme"
										bsSize="Acronyme"
									/>
									<Field
										label="logo"
										name="logo"
										withHeader={true}
										component={WhFieldFile}
										type="text"
										placeholder="Logo"
										bsSize="Logo"
									/>

									<Label>
										Description
									</Label>
									<WhFieldTranslate
										label="Description"
										name="description"
										component={WhFieldCkEditor}
										translatable={local}
									/>

									<Label>
										Infos pratiques
									</Label>
									<WhFieldTranslate
										label="Infos pratique"
										name="info"
										component={WhFieldCkEditor}
										translatable={local}
									/>
								</TabPane>
								<TabPane tabId="2">
									<FormSection name="seo">
										<FormSeo
											seoRobots={seoRobots}
											local={local}
										/>
									</FormSection>
								</TabPane>
							</TabContent>
						</Card>
					</Col>
					<Col sm="4" xs={12}>
						<Card>
							<CardHeader>
								<i className="fa fa-align-justify"/> Edition
							</CardHeader>

							<CardBody className="form-vertical">

								<WhFieldTranslate
									label="Statut de publication :"
									name="status"
									required
									validate={[WhValidator.required]}
									component={WhFieldSelect}
									type="select"
									options={cmsStatus}
									translatable={local}
								/>


								<Field
									label="Adresse : "
									name="address.street"
									validate={[WhValidator.required]}
									component={WhFieldTxt}
									type="text"
								/>

								<Field
									label="Complément d'adresse : "
									name="address.cplt"
									component={WhFieldTxt}
									type="text"
								/>

								<Field
									label="Code postal : "
									name="address.zipCode"
									validate={[WhValidator.required]}
									component={WhFieldTxt}
									type="text"
								/>

								<Field
									label="Ville :"
									name="address.cityName"
									component={WhFieldTxt}
									type="text"
								/>

								<Field
									label="Ville rattachée :"
									name="address.city"
									component={WhFieldSelectAsync}
									type="select"
									validate={[WhValidator.required]}
									loadOptions={this.loadCities}
									parse={WhParseNull}
								/>
								<Field
									label="Email : "
									name="email"
									component={WhFieldTxt}
									type="text"
								/>
								<Field
									label="Téléphone : "
									name="phone"
									validate={[WhValidator.required]}
									component={WhFieldTxt}
									type="text"
								/>
								<Field
									label="Fax : "
									name="fax"
									component={WhFieldTxt}
									type="text"
								/>
								<Field
									label="Latitude"
									name="latitude"
									component={WhFieldTxt}
									type="text"
								/>

								<Field
									label="Longitude"
									name="longitude"
									component={WhFieldTxt}
									type="text"
								/>
								{error && (
									<Alert color="danger">
										<div
											dangerouslySetInnerHTML={{
												__html: error,
											}}
										/>
									</Alert>
								)}
							</CardBody>
							<CardFooter>
								{
									initialValues && initialValues.address.city && (
										<div>
											<a
												href={viewUrl + initialValues.address.city.slug + "/" + initialValues.slug}
												target="_blank"
												className="btn btn-block btn-info btn-ld"
											>
												Voir
											</a>
											<br/>
										</div>
									)
								}

								<LaddaButton
									className="btn btn-success btn-ladda btn-lg"
									loading={submitting}
									data-style={EXPAND_LEFT}
									type="submit"
								>
									<i className="fa fa-save"/> Enregistrer{" "}
								</LaddaButton>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	};
}

const formName = "FormSchool";

const rxf = reduxForm({form: formName})(FormEdit);

FormEdit.defaultProps = {
	error: "",
	categories: [],
	cmsStatus: [],
	seoRobots: [],
	currentValues: {},
	locals: [],
};
FormEdit.propTypes = {
	error: PropTypes.string,
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	currentValues: PropTypes.shape({}),
	cmsStatus: PropTypes.array,
	seoRobots: PropTypes.array,
	locals: PropTypes.array,
};

export const mapStateToProps = state => ({
	currentValues: getFormValues(formName)(state),
	cmsStatus: getCmsStatus(state),
	seoRobots: getSeoRobots(state),
});

const mapDispatchToProps = dispatch => ({
	changeFieldValue: (field, value) =>
		dispatch(change(formName, field, value)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(rxf);
