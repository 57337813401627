import React, {Component} from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, ModalHeader, Modal } from 'reactstrap';
import {toast} from "react-toastify";
import Loading from "../../../components/Loading";
import ApiConfig from "../../../constants/ApiConfig";
import * as lotService from "../../../services/lot";
import classnames from 'classnames';



class ModalFloor extends Component {
    state = {
        open: false,
        floor: {},
        loaded: true,
        lot: null,
        positionLeft: null,
        positionTop: null,
        activeTab: '1',
        activeLot: null,
		hoveredLot : null,
        markers: {},
        lots: []
    };

    handleOpen = (floor) => {
        this.setState({
            open: true,
        });

        if (floor) {
            this.setState({
                loaded: false,
            });

            const markersData = [];
            let markerArray = {}
            floor.lots.filter(el => el.positionTop != null && el.positionLeft != null).map((el) => (
                markerArray = {
                    id : el.id,
                    positionLeft : el.positionLeft,
                    positionTop: el.positionTop
                },
                markersData.push(markerArray)
            ))
            
            this.setState({
                floor,
                lots: floor.lots,
                loaded: true,
                markers: markersData
            });
        }
    };

    handleClose = () => {
        this.setState({
            open: false,
            data: {},
        });
    };

    select = (lot) => {
        toast.success("Vous avez selectionne le lot numero" + lot.number);
        this.setState({
            lot,
            positionTop: null,
            positionLeft: null,
			activeLot: lot.id


		});

    };

    selectPoint = (e, lot) => {
        const markersData = this.state.markers
        const image = e.currentTarget;
        const rect = image.getBoundingClientRect();
        const positionLeftPixel = e.clientX - rect.left.toFixed();
        const positionTopPixel = e.clientY - rect.top.toFixed();

        const positionLeft = positionLeftPixel / image.clientWidth * 100;
        const positionTop = positionTopPixel / image.clientHeight * 100;

        if (lot){
            const index = markersData.findIndex(el => el.id == lot.id);
            const data = {
                id: lot.id,
                positionLeft: positionLeft,
                positionTop: positionTop
            }

            if (index > -1) {
                markersData[index] = data;
            } else {
                markersData.push(data);
            }

        }

        this.setState({
            markers: markersData, 
            positionLeft,
            positionTop,
			activeLot: null
         
        });
    }

    savePoint = async() => {
        const { lot, positionLeft, positionTop } = this.state;
        const  lst  = this.state.lots;

        this.setState({
            loaded: false,
        });

        const dataPosition= {
            'lot' : lot['id'],
            'positionLeft' : positionLeft,
            'positionTop':positionTop
        }

        try {
            await lotService.setPositionPoint(dataPosition);
            toast.success("Enregistrement réussi");

            const index = lst.findIndex(el => el.id == lot.id);
            lst[index] = { ...lot, positionLeft, positionTop };

            this.setState({
                loaded: true,
                lots : lst,
                lot: null
            });
        } catch (error) {
        }
    }

    

    getPosition = (lot) => {
       const style= {
            top: lot.positionTop + "%",
           left: lot.positionLeft + "%",
        }
        return style;
    }

    toggle = (tab) =>  {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    onMouseEnterHandler(lot) {
        this.setState({
			hoveredLot: lot.id
        });
    }

    onMouseLeaveHandler() {
        this.setState({
			hoveredLot: null
        });
    }

    render() {
        const {className} = this.props;
        const { loaded, open, floor, lot, positionLeft, positionTop, activeLot, markers, lots,hoveredLot } = this.state;
        const assetUrl = ApiConfig.finderParams.assetUrl;
		console.log("active poind render",activeLot)

        return (
            <Modal size='lg' isOpen={open} className={className} fade={false}>
                <ModalHeader toggle={this.handleClose}>
                    Placement des points
                </ModalHeader>

                {!loaded && <Loading/>}

                {loaded && lots && (
                    <div className="modal-content floor">
                        <div className='column'>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}
                                    >
                                       À placer
                                </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}
                                    >
                                      Déja placé
                                     </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">

									<div style={{ overflow: 'scroll', border: '#000000 1px solid', height: '650px' }}>
										<ul className="lot-list" >
											{lots.filter(el => el.positionTop == null || el.positionLeft == null).map((el) => (
												<li key={el.id}>
													<a
														className={lot ? el.id === lot.id ? "active" : "" : ""}
														href="#"
														onClick={(e) => { e.preventDefault(); this.select(el) }}
													>{el.number}
													</a>
												</li>
											)
											)}
										</ul>
									</div>
                                </TabPane>

								<TabPane tabId="2">
									<div style={{ overflow: 'scroll', border: '#000000 1px solid', height: '650px' }}>
										<ul className="lot-list" >
											{lots.filter(el => el.positionTop != null && el.positionLeft != null).map((el) => (
												<li key={el.id}>
													<a
														className={lot ? el.id === lot.id ? "active" : "" : ""}
														onMouseOver={() => this.onMouseEnterHandler(el)}
														onMouseLeave={() => this.onMouseLeaveHandler()}
														href="#"
														onClick={(e) => { e.preventDefault(); this.select(el) }}
													>{el.number}
													</a>
												</li>
											))}
										</ul>
									</div>
                                </TabPane>
                            </TabContent>
                        </div>
                        <div className='content'>
                            {
                                floor.plan && (
                                    <div id="image-container">
                                        {markers.map((el) => (
                                            // <div ref={`marker_${el.id}`} className={el.id === activeLot ? "markerFloor active" : "markerFloor"} key={el.id} style={this.getPosition(el)}></div>
                                            <div ref={`marker_${el.id}`} className={el.id === hoveredLot ? ("markerFloor active" ): (el.id === activeLot ? "markerFloor active" : "markerFloor")} key={el.id} style={this.getPosition(el)}></div>
                                        ))}
                                        <img className="image-modal" id="image-position" onClick={(e) => this.selectPoint(e, lot)} src={assetUrl + floor.plan.url} alt="" />
                                    </div>
                                )
                            }

                            {lot  && positionLeft && positionTop && (
                                <Button color="success" onClick={ () => this.savePoint()}>Enregister</Button>
                            )}
                        </div>
                    </div>
                )}
            </Modal>
        );
    }
}

export default ModalFloor;
