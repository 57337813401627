import React from "react";
import History from "./history";
import moment from "moment";

class Save extends React.Component {
	state = {
		toSave: [],
		historyOpen: false,
	};

	handleHistory = () => {
		const { historyOpen } = this.state;

		this.setState({ historyOpen: !historyOpen });
	};

	onHistoryChange = data => {
		const toCharge = JSON.parse(data);
		const { setHistory } = this.props;

		delete toCharge["@id"];
		delete toCharge["id"];
		if (toCharge.seo) delete toCharge.seo["id"];
		if (toCharge.seo) delete toCharge.seo["@id"];
		if (toCharge.address) delete toCharge.address["id"];
		if (toCharge.address) delete toCharge.address["@id"];
		if (toCharge.pushHome) delete toCharge.pushHome["id"];
		if (toCharge.pushHome) delete toCharge.pushHome["@id"];

		const fields = Object.keys(toCharge);
		fields.map(field => {
			setHistory(field, toCharge[field]);
		});

		this.setState({ historyOpen: false });
	};

	saveFile = () => {
		const { data, type } = this.props;

		const file = new Blob([JSON.stringify(data)], {
			type: "application/json",
		});

		const a = document.createElement("a");
		const url = URL.createObjectURL(file);
		const date = moment().format("DD-MM-YYYY-h:mm:ss");
		a.href = url;
		a.download = type ? `${type}-${date}.txt` : `undefined-${date}.txt`;
		document.body.appendChild(a);
		a.click();
		setTimeout(function() {
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url);
		}, 0);
	};

	render = () => {
		const { historyOpen } = this.state;

		return (
			<div className="card-actions">

				<button
					onClick={() =>
						this.props.copy('en')
					}
					className="btn "
				><i className={"fa fa-copy"} /> <img style={{width: "15px"}} src="/img/en.png" alt=""/> </button>
				<button
					onClick={() =>
						this.props.copy('zh')
					}
					className="btn "
				><i className={"fa fa-copy"} /> <img style={{width: "15px"}} src="/img/zh.png" alt=""/></button>
				<button
					type="button"
					className={"btn btn-success"}
					onClick={() => this.saveFile()}
				>
					<i className={"fa fa-download"} />
				</button>
				<button
					type="button"
					className={"btn btn-success"}
					onClick={() => this.handleHistory()}
				>
					<i className={"fa fa-upload"} />
				</button>

				{historyOpen && (
					<div style={{ position: "relative" }}>
						<History onChange={this.onHistoryChange} />
					</div>
				)}
			</div>
		);
	};
}
export default Save;
