import {ROLE_ADMIN, ROLE_RGPD} from "../../../constants/roles";

export default {
	items: [
		{
			name: "CMS",
			roles: ROLE_RGPD,
			icon: "fa fa-edit",
			children: [
				{
					name: "Pages",
					url: "/pages",
					roles: ROLE_RGPD,
				},
				{
					name: "Menus",
					url: "/menus",
					roles: ROLE_ADMIN,
				},
				{
					name: "Actualités",
					url: "/posts",
					roles: ROLE_ADMIN,
				},
				{
					name: "HelloBar",
					url: "/hellobar",
					roles: ROLE_ADMIN,
				},
				{
					name: "Seo",
					url: "/seos",
					roles: ROLE_ADMIN,
				},
				{
					name: "Templates",
					url: "/templates",
					roles: ROLE_ADMIN,
				},
			],
		},
		{
			name: "FAQ",
			roles: ROLE_ADMIN,
			icon: "fa fa-question",
			children: [
				{
					name: "Thématiques",
					roles: ROLE_ADMIN,
					url: "/faq_thematiques",
				},
				{
					name: "Cat. / Questions",
					roles: ROLE_ADMIN,
					url: "/faq_questions",
				},
			],
		},
		{
			name: "Villes",
			roles: ROLE_ADMIN,
			icon: "fa fa-building",
			url: "/villes",
		},
		{
			name: "Ecoles",
			roles: ROLE_ADMIN,
			icon: "fas fa-school",
			url: "/ecoles",
		},
		{
			name: "Presse",
			roles: ROLE_ADMIN,
			icon: "fas fa-school",
			url: "/presse",
		},
		{
			name: "Résidences",
			roles: ROLE_ADMIN,
			icon: "fa fa-home",
			children: [
				{
					name: "Résidences et lots",
					roles: ROLE_ADMIN,
					url: "/residences",
				},
				{
					name: "Flaps promotionnel",
					roles: ROLE_ADMIN,
					url: "/promotions",
				},
				{
					name: "Typologies",
					roles: ROLE_ADMIN,
					url: "/typologies",
				},
				{
					name: "Services",
					roles: ROLE_ADMIN,
					url: "/services",
				},
				{
					name: "Imports",
					roles: ROLE_ADMIN,
					url: "/lots/import",
				},
			],
		},
		{
			name: "Points d'intéret",
			roles: ROLE_ADMIN,
			icon: "fa fa-map-marker",
			children: [
				{
					name: "Gestion des poi",
					roles: ROLE_ADMIN,
					url: "/poi",
				},
			],
		},
		{
			name: "Paramètres",
			roles: ROLE_ADMIN,
			icon: "fa fa-cog",
			children: [
				{
					name: "Gestions des membres",
					roles: ROLE_ADMIN,
					url: "/users",
				},
				{
					name: "Gestions des contacts",
					roles: ROLE_ADMIN,
					url: "/contacts",
				},
				{
					name: "Types de contacts",
					roles: ROLE_ADMIN,
					url: "/contact-types",
				},
				{
					name: "Gestions des paramètres",
					roles: ROLE_ADMIN,
					url: "/parametres",
				},
				{
					name: "Liste des imports",
					roles: ROLE_ADMIN,
					url: "/imports",
				},

			],
		},
		{
			name: "Jobs",
			roles: ROLE_ADMIN,
			icon: "fa fa-hammer",
			children: [
				{
					name: "Gestion des annonces",
					roles: ROLE_ADMIN,
					url: "/jobs",
				},
				{
					name: "Gestion des type d'emploi",
					roles: ROLE_ADMIN,
					url: "/jobTypes",
				},
			],
		},
		{
			name: "RGPD",
			roles: ROLE_RGPD,
			icon: "fa fa-question",
			children: [
				{
					name: "Consentements",
					roles: ROLE_RGPD,
					url: "/consentements",
				},{
					name: "Contacts",
					roles: ROLE_RGPD,
					url: "/rgpd_contacts",
				},
				{
					name: "Reservations",
					roles: ROLE_RGPD,
					url: "/reservations",
				},
			],
		},
	],
};
