import React, { Component } from "react";
import ApiConfig from "../../../constants/ApiConfig";
import EditPopup from "./Popup/PopupEdit";
import Menu from "./Menu";
import { withRouter } from "react-router";
import {
	Card,
	CardHeader,
	Row,
	Col,
	Button,
	ListGroup,
	ListGroupItem,
} from "reactstrap";
import * as menuService from "../../../services/cmsMenu";
import { toast } from "react-toastify";
import Locals from "../../../components/Locals";

class Menus extends Component {
	refMenu = React.createRef();

	state = {
		data: [],
		menu: {},
		menuLoaded: true,
		loaded: true,
	};

	componentDidMount = () => {
		this.load();
	};

	showdetails = id => {
		this.props.history.push(`/menus/${id}`);
	};

	load = () => {
		this.setState({ loaded: false });

		return menuService.search().then(data => {
			this.setState({ data: [] }, () => {
				this.setState({
					data: data["hydra:member"],
					loaded: true,
				});
			});
		});
	};

	remove = toDelete => {
		if (window.confirm("Etes vous sûre de vouloir supprimer cet item ?")) {
			this.setState({ loaded: false });

			menuService
				.remove(toDelete)
				.then(data => {
					const lst = [...this.state.data];

					const index = lst.findIndex(el => el.id == toDelete);

					if (index > -1) {
						lst.splice(index, 1);

						this.setState({ data: lst, loaded: true });
					}

					toast.success("Item supprimée");
				})
				.catch(error => {
					alert(
						"Il existe des dépendances sur cette item, il ne peut donc être supprimé",
					);
				});
		}
	};

	newData = data => {
		const lst = [...this.state.data];

		const index = lst.findIndex(el => el.id == data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({ data: lst });
	};

	render = () => {
		const { match } = this.props;
		const { data, menu, menuLoaded } = this.state;

		return (
			<div className="animated fadeIn">
				<EditPopup ref="editPopup" newData={this.newData} />
				<h1>Gestion des menus</h1>
				<Row>
					<Col lg={4}>
						<Card>
							<CardHeader>
								<i className="fa fa-align-justify" /> Gestion
								des menus
								<div className="card-actions">
									<Button
										onClick={() =>
											this.refs.editPopup.handleOpen()
										}
									>
										<i className="fa fa-plus" /> Créer
									</Button>
									<a
										href={`${
											ApiConfig.helpAdmin
										}/cms/pages.html`}
										target="_blank"
									>
										<i className="fa fa-question" />
									</a>
								</div>
							</CardHeader>

							<ListGroup>
								{data.map((menu, index) => (
									<ListGroupItem
										key={index}
										active={
											menu.id == match.params.id
												? true
												: false
										}
										style={{ display: "flex" }}
									>
										<a
											style={{
												flex: 1,
												display: "block",
											}}
											href="javascript:void(0);"
											onClick={() =>
												this.refMenu.current.loadMenu(
													menu.id,
												)
											}
										>
											{menu.title}
										</a>
										<Button
											onClick={() =>
												this.refs.editPopup.handleOpen(
													menu,
												)
											}
											size="xs"
											color="primary"
										>
											<i className="fa fa-edit" />
										</Button>
										<Button
											onClick={() => this.remove(menu.id)}
											size="xs"
											color="danger"
										>
											<i className="fa fa-trash" />
										</Button>
									</ListGroupItem>
								))}
							</ListGroup>
						</Card>
					</Col>
					<Col lg={8}>
						<Menu ref={this.refMenu} />
					</Col>
				</Row>
			</div>
		);
	};
}

export default withRouter(Menus);
