const introduction = {
	tplt: "/page/faq.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Introduction",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content:
						"<h2>FAQ, Nous répondons </h2><h3>à toutes vos questions !</h3>",
				},
			},
			content: {
				admin: {
					template: "BlocModule",
					title: "Liste des FAQ's",
				},
			},
		},
	},
};

export default {
	template: "listmap",
	name: "Page FAQ",
	editable: false,
	add: false,
	seo: {
		robots: "index,follow",
	},
	builder: [introduction],
};
