import QueryBuilder from "../components/WHTable/queryBuilder";

export default (Service, data) => {
	const query = {
		service: Service.endPoints,
		filters: QueryBuilder(data),
	};

	const toCopy = JSON.stringify(query, null, 4);
	const copyText = document.getElementById("copyText");
	copyText.value = toCopy;
	copyText.select();
	document.execCommand("copy");
	alert("Filtre Copié !");
};
