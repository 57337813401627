import React, { Component } from "react";
import merge from "lodash/merge";
import PropTypes from "prop-types";
import { SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loading from "../../components/Loading";
import * as cityService from "../../services/city";
import CityForm from "./CityForm";
import { toast } from "react-toastify";
import Locals from "../../components/Locals";
import washJson from "../../utils/washJson";
import {
	getLocal,
	getLocals,
	setLocal,
	getDefaultLocal,
} from "../../reducers/params";
import cityTplt from "../../constants/tplt/city/city";
import * as residenceService from "../../services/residence";

class City extends Component {
	state = {
		loaded: false,
	};

	componentDidMount() {
		this.load();
	}

	load = async () => {
		this.setState({ loaded: false });
		const { defaultLocal, locals } = this.props;

		let data;

		if (this.props.match.params.id) {
			data = await cityService.view(this.props.match.params.id);

			data = merge(JSON.parse(JSON.stringify(cityTplt)), data);

			if (!data.translate) data.translate = {};

			locals.map(local => {
				if (local != defaultLocal) {
					if (!data.translate[local]) data.translate[local] = {};
					if (!data.translate[local].builder) {
						data.translate[local].builder = JSON.parse(
							JSON.stringify(cityTplt.builder),
						);
					}

					if (typeof data.translate[local].builder !== "object")
						data.translate[local].builder = washJson(
							data.translate[local].builder,
						);
				}
			});

			this.setState({
				data,
				loaded: true,
			});
		} else {
			data = JSON.parse(JSON.stringify(cityTplt));
			data.translate = {};

			if (data.builder) {
				locals.map(el => {
					if (el != defaultLocal)
						data.translate[el] = { builder: data.builder };
				});
			}

			this.setState({
				loaded: true,
				data,
			});
		}
	};

	copy = async (locale) =>
	{
		var localeWord = locale === 'en' ? 'l\'Anglais' : 'le Chinois';

		if (
			window.confirm("Voulez vous vraiment copier le contenu de la ville vers " + localeWord  + "? Si des traductions sur cette page existe elles seront supprimées")
		) {

			return residenceService.copy(this.state.data.id, locale)
				.then(() => {
					window.location.reload();
				})
				.catch(error => {
					throw new SubmissionError(error);
				});
		}
	}

	onSubmit = data => {
		const service = !data.id ? cityService.create : cityService.update;
		const caseForm = !data.id ? "Création réussie" : "Modification réussie";

		if (data.cityInfosPortfolio && data.cityInfosPortfolio.items)
			data.cityInfosPortfolio.items.map((el, index) => {
				el.position = index;
			});

		if (data.placesPortfolio && data.placesPortfolio.items)
			data.placesPortfolio.items.map((el, index) => {
				el.position = index;
			});

		return service(data)
			.then(() => {
				this.props.history.push("/villes");
				toast.success(caseForm);
			})
			.catch(error => {
				console.log(error);
				throw new SubmissionError(error);
			});
	};

	render() {
		const { loaded } = this.state;
		const { local, locals, defaultLocal } = this.props;

		if (!loaded) return <Loading />;

		return (
			<div className="animated fadeIn">
				<h1>Ville</h1>

				<Locals />
				<CityForm
					onSubmit={this.onSubmit}
					initialValues={this.state.data}
					local={local}
					locals={locals}
					defaultLocal={defaultLocal}
					copy={this.copy}
				/>
			</div>
		);
	}
}

City.defaultProps = {
	local: null,
	locals: [],
	defaultLocal: null,
};

City.propTypes = {
	local: PropTypes.string,
	locals: PropTypes.array,
	defaultLocal: PropTypes.string,
};

const mapStateToProps = state => ({
	local: getLocal(state),
	locals: getLocals(state),
	defaultLocal: getDefaultLocal(state),
});

export const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			setLocal,
		},
		dispatch,
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(City);
