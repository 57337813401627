import React, { Component } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card, CardHeader, Button } from "reactstrap";
import EditPopup from "./Popup/PopupEdit";
import WHTable from "../../../components/WHTable";
import * as tpltService from "../../../services/cmsTemplate";
import { setTemplates } from "../../../reducers/params";

class Templates extends Component {
	state = {
		data: [],
		pages: null,
		loaded: true,
	};

	columns = () => [
		{
			Header: "",
			accessor: "id",
			filterable: false,
			Cell: row => (
				<div>
					<Button
						color="info"
						onClick={() =>
							this.refs.editPopup.handleOpen(row.original)
						}
					>
						<i className="fa fa-edit" />
					</Button>
					<Button
						color="danger"
						onClick={() => this.remove(row.value)}
					>
						<i className="fa fa-trash" />
					</Button>
				</div>
			),
		},
		{
			Header: "Type",
			accessor: "type",
		},
		{
			Header: "Nom",
			accessor: "name",
		},
		{
			Header: "Parent",
			accessor: "parent.name",
		},
	];

	load = async (state, instance) => {
		this.setState({ loaded: false });

		const data = await tpltService.search(state);

		this.setState({ data: [] }, () => {
			this.setState({
				data: data["hydra:member"],
				pages: Math.ceil(data["hydra:totalItems"] / state.pageSize),
				loaded: true,
			});
		});
	};

	remove = async toDelete => {
		if (window.confirm("Etes vous sûre de vouloir supprimer cet item ?")) {
			this.setState({ loaded: false });

			await tpltService.remove(toDelete);

			const lst = [...this.state.data];

			const index = lst.findIndex(el => el.id == toDelete);

			if (index > -1) {
				lst.splice(index, 1);

				this.setState({ data: lst, loaded: true });
			}
			toast.success("Template supprimée");
		}
	};

	newData = async data => {
		const { setTemplates } = this.props;

		const lst = [...this.state.data];

		const index = lst.findIndex(el => el.id == data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({ data: lst });

		await setTemplates();
	};

	render = () => {
		const { data, pages } = this.state;

		return (
			<div className="animated fadeIn">
				<EditPopup ref="editPopup" newData={this.newData} />

				<Card>
					<CardHeader>
						<i className="fa fa-align-justify" /> Gestion des
						templates
						<div className="card-actions">
							<button
								onClick={() => this.refs.editPopup.handleOpen()}
							>
								<i className="fa fa-plus" /> Créer
							</button>
						</div>
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
				</Card>
			</div>
		);
	};
}

export const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			setTemplates,
		},
		dispatch,
	);

export default connect(
	null,
	mapDispatchToProps,
)(Templates);
