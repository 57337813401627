import React, { Component } from "react";
import { connect } from "react-redux";
import merge from "lodash/merge";
import PropTypes from "prop-types";
import Loading from "../../components/Loading";
import { bindActionCreators } from "redux";
import * as residenceService from "../../services/residence";
import * as serviceService from "../../services/service";
import { SubmissionError } from "redux-form";
import ResidenceForm from "./ResidenceForm";
import { toast } from "react-toastify";
import Locals from "../../components/Locals";
import washJson from "../../utils/washJson";

import {
	getLocal,
	getLocals,
	setLocal,
	getTemplates,
	getDefaultLocal,
} from "../../reducers/params";
import residenceTplt from "../../constants/tplt/residence/residence";
import * as pageService from "../../services/cmsPage";

class Residence extends Component {
	state = {
		loaded: false,
	};

	componentDidMount() {
		this.load();
	}

	load = async () => {
		this.setState({ loaded: false });
		const { defaultLocal, locals } = this.props;
		let data;

		if (this.props.match.params.id) {
			data = await residenceService.view(this.props.match.params.id);

			let floors = await residenceService.floors(this.props.match.params.id);

			data.floors = floors['hydra:member'];

			//Commenté car empêche l'edition de l'ordre des blocs
			data = merge(JSON.parse(JSON.stringify(residenceTplt)), data);
			if (data.services) {
				data.services = data.services.map(el => el["@id"]);
			}
			locals.map(local => {
				if (local != defaultLocal) {
					if (!data.translate) data.translate = {};
					if (!data.translate[local]) data.translate[local] = {};
					if (!data.translate[local].builder) {
						data.translate[local].builder = JSON.parse(
							JSON.stringify(residenceTplt.builder),
						);
					}

					if (typeof data.translate[local].builder !== "object")
						data.translate[local].builder = washJson(
							data.translate[local].builder,
						);
				}
			});

			this.setState({
				data: data,
				loaded: true,
			});
		} else {
			data = merge(JSON.parse(JSON.stringify(residenceTplt)), {});
			data.translate = {};

			if (data.builder) {
				locals.map(el => {
					if (el != defaultLocal)
						data.translate[el] = { builder: data.builder };
				});
			}
			this.setState({
				data: data,
				loaded: true,
			});
		}
		serviceService.search().then(data => {
			this.setState({
				services: data["hydra:member"].map(el => ({
					label: el.name,
					value: el["@id"],
				})),
			});
		});
	};

	copy = async (locale) =>
	{
		var localeWord = locale === 'en' ? 'l\'Anglais' : 'le Chinois';

		if (
			window.confirm("Voulez vous vraiment copier le contenu de la résidence vers " + localeWord  + "? Si des traductions sur cette page existe elles seront supprimées")
		) {

			return residenceService.copy(this.state.data.id, locale)
				.then(() => {
					window.location.reload();
				})
				.catch(error => {
					throw new SubmissionError(error);
				});
		}
	}

	onSubmit = data => {
		const service = !data.id
			? residenceService.create
			: residenceService.update;
		const caseForm = !data.id ? "Création réussie" : "Modification réussie";

		const updatedFeatures = data.typologyFeatures
			? data.typologyFeatures
					.filter(el => el.hasOwnProperty("number"))
					.map(element => {
						return {
							...element,
							typology: element.typology["@id"],
						};
					})
			: [];
		const newData = { ...data, typologyFeatures: updatedFeatures };
		return service(newData)
			.then(newData => {
				if (!data.id) this.props.history.push("/residences");
				toast.success(caseForm);
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	};

	render() {
		const { loaded, services, local, locals } = this.state;

		if (!loaded) return <Loading />;

		return (
			<div className="animated fadeIn">
				<h1>Résidence</h1>

				<Locals />
				<ResidenceForm
					onSubmit={this.onSubmit}
					initialValues={this.state.data}
					services={services}
					local={local}
					locals={locals}
					copy={this.copy}
				/>
			</div>
		);
	}
}

Residence.defaultProps = {
	local: null,
};

Residence.propTypes = {
	local: PropTypes.string,
	locals: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
	local: getLocal(state),
	locals: getLocals(state),
	defaultLocal: getDefaultLocal(state),
	templates: getTemplates(state),
});

export const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			setLocal,
		},
		dispatch,
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Residence);
