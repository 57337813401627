import React, { Component } from "react";
import { toast } from "react-toastify";
import WHTable from "../../../components/WHTable";
import { Card, CardHeader, Button } from "reactstrap";
import LogPopup from "./LogPopup";
import * as lotImport from "../../../services/lotImport";
import Moment from "react-moment";

class LotImport extends Component {
	_logPopup = React.createRef();

	state = {
		data: [],
		pages: null,
		loaded: true,
	};

	reload = async id => {
		const data = await lotImport.reload(id);
		toast.success("Import en cours ... ");

		const lst = [...this.state.data];
		const index = lst.findIndex(el => el.id === id);
		lst[index] = data;
		this.setState({
			data: lst,
		});
	};

	openLog = data => {
		this._logPopup.current.handleOpen(data);
	};

	remove = async toDelete => {
		if (window.confirm("Êtes-vous sûr de vouloir supprimer ce log ?")) {
			this.setState({
				loaded: false,
			});

			try {
				await lotImport.remove(toDelete);

				const lst = [...this.state.data];

				const index = lst.findIndex(el => el.id === toDelete);

				if (index > -1) {
					lst.splice(index, 1);

					this.setState({
						data: lst,
					});
				}
			} catch (error) {
				alert(
					"Il existe des dépendances sur membre, il ne peut donc être supprimé",
				);
			} finally {
				this.setState({
					loaded: true,
				});
			}
		}
	};

	columns() {
		return [
			{
				Header: "",
				accessor: "id",
				filterable: false,
				Cell: row => (
					<div>
						{/** MARCHE PAS, CREER UNE ERREUR SUB...
						{row.original.log && row.original.log.length > 0 && (
							<Button
								color="info"
								onClick={() => this.reload(row.value)}
							>
								<i className="fa fa-refresh" />
							</Button>
						)}
						 */}
						{row.original.log && row.original.log.length > 0 && (
							<Button
								color="warning"
								onClick={() => this.openLog(row.original)}
							>
								<i className="fa fa-eye" />
							</Button>
						)}
						<Button
							color="danger"
							onClick={() => this.remove(row.original.id)}
						>
							<i className="fa fa-trash" />
						</Button>
					</div>
				),
				maxWidth: 130,
			},
			{
				Header: "Type",
				accessor: "service",
			},
			{
				Header: "Date",
				accessor: "created",
				Cell: row => <Moment date={row.value} format="DD/MM/YYYY" />,
			},
			{
				Header: "Nbr",
				accessor: "nbr",
			},
			{
				Header: "Statut",
				accessor: "status",
			},
		];
	}

	load = state => {
		this.setState({
			loaded: false,
			filters: state,
		});

		return lotImport.search(state).then(data => {
			data["hydra:member"].map(el => {
				el.log = el.log ? JSON.parse(el.log) : null;
			});

			this.setState(
				{
					data: [],
				},
				() => {
					this.setState({
						data: data["hydra:member"],
						pages: Math.ceil(
							data["hydra:totalItems"] / state.pageSize,
						),
						loaded: true,
					});
				},
			);
		});
	};

	render = () => {
		const { data, pages } = this.state;

		return (
			<div className="animated fadeIn">
				<h1>Résidences</h1>

				<LogPopup ref={this._logPopup} />

				<Card>
					<CardHeader>
						<i className="fa fa-align-justify" />
						&nbsp;Lotgs des imports
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable={true}
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
				</Card>
			</div>
		);
	};
}

export default LotImport;
