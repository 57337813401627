export const paragraphe01 =
	'<h4>Sous-titre</h4><h3>Titre du bloc</h3><p><span class="icon-hover"></span></p><p>Etenim si attendere diligenter, </p><p><a class="btn button" data-hover="click me!" href="#"><span>D&eacute;couvrir</span></a></p>';

export const paragraphe02 =
	'<h4> Date</h4><h3>Titre</h3><p><span class="icon-hover"></span></p><p>Résumé</p><a href="#"><span class="icon-fleche-3"></span></a>';

export const paragraphe03 =
	'<h3>Praesent nulla sapien, aliquam vel odio id</h3><a class="btn button" data-hover="click me!" href="#"><span>D&eacute;couvrir</span></a>';

export const paragraphe04 =
	'<div><span class="icon-office" style="color:#000000;"></span></div><div class="content"><h4>Ceci est le titre de l’astuce</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta, a odit? Nostrum voluptates nemo dolores ipsum repellendus, laboriosam aut pariatur.</p></div >';
/*****************************
 * BLOCS SIMPLES
 *****************************/

/**
 * BLOC PARAGRAPHE TEXTE
 */
export const containerParagraphe1 = {
	admin: {
		template: "BlocContainer",
		title: "09 Paragraphe01",
		type: "tplt-paragraph",
		tplt: "/blocs/09-tplt-paragraphe.html.twig",
		fields: {
			paragraphe: {
				admin: {
					template: "BlocText1",
					type: "tplt-paragraph",
					content:
						'<div class="bloc-txt-1"><h1>Lorem ipsum<span class="bold"> dolor sit amet</span></h1><h3>Lorem ipsum dolor sit amet, consectetur adipisicing.</h3><p><b>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto quae veritatis fuga saepe sit dolor sed</b>, at labore fugiat culpa. Illum in dolorum beatae iste provident. Veniam a, officiis provident id autem esse! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident adipisci perspiciatis ducimus, dolorem nesciunt suscipit cumque sint maxime omnis repellendus corporis, id porro. Ipsum consequuntur quasi tempore, tenetur possimus qui blanditiis distinctio. Eveniet facere fugit excepturi ab iusto sapiente magni exercitationem minus soluta numquam tempora ratione libero, quia animi maxime.</p></div >',
				},
			},
		},
	},
};

export const containerParagraphe2 = {
	admin: {
		template: "BlocContainer",
		title: "10 Paragraphe02",
		type: "tplt-paragraph",
		tplt: "/blocs/09-tplt-paragraphe.html.twig",
		fields: {
			paragraphe: {
				admin: {
					template: "BlocText1",
					type: "tplt-paragraph",
					content:
						'<div class="bloc-txt-2"><h2>Lorem ipsum<span class="bold"> dolor sit amet</span></h2><h3>Lorem ipsum dolor sit amet, consectetur adipisicing.</h3><p><b>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto quae veritatis fuga saepe sit dolor sed</b>, at labore fugiat culpa. Illum in dolorum beatae iste provident. Veniam a, officiis provident id autem esse! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident adipisci perspiciatis ducimus, dolorem nesciunt suscipit cumque sint maxime omnis repellendus corporis, id porro. Ipsum consequuntur quasi tempore, tenetur possimus qui blanditiis distinctio. Eveniet facere fugit excepturi ab iusto sapiente magni exercitationem minus soluta numquam tempora ratione libero, quia animi maxime.</p></div >',
				},
			},
		},
	},
};

/**
 * BLOC PHOTO
 */
export const picture = {
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		type: "tplt-picture",
		tplt: "/blocs/tplt-picture.html.twig",
		title: "Photo",
		fields: {
			picture: {
				admin: {
					template: "BlocMedia",
					type: "tplt-picture",
				},
			},
			content: {
				admin: {
					template: "BlocText1",
					type: "tplt-paragraph",
					content: paragraphe01,
				},
			},
		},
	},
};

/**
 * BLOC VIDEO
 */
export const video = {
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		tplt: "/blocs/02-tplt-video.html.twig",
		type: "tplt-video",
		title: "02 Video",
		fields: {
			video: {
				admin: {
					template: "BlocMedia",
					type: "tplt-video",
				},
			},
			content: {
				admin: {
					template: "BlocText1",
					type: "tplt-paragraph",
					content: paragraphe01,
				},
			},
		},
	},
};

/**
 * BLOC VIDEO
 */
export const document = {
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		tplt: "/blocs/tplt-document.html.twig",
		type: "tplt-document",
		title: "Document",
		fields: {
			video: {
				admin: {
					template: "BlocMedia",
					type: "tplt-document",
				},
			},
			content: {
				admin: {
					template: "BlocText1",
					type: "tplt-paragraph",
					content: paragraphe01,
				},
			},
		},
	},
};

/*****************************
 * BLOCS CONTAINERS
 *****************************/

/**
 * PHOTO HAUT, TEXT BAS
 */
export const flapPictureTxt01 = {
	admin: {
		editable: true,
		add: false,
		tplt: "/blocs/flap-picture-txt01.html.twig",
		template: "BlocContainer",
		title: "Image haut text bas",
		fields: {
			picture: {
				admin: {
					template: "BlocMedia",
				},
			},
			content: {
				admin: {
					template: "BlocText1",
					content: paragraphe02,
				},
			},
		},
	},
};

export const flapPictureTxt02 = {
	admin: {
		editable: true,
		add: false,
		tplt: "/blocs/flap-picture-txt02.html.twig",
		template: "BlocContainer",
		title: "Text haut image bas",
		fields: {
			content: {
				admin: {
					template: "BlocText1",
					content: paragraphe02,
				},
			},
			picture: {
				admin: {
					template: "BlocMedia",
				},
			},
		},
	},
};

export const flapPictureTxt03 = {
	admin: {
		editable: true,
		add: false,
		tplt: "/blocs/flap-picture-txt01.html.twig",
		template: "BlocContainer",
		title: "Image haut text bas",
		fields: {
			picture: {
				admin: {
					template: "BlocMedia",
				},
			},
			content: {
				admin: {
					template: "BlocText1",
					content: paragraphe03,
				},
			},
		},
	},
};

export const flapPictureTxt04 = {
	admin: {
		editable: true,
		add: false,
		tplt: "/blocs/flap-txt02.html.twig",
		template: "BlocContainer",
		title: "Texte avec icone",
		fields: {
			content: {
				admin: {
					template: "BlocText1",
					content: paragraphe04,
				},
			},
		},
	},
};

/**
 * SLIDER A GAUCHE, PARAPGRAPHE A DROITE
 */
export const foliosLeftTextRight = {
	admin: {
		template: "BlocContainer",
		tplt: "/blocs/01-tplt-slider-photos-1txt.html.twig",
		title: "01 Portolfio Gauche 1 Text à droite",
		add: false,
		editable: true,
		fields: {
			content: {
				admin: {
					template: "BlocText1",
					content: paragraphe01,
				},
			},
			carrousel: {
				admin: {
					template: "BlocCollection",
					add: true,
					editable: true,
					type: "tplt-slider-horizontal",
					defaultTemplates: [picture],
				},
			},
		},
	},
};

/**
 * VIDEO A GAUCHE, PARAGRAPHE A DROITE
 */
export const videoLeftTextRight = {
	admin: {
		template: "BlocCollection",
		tplt: "/blocs/tplt-video-txt.html.twig",
		title: "1 Video Gauche 1 Text droite",
		type: "tplt-video-txt",
		add: true,
		editable: true,
		defaultTemplates: [video],
	},
};

/**
 * SLIDER TEXT + PHOTO
 */
export const sliderAstuce = {
	admin: {
		template: "BlocCollection",
		tplt: "/blocs/07-tplt-slider-astuces.html.twig",
		title: "07 Slider astuce",
		type: "tplt-slider-txt",
		add: true,
		editable: true,
		defaultTemplates: [flapPictureTxt04],
	},
};

/**
 * SLIDER FLAP
 */
export const sliderFlap01 = {
	admin: {
		template: "BlocCollection",
		tplt: "/blocs/03-tplt-slide-flap01.html.twig",
		title: "03 Liste de blocs slider",
		type: "tplt-slider-horizontal",
		add: true,
		editable: true,
		defaultTemplates: [flapPictureTxt01, flapPictureTxt02],
	},
};

/**
 * SLIDER FLAP NAVIGATION GAUCHE
 */

export const sliderNavLeftPhotoTxtFlap = {
	admin: {
		editable: true,
		add: false,
		tplt: "/blocs/flap-picture-txt01.html.twig",
		template: "BlocContainer",
		title: "Image haut text bas",
		fields: {
			picture: {
				admin: {
					template: "BlocMedia",
				},
			},
			content: {
				admin: {
					template: "BlocText1",
					content:
						"<h3>Praesent nulla sapien, aliquam vel odio id</h3>",
				},
			},
		},
	},
};

export const sliderNavLeftPhotoTxt = {
	admin: {
		template: "BlocCollection",
		tplt: "/blocs/08-tplt-slider-navleft-phototxt.html.twig",
		title: "O8 Slider nav gauche",
		type: "tplt-slider-horizontal",
		add: true,
		editable: true,
		defaultTemplates: [sliderNavLeftPhotoTxtFlap],
	},
};

/**
 * DEUX COLONNES
 */
export const twoColumns = {
	admin: {
		template: "BlocCollection",
		tplt: "/blocs/04-tplt-two-columns.html.twig",
		title: "04 Bloc deux colonnes",
		type: "tplt-two-columns",
		add: true,
		editable: true,
		defaultTemplates: [flapPictureTxt01, flapPictureTxt02],
	},
};

/**
 * TROIS COLONNES
 */
export const threeColumns = {
	admin: {
		template: "BlocCollection",
		tplt: "/blocs/05-tplt-tree-columns.html.twig",
		title: "05 Bloc trois colonnes",
		type: "tplt-three-columns",
		add: true,
		editable: true,
		defaultTemplates: [flapPictureTxt01, flapPictureTxt02],
	},
};

/**
 * QUATRE COLONNES
 */
export const fourColumns = {
	admin: {
		template: "BlocCollection",
		tplt: "/blocs/06-tplt-four-columns.html.twig",
		title: "06 Bloc quatres colonnes",
		type: "tplt-slider-horizontal",
		add: true,
		editable: true,
		defaultTemplates: [flapPictureTxt03],
	},
};

/**
 * LISTE DE DOCUMENTS
 */
export const documentsLists = {
	admin: {
		template: "BlocCollection",
		tplt: "/blocs/tplt-documents-list.html.twig",
		title: "Liste de documents",
		type: "tplt-document-list",
		add: true,
		editable: true,
		defaultTemplates: [document],
	},
};

export default {
	containerParagraphe1,
	containerParagraphe2,
	picture,
	video,
	foliosLeftTextRight,
	videoLeftTextRight,
	sliderAstuce,
	sliderFlap01,
	twoColumns,
	threeColumns,
	fourColumns,
	sliderNavLeftPhotoTxt,
	documentsLists,
};
