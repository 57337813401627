import React, {Component, Fragment} from 'react'

import {
	CardBody,
	CardHeader,
	Card,
} from "reactstrap";

class Calendly extends Component {
	render() {
		const url = this.props.url;
		if (!url)
			return <Fragment></Fragment>
		return (
			<Card>
				<CardHeader>Calendly</CardHeader>
				<CardBody id="schedule_form">
					{this.props.url &&
					<div id={"calendlyDisplay"}>
						<div className='calendly-inline-widget'
							 id={'calendy'}
							 data-url={this.props.url}
							 style={{minWidth: '320px', height: '580px'}}/>
					</div>
					}
				</CardBody>
			</Card>
		);
	}
}

export default Calendly;
