import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import ParameterForm from "./ContactForm";
import * as contactService from "../../services/contactType";
import { SubmissionError } from "redux-form";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import Locals from "../../components/Locals";

class CreateEditPopup extends Component {
	state = {
		open: false,
		data: {},
		loaded: true,
	};

	handleOpen = (id = null) => {
		this.setState({
			open: true,
		});

		if (id) {
			this.setState({
				loaded: false,
			});

			contactService.view(id).then(data => {
				this.setState({
					data,
					loaded: true,
				});
			});
		}
	};

	handleClose = () => {
		this.setState({
			open: false,
			data: {},
		});
	};

	onSubmit = data => {
		const edit = !data.id ? contactService.create : contactService.update;

		return edit(data)
			.then(data => {
				this.props.newData(data);

				toast.success("Enregistrement réussi");

				this.handleClose();
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	};

	render() {
		const { className, local } = this.props;
		const { loaded, open } = this.state;

		return (
			<Modal isOpen={open} className={className} fade={false}>
				<ModalHeader toggle={this.handleClose}>
					Editer <Locals />
				</ModalHeader>

				{!loaded && <Loading />}

				{loaded && (
					<ParameterForm
						onSubmit={this.onSubmit}
						initialValues={this.state.data}
						local={local}
					/>
				)}
			</Modal>
		);
	}
}

export default CreateEditPopup;
