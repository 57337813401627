/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { getLocal, getLocals, setLocal } from "../../reducers/params";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./local.css";

class Locals extends Component {
	render = () => {
		const { locals, local, setLocal } = this.props;

		return (
			<div className="locals">
				{locals.map((el, index) => (
					<a
						href="javascript:void(0)"
						className={local === el ? "active" : null}
						onClick={() => setLocal(el)}
						key={index}
					>
						<img style={{ width: "40px" }} src={`/img/${el}.png`} />
					</a>
				))}
			</div>
		);
	};
}

Locals.defaultProps = {
	local: null,
	locals: [],
};

Locals.propTypes = {
	local: PropTypes.string,
	locals: PropTypes.array,
	setLocal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	local: getLocal(state),
	locals: getLocals(state),
});

const mapDispatchToProps = dispatch =>
	bindActionCreators({ setLocal }, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Locals);
