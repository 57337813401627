import React from "react";
import { Form, ModalBody, ModalFooter, Alert } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import {
	WhFieldCkEditor,
	WhFieldTxt,
	WhFieldSelect,
	WhFieldTranslate,
} from "../../components/Form/whFields";
import { connect } from "react-redux";
import { getLocal } from "../../reducers/params";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import PropTypes from "prop-types";

const FaqQuestionForm = ({
	error,
	handleSubmit,
	submitting,
	local,
	options: { thematics },
}) => {
	return (
		<Form onSubmit={handleSubmit} className="form-vertical">
			<ModalBody>
				<WhFieldTranslate
					label="Question :"
					name="question"
					required
					translatable={local}
					component={WhFieldTxt}
				/>

				<Field
					label="Priorité d'affichage:"
					name="priority"
					required
					component={WhFieldTxt}
					require
					parse={value => parseInt(value)}
				/>

				<Field
					label="Catégorie de FAQ rattachée (pour ranger la FAQ dans une famille)"
					name="thematic"
					required
					component={WhFieldSelect}
					options={thematics}
					nullValue="Choisir une thématique"
				/>

				<WhFieldTranslate
					label="Réponse : "
					name="response"
					translatable={local}
					component={WhFieldCkEditor}
				/>

				{error && (
					<Alert color="danger">
						<div dangerouslySetInnerHTML={{ __html: error }} />
					</Alert>
				)}
			</ModalBody>

			<ModalFooter>
				<LaddaButton
					className="btn btn-success btn-ladda"
					loading={submitting}
					data-style={EXPAND_LEFT}
					type="submit"
				>
					<i className="fa fa-save" />
					&nbsp;Enregistrer
				</LaddaButton>
			</ModalFooter>
		</Form>
	);
};

FaqQuestionForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	options: PropTypes.shape({
		thematics: PropTypes.arrayOf(PropTypes.any),
	}).isRequired,
};

const mapStateToProps = state => ({
	local: getLocal(state),
});

const theFaqQuestionForm = connect(mapStateToProps)(FaqQuestionForm);

export default reduxForm({
	form: "FaqQuestionForm",
	initialValues: { questions: {} },
})(theFaqQuestionForm);
