import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import ThematicForm from "./ThematicForm";
import * as thematicService from "../../services/faqThematic";
import { SubmissionError } from "redux-form";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import Locals from "../../components/Locals";
import PropTypes from "prop-types";
import CreateEditPopup from "../FaqQuestion/CreateEditPopup";

class createEditPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			data: {},
			loaded: true,
		};

		this.handleClose = this.handleClose.bind(this);
		this.handleOpen = this.handleOpen.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	handleOpen(id = null) {
		this.setState({
			open: true,
		});

		if (id) {
			this.setState({
				loaded: false,
			});

			thematicService.view(id).then(data => {
				this.setState({
					data,
					loaded: true,
				});
			});
		}
	}

	handleClose() {
		this.setState({
			open: false,
			data: {},
		});
	}

	onSubmit(data) {
		const edit = !data.id ? thematicService.create : thematicService.update;

		return edit(data)
			.then(data => {
				this.props.newData(data);

				toast.success("Enregistrement réussi");

				this.handleClose();
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	}

	render() {
		const { local } = this.props;
		const { loaded } = this.state;

		return (
			<Modal size="xs" isOpen={this.state.open} fade={false}>
				<ModalHeader toggle={() => this.handleClose()}>
					Editer <Locals />
				</ModalHeader>

				{!loaded && <Loading />}

				{loaded && (
					<ThematicForm
						onSubmit={this.onSubmit}
						initialValues={this.state.data}
					/>
				)}
			</Modal>
		);
	}
}

CreateEditPopup.propTypes = {
	local: PropTypes.string,
};

export default createEditPopup;
