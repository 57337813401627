import React, {Component} from "react";
import {Modal, ModalHeader} from "reactstrap";
import TypeForm from "./TypeForm";
import * as typeService from "../../../services/rh/type";
import {SubmissionError} from "redux-form";
import {toast} from "react-toastify";
import Loading from "../../../components/Loading";
import PropTypes from "prop-types";
import Locals from "../../../components/Locals";

class CreateEditPopup extends Component {

	state = {
		open: false,
		data: {status: 'draft'},
		loaded: true,
	};


	handleOpen = (id = null) => {
		this.setState({
			open: true,
		});

		if (id) {
			this.setState({
				loaded: false,
			});

			typeService.view(id).then(data => {
				this.setState({
					data,
					loaded: true,
				});
			});
		}
	}

	handleClose = () => {
		this.setState({
			open: false,
			data: {},
		});
	}

	onSubmit = (data) => {
		const edit = !data.id ? typeService.create : typeService.update;

		return edit(data)
			.then(data => {
				this.props.newData(data);

				toast.success("Enregistrement réussi");

				this.handleClose();
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	}

	render = () => {
		const {loaded} = this.state;

		return (
			<Modal size="lg" isOpen={this.state.open} fade={false}>
				<ModalHeader toggle={() => this.handleClose()}>
					Editer
				</ModalHeader>

				{!loaded && <Loading/>}

				{loaded && (
					<div>
						<Locals/>
						<TypeForm
							onSubmit={this.onSubmit}
							initialValues={this.state.data}
						/>
					</div>
				)}
			</Modal>
		);
	}
}

CreateEditPopup.propTypes = {
	local: PropTypes.string,
};

export default CreateEditPopup;
