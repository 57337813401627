import {
	picture,
	video,
	foliosLeftTextRight,
	videoLeftTextRight,
	sliderAstuce,
	sliderFlap01,
	twoColumns,
	threeColumns,
	fourColumns,
	documentsLists,
	sliderNavLeftPhotoTxt,
} from "../block";

export const pagraraphe = {
	admin: {
		template: "BlocContainer",
		title: "Paragraphe",
		type: "tplt-paragraph",
		tplt: "/blocs/10-tplt-paragraphe.html.twig",
		fields: {
			paragraphe: {
				admin: {
					template: "BlocText1",
					type: "tplt-paragraph",
					content:
						'<div class="row justify-content-left"><h3 class="underline black">Les lieux pratiques à nantes</h3></div><p>Pellentesque lobortis neque nunc, tempus ultrices arcu dignissim et. In porta gravida aliquet. Quisque vel gravida elit. Vestibulum finibus faucibus diam, vel suscipit massa condi</p><quote>Maecenas odio tortor, condimentum</quote><p>Pellentesque lobortis neque nunc, tempus ultrice</p>',
				},
			},
		},
	},
};
export const builder = {
	tplt: "/builder",
	admin: {
		editable: true,
		add: true,
		template: "BlocCollection",
		title: "Body",
		defaultTemplates: [
			pagraraphe,
			picture,
			video,
			foliosLeftTextRight,
			videoLeftTextRight,
			sliderAstuce,
			sliderFlap01,
			twoColumns,
			threeColumns,
			fourColumns,
			documentsLists,
			sliderNavLeftPhotoTxt,
		],
	},
};

export default {
	template: "post",
	name: "Article",
	default: true,
	seo: {
		robots: "index,follow",
	},
	builder: [builder],
};
