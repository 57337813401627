const introductionItem = {
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Photos",
		fields: {
			photo: {
				admin: {
					template: "BlocMedia",
					type: "tplt-picture",
				},
			},
			content: {
				admin: {
					template: "BlocText1",
					type: "tplt-paragraphe",
					content: "<p>Description image</p>",
				},
			},
		},
	},
};

const introduction = {
	tplt: "/page/lifeStyle/01-introduction.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Introduction",
		fields: {
			content: {
				admin: {
					template: "BlocText1",
					type: "tplt-paragraph",
					content:
						'<h1>Title</h1><p>Imaginés ...</p><a href="#" class="btn button" data-hover="click me!"><span>Découvrir</span></a>',
				},
			},
			caroussel: {
				admin: {
					template: "BlocCollection",
					title: "Portolfio",
					type: "tplt-slider-horizontal",
					add: true,
					editable: true,
					defaultTemplates: [introductionItem],
				},
			},
		},
	},
};

const important = {
	tplt: "/page/lifeStyle/02-important.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Parce qu'il est aussi important ...",
		fields: {
			content: {
				admin: {
					template: "BlocText1",
					content:
						'<div class="container"><img src="assets/img/picto/quote.svg" alt=""><p><b>Parce qu’il est aussi important de prendre du temps pour soi et de s’amuser,</b>suitétudes mise sur l’animation de sa commauté et de ses résidences.</p></div >',
				},
			},
		},
	},
};

const goodIdeas = {
	tplt: "/page/lifeStyle/03-good-ideas.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Offre et bons plans",
		fields: {
			content: {
				admin: {
					template: "BlocText1",
					type: "tplt-paragraph",
					content:
						'<h2>Des offres et bons plans exclusifs</h2><img class="separator" src="./assets/img/picto/hover_white.svg" alt=""><h3>Suit’shopping</h3><p>test de contenu</p>',
				},
			},
			caroussel: {
				admin: {
					template: "BlocCollection",
					title: "Portolfio",
					type: "tplt-slider-horizontal",
					add: true,
					editable: true,
					defaultTemplates: [introductionItem],
				},
			},
		},
	},
};

const eveningEachItem = {
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Photo",
		fields: {
			photo: {
				admin: {
					template: "BlocMedia",
					type: "tplt-picture",
				},
			},
			content: {
				admin: {
					template: "BlocText1",
					type: "tplt-paragraph",
					content: "<h3>Suit’party</h3><p>contenu</p>",
				},
			},
		},
	},
};

const eveningEach = {
	tplt: "/page/lifeStyle/04-evening.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Des soirées ... ",
		fields: {
			content: {
				admin: {
					template: "BlocText1",
					type: "tplt-paragraph",
					content:
						"<h2>Des soirées dans chaque résidence pour kiffer entre voisins</h2>",
				},
			},
			caroussel: {
				admin: {
					template: "BlocCollection",
					title: "Portolfio",
					type: "tplt-slider-horizontal",
					add: true,
					editable: true,
					defaultTemplates: [eveningEachItem],
				},
			},
		},
	},
};

const bigEvents = {
	tplt: "/page/lifeStyle/05-big-events.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Des évenements ... ",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content: "<h2> des événements incontournables</h2>",
				},
			},
			caroussel: {
				admin: {
					template: "BlocCollection",
					title: "Portolfio",
					type: "tplt-slider-horizontal",
					add: true,
					editable: true,
					defaultTemplates: [introductionItem],
				},
			},
		},
	},
};

export default {
	template: "lifeStyle",
	name: "Life Style",
	editable: false,
	add: false,
	seo: {
		robots: "index,follow",
	},
	builder: [introduction, important, goodIdeas, eveningEach, bigEvents],
};
