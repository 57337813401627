import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
	Field,
	FormSection,
	reduxForm,
	FieldArray,
	change,
	getFormValues,
} from "redux-form";
import classnames from "classnames";
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Form,
	Alert,
	Button,
	Row,
	Col,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
} from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import ApiConfig from "../../constants/ApiConfig";
import Save from "../../components/Save";
import * as pageService from "../../services/cmsPage";
import * as cityService from "../../services/city";
import { getCmsStatus, getSeoRobots } from "../../reducers/params";
import FormSeo from "../Cms/Seo/Form";
import Dropzone from "react-dropzone";
import {
	WhFieldTxt,
	WhFieldCheckbox,
	WhFieldTranslate,
	WhFieldSelect,
	WhParseInt,
	WhFile,
	WhValidator,
	WhFieldSelectAsync,
	WhFieldBuilder,
} from "../../components/Form/whFields";

class FormEdit extends React.Component {
	state = {
		activeTab: "1",
		errorsImport: [],
	};

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}

	loadParents = input => {
		return pageService
			.search({ filtered: [{ id: "title", value: input }] })
			.then(data => {
				const lst = data["hydra:member"].map((el, index) => {
					return { id: el.id, label: `${el.title}` };
				});
				return { options: lst };
			});
	};

	onDrop = file => {
		this.setState({
			file,
			errorsImport: []
		});
	};

	import = () => {
		const { file } = this.state;
		const { initialValues } = this.props;

		if (!file) {
			toast.error("Pas de fichier séléctionné");
			return;
		}

		let formData = new FormData();
		formData.append("file", file[0]);

		cityService
			.importPois(formData, initialValues.id)
			.then(() => {
				this.setState({
					file: null,
				});
				toast.success("Import réussi");
			})
			.catch(error => {
				if (error) {
					this.setState({
						errorsImport: error
					});
					// toast.error(error);
				}
			});
	};

	render = () => {
		const {
			error,
			handleSubmit,
			submitting,
			initialValues,
			change,
			seoRobots,
			currentValues,
			changeFieldValue,
			cmsStatus,
			local,
			locals,
			defaultLocal,
		} = this.props;

		const {
			errorsImport,
		} = this.state;

		console.log(errorsImport)
		return (
			<Form onSubmit={handleSubmit}>
				<Row>
					<Col
						sm="8"
						xs={12}
						className="form-vertical"
						style={{ marginTop: "7px" }}
					>
						<Card>
							<CardHeader>
								<Nav tabs>
									<NavItem>
										<NavLink
											className={classnames({
												active:
													this.state.activeTab ===
													"1",
											})}
											onClick={() => {
												this.toggle("1");
											}}
										>
											Contenu
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({
												active:
													this.state.activeTab ===
													"2",
											})}
											onClick={() => {
												this.toggle("2");
											}}
										>
											SEO
										</NavLink>
									</NavItem>

									<NavItem>
										<NavLink
											className={classnames({
												active:
													this.state.activeTab ===
													"3",
											})}
											onClick={() => {
												this.toggle("3");
											}}
										>
											Import des POI sur la ville
										</NavLink>
									</NavItem>

									<NavItem>
										<NavLink
											className={classnames({
												active:
													this.state.activeTab ===
													"4",
											})}
											onClick={() => {
												this.toggle("4");
											}}
										>
											Images
										</NavLink>
									</NavItem>
								</Nav>

								<Save
									data={currentValues}
									setHistory={changeFieldValue}
									type="city"
									copy={this.props.copy}
								/>
							</CardHeader>

							<TabContent activeTab={this.state.activeTab}>
								<TabPane tabId="1">
									<WhFieldTranslate
										label={false}
										name="title"
										component={WhFieldTxt}
										type="text"
										translatable={local}
										placeholder="Nom de la ville"
										bsSize="lg"
									/>
									<WhFieldTranslate
										label={false}
										name="hashTag"
										component={WhFieldTxt}
										type="text"
										translatable={local}
										placeholder="#"
									/>
									<WhFieldTranslate
										label={false}
										name="slug"
										component={WhFieldTxt}
										validate={[WhValidator.required]}
										type="text"
										translatable={local}
										placeholder="nom dans l'url"
									/>
									<div className="content">
										<FieldArray
											name={
												local === defaultLocal
													? `builder`
													: `translate.${local}.builder`
											}
											component={WhFieldBuilder}
										/>
									</div>
								</TabPane>
								<TabPane tabId="2">
									<FormSection name="seo">
										<FormSeo
											seoRobots={seoRobots}
											local={local}
										/>
									</FormSection>
								</TabPane>
								{currentValues.id && (
									<TabPane tabId="3">
										<p>
											Fichier .csv windows avec séparateur
											";". Noms des colonnes dans l'ordre
											: <br />
											FAMILLE_POI, TITRE_POI_FR,
											TITRE_POI_EN, DESCRIPTIF_POI_FR,
											DESCRIPTIF_POI_EN, LATITUDE,
											LONGITUDE
										</p>

										<p className="alert alert-warning">
											Attention l'import de pois écrase
											tous les pois existant sur cette
											ville.
										</p>
										{/*{console.log(errorsImport)}*/}
										{errorsImport.map(val => (
											<p className="alert alert-danger">
												{val}
											</p>
										)
										)}
										<Dropzone
											className="dropzone"
											multiple={false}
											onDrop={this.onDrop}
										>
											{({
												isDragActive,
												isDragReject,
												acceptedFiles,
												rejectedFiles,
											}) => {
												if (isDragActive) {
													return "This file is authorized";
												}
												if (isDragReject) {
													return "This file is not authorized";
												}
												return acceptedFiles.length ||
													rejectedFiles.length
													? `Accepted ${
															acceptedFiles.length
													  }, rejected ${
															rejectedFiles.length
													  } files`
													: "Déposer votre fichier csv dans cette zone";
											}}
										</Dropzone>

										<Button
											color="btn btn-primary btn-block"
											onClick={this.import}
										>
											Importer
										</Button>
									</TabPane>
								)}
								{!currentValues.id && (
									<TabPane tabId="3">
										<p>Merci d'enregistrer votre ville</p>
									</TabPane>
								)}
								<TabPane tabId="4">
									<Card>
										<CardHeader>Medias</CardHeader>
										<CardBody>
											<Field
												label="Vignette"
												name="vignette"
												component={WhFile}
												type="text"
											/>
										</CardBody>
									</Card>
								</TabPane>
							</TabContent>
						</Card>
					</Col>
					<Col sm="4" xs={12}>
						<Card>
							<CardHeader>
								<i className="fa fa-align-justify" /> Edition
							</CardHeader>

							<CardBody>
								<div className="form-vertical">
									<WhFieldTranslate
										label="Résumé : "
										name="summary"
										component={WhFieldTxt}
										type="textarea"
										translatable={local}
									/>
									<WhFieldTranslate
										label="Citation menu : "
										name="citation"
										component={WhFieldTxt}
										type="textarea"
										translatable={local}
									/>
									<Row>
										<Col md={6}>
											<Field
												label="Nombre d'appartements (page ville) : "
												parse={WhParseInt}
												name="nbFlats"
												component={WhFieldTxt}
												type="number"
											/>
										</Col>
										<Col md={6}>
											<Field
												label="Nombre de résidences (page liste ville) : "
												name="nbResidences"
												parse={WhParseInt}
												component={WhFieldTxt}
												type="number"
											/>
										</Col>
									</Row>
									<Row>
										<Col md={6}>
											<Field
												label="Latitude : "
												name="latitude"
												component={WhFieldTxt}
												type="number"
											/>
										</Col>
										<Col md={6}>
											<Field
												label="Longitude : "
												name="longitude"
												component={WhFieldTxt}
												type="number"
											/>
										</Col>
									</Row>
									<Field
										label="Nom du picto : "
										name="picto"
										component={WhFieldTxt}
										type="icon"
									/>
									<Field
										label="Nouveau"
										name="new"
										component={WhFieldCheckbox}
									/>
								</div>

								<WhFieldTranslate
									label="Statut de publication :"
									name="status"
									required
									validate={[WhValidator.required]}
									component={WhFieldSelect}
									type="select"
									options={cmsStatus}
									translatable={local}
								/>

								{error && (
									<Alert color="danger">
										<div
											dangerouslySetInnerHTML={{
												__html: error,
											}}
										/>
									</Alert>
								)}
							</CardBody>

							<CardFooter style={{ display: "flex" }}>
								<LaddaButton
									className="btn btn-primary btn-ladda"
									loading={submitting}
									data-style={EXPAND_LEFT}
									type="submit"
									style={{ flex: 1 }}
								>
									<i className="fa fa-save" /> Enregistrer{" "}
								</LaddaButton>

								<LaddaButton
									className="btn btn-info btn-ladda"
									loading={submitting}
									onClick={() => change("add", true)}
									data-style={EXPAND_LEFT}
									type="submit"
									style={{ flex: 1 }}
								>
									<i className="fa fa-save" /> Enregistrer et
									ajouter
								</LaddaButton>
							</CardFooter>
						</Card>

						{currentValues.id && (
							<Card>
								<a
									href={`${ApiConfig.url}/redirect/city/${
										currentValues.id
									}`}
									target="_blank"
									className="btn btn-block btn-success btn-ld"
								>
									Voir
								</a>
							</Card>
						)}
					</Col>
				</Row>
			</Form>
		);
	};
}

const formName = "FormCity";

const rxf = reduxForm({ form: formName })(FormEdit);

FormEdit.defaultProps = {
	error: "",
	categories: [],
	cmsStatus: [],
	seoRobots: [],
	currentValues: {},
	locals: [],
};
FormEdit.propTypes = {
	error: PropTypes.string,
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	currentValues: PropTypes.shape({}),
	cmsStatus: PropTypes.array,
	seoRobots: PropTypes.array,
	locals: PropTypes.array,
};

export const mapStateToProps = state => ({
	currentValues: getFormValues(formName)(state),
	cmsStatus: getCmsStatus(state),
	seoRobots: getSeoRobots(state),
});

const mapDispatchToProps = dispatch => ({
	changeFieldValue: (field, value) =>
		dispatch(change(formName, field, value)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(rxf);
