import React, { Component } from "react";
import { NavLink, NavItem, Nav } from "reactstrap";
import classnames from "classnames";

class Header extends Component {
	render = () => {
		const { toggle, activeTab } = this.props;

		return (
			<Nav tabs>
				<NavItem>
					<NavLink
						className={classnames({
							active: activeTab === "contents",
						})}
						onClick={() => {
							toggle("contents");
						}}
					>
						{" "}
						Contenus
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({
							active: activeTab === "settings",
						})}
						onClick={() => {
							toggle("poi");
						}}
					>
						{" "}
						Pois
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({
							active: activeTab === "medias",
						})}
						onClick={() => {
							toggle("medias");
						}}
					>
						{" "}
						Médias
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTab === "lots" })}
						onClick={() => {
							toggle("lots");
						}}
					>
						{" "}
						Lots
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTab === "seo" })}
						onClick={() => {
							toggle("seo");
						}}
					>
						{" "}
						SEO
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({
							active: activeTab === "floors",
						})}
						onClick={() => {
							toggle("floors");
						}}
					>
						{" "}
						Etages
					</NavLink>
				</NavItem>
			</Nav>
		);
	};
}

export default Header;
