import React, {Component} from 'react';
import {Form} from 'reactstrap';
import {Field, reduxForm} from 'redux-form'
import {FieldTxt} from '../../components/Form/wh-field';
import {ModalBody, ModalFooter} from 'reactstrap';
import LaddaButton, {EXPAND_LEFT} from 'react-ladda';

import {connect} from 'react-redux';
import FieldTranslate from "../../components/Form/whFields/FieldTranslate";
import PropTypes from "prop-types";
import {getLocal} from "../../reducers/params";
import {SketchPicker} from 'react-color';
import {WhFieldTxt} from "../../components/Form/whFields";

class PoiForm extends Component {
    state = {
        open: false
    };

    render = () => {
        const {local, handleSubmit, submitting, initialValues} = this.props;

        return (

            <Form onSubmit={handleSubmit} className="form-horizontal">

                <ModalBody>

                    <FieldTranslate
                        label="Nom :"
                        name="name"
                        translatable={local}
                        component={FieldTxt}
                        type="text"
                    />

                    <Field
                        label="Icone :"
                        name="icon"
                        component={FieldTxt}
                        type="text"
                    />

                    <Field
                        label="Priorité d'apparition : "
                        name="position"
                        component={WhFieldTxt}
                        parse={value => {
                            return parseInt(value);
                        }}
                    />

                    <label>Couleur :</label>
                    <div  style={{width:'50%',margin:'auto'}}>
                        <SketchPicker color={initialValues.color ?initialValues.color : "#4A529E" } onChange={ (color) => { this.props.change('color', color.hex) } } />
                        <Field name="color" component="hidden" />
                    </div>

                </ModalBody>

                <ModalFooter>

                    <LaddaButton
                        className="btn btn-success btn-ladda"
                        loading={submitting}
                        data-style={EXPAND_LEFT}
                        type={'submit'}
                    >
                        <i className="fa fa-save"> </i>
                        &nbsp;Enregistrer
                    </LaddaButton>

                </ModalFooter>

            </Form>

        );

    }
}

PoiForm.propTypes = {
    error: PropTypes.shape({}).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    local: PropTypes.string
};
PoiForm.defaultProps = {
    error: "",
};

const mapStateToProps = (state) => ({
    local: getLocal(state)
});

PoiForm = connect(
    mapStateToProps
)(PoiForm);

export default reduxForm({
    form: 'PromotionForm'
})(PoiForm);
