import React, { Component } from "react";
import WHTable from "../../components/WHTable";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {getDefaultLocal, getLocals} from "../../reducers/params";
import { Card, CardHeader, Button } from "reactstrap";
import * as schoolService from "../../services/school";
import ApiConfig from "../../constants/ApiConfig";
import ModalImport from "./ModalImport";

class Cities extends Component {
	state = {
		data: [],
		pages: null,
		loaded: true,
	};

	columns() {
		const viewUrl = ApiConfig.url + "/fr/logement-etudiant/";
		const { locals, local } = this.props;
		return [
			{
				Header: "",
				accessor: "id",
				filterable: false,
				Cell: row => (
					<div>
						{row.original.address && row.original.address.city &&
							<a
								className="btn btn-info"
								target="_blank"
								href={viewUrl + row.original.address.city.slug + "/" + row.original.slug}
							>
								<i className="fa fa-eye" />
							</a>
						}
						<Button
							color="primary"
							onClick={() =>
								this.props.history.push(
									`${this.props.match.path}/${row.value}`,
								)
							}
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							color="danger"
							onClick={() => this.remove(row.value)}
						>
							<i className="fa fa-trash" />
						</Button>
					</div>
				),
				maxWidth: 130,
			},
			{
				Header: "Nom de l\'école",
				accessor: "name"
			},
			{
				'Header': 'Ville',
				accessor: "address.city.title"
			},
			{
				Header: "Acronyme",
				accessor: "acronym"
			},
			{
				Header: "Langues",
				filterable: false,
				Cell: row => {
					return (
						<div style={{ textAlign: "right" }}>
							{locals.map((lng, index) => (
								<Button
									key={index}
									color={
										local === lng ? row.original.status === 'published'
												? "success"
												: "danger"
											: row.original.translate && row.original.translate[lng] ? row.original.translate[lng].status === 'published'
												? "success"
												: "danger"
											: "danger"
									}
								>
									{lng.toUpperCase()}
								</Button>
							))}
						</div>
					);
				},
			},
		];
	}

	load = state => {
		this.setState({
			loaded: false,
		});

		return schoolService.search(state).then(data => {
			this.setState(
				{
					data: [],
				},
				() => {
					this.setState({
						data: data["hydra:member"],
						pages: Math.ceil(
							data["hydra:totalItems"] / state.pageSize,
						),
						loaded: true,
					});
				},
			);
		});
	};

	remove = toDelete => {
		if (
			window.confirm("Êtes-vous sûr de vouloir supprimer cette école ?")
		) {
			this.setState({
				loaded: false,
			});

			schoolService
				.remove(toDelete)
				.then(() => {
					const lst = [...this.state.data];

					const index = lst.findIndex(el => el.id === toDelete);

					if (index > -1) {
						lst.splice(index, 1);

						this.setState({
							data: lst,
							loaded: true,
						});
					}
				})
				.catch(() => {
					this.setState({
						loaded: true,
					});
					alert(
						"Il existe des dépendances sur membre, il ne peut donc être supprimé",
					);
				});
		}
	};

	newData = data => {
		const lst = [...this.state.data];

		const index = lst.findIndex(el => el.id === data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({
			data: lst,
		});
	};

	render = () => {
		const { data, pages } = this.state;

		return (
			<div className="animated fadeIn">
				<h1>Ecoles</h1>
				<ModalImport
					ref={"_popupImport"}
				/>
				<Card>
					<CardHeader>
						<i className="fa fa-align-justify" /> Liste des écoles
						<div className="card-actions">
							<button
								onClick={() =>
									this.refs._popupImport.handleOpen()
								}
								className="btn btn-primary"
							>
								<i className="fa fa-download" />
								&nbsp;Importer des écoles
							</button>
							<button
								onClick={() =>
									this.props.history.push("/ecoles/creer")
								}
								className="btn btn-primary"
							>
								<i className="fa fa-plus" />
								&nbsp;Nouveau
							</button>
						</div>
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable={true}
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
				</Card>
			</div>
		);
	};
}

Cities.defaultProps = {
	locals: [],
};

Cities.propTypes = {
	locals: PropTypes.array,
};

const mapStateToProps = state => ({
	locals: getLocals(state),
	local: getDefaultLocal(state),
});

export default connect(mapStateToProps)(Cities);
