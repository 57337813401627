import React, {Component} from "react";
import {
	Form, ModalBody, ModalFooter, Alert, CardBody, Card,
} from "reactstrap";
import {reduxForm, Field, getFormValues, change} from "redux-form";
import {
	WhFieldTxt,
} from "../../../components/Form/whFields";
import LaddaButton, {EXPAND_LEFT} from "react-ladda";
import { SketchPicker } from 'react-color';
import {connect} from "react-redux";


class HelloBarForm extends Component {

	state = {
		background: '#fff',
	};

	handleChangeComplete = (color) => {
		this.setState({ background: color.hex });
		this.props.changeFieldValue('color', color.hex);
	};

	componentDidMount() {
		this.setState({
			background: this.props.currentValues.color
		})
	}

	render = () => {
		const {error, handleSubmit, submitting} = this.props;

		return (

			<Form onSubmit={handleSubmit} className="form-vertical">
				<ModalBody>
					<Card>
						<CardBody>

							<Field
								label="Texte :"
								name="text"
								component={WhFieldTxt}
								require
								type="text"
							/>

							<Field
								label="Lien :"
								name="link"
								component={WhFieldTxt}
								require
								type="text"
							/>

							<SketchPicker
								color={ this.state.background }
								onChangeComplete={ this.handleChangeComplete }
							/>

							{error && (
								<Alert color="danger">
									<div dangerouslySetInnerHTML={{__html: error}}/>
								</Alert>
							)}
						</CardBody>

					</Card>
				</ModalBody>

				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type="submit"
					>
						<i className="fa fa-save"/>
						&nbsp;Enregistrer
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	};
}

const mapState = state => ({
	currentValues: getFormValues("HelloBarForm")(state),
});

const mapDispatchToProps = dispatch => ({
	changeFieldValue: (field, value) =>
		dispatch(change('HelloBarForm', field, value)),
});

HelloBarForm = connect(mapState, mapDispatchToProps)(HelloBarForm);

export default reduxForm({
	form: "HelloBarForm",
})(HelloBarForm);
