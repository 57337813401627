import home from "./home";
import page from "./page";
import category from "./category";
import listmap from "./listmap";
import listresidence from "./listresidence";
import sectorEntry from "./sectorEntry";
import lifeStyle from "./lifeStyle";
import faq from "./faq";
import logements from "./logements";
import contact from "./contact";
import cgu from "./cgu";

export default {
	home,
	page,
	category,
	listmap,
	sectorEntry,
	lifeStyle,
	faq,
	logements,
	contact,
	cgu,
	listresidence
};
