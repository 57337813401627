import React, { Component } from "react";
import Loading from "../../../components/Loading";
import Form from "./Form";
import { toast } from "react-toastify";
import { SubmissionError } from "redux-form";
import Locals from "../../../components/Locals";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import washJson from "../../../utils/washJson";
import {
	getLocal,
	getLocals,
	setLocal,
	getDefaultLocal,
	getTemplates,
} from "../../../reducers/params";
import { bindActionCreators } from "redux";
import merge from "lodash/merge";
import * as postService from "../../../services/cmsPost";
import tplt from "../../../constants/tplt/post";
import * as residenceService from "../../../services/residence";

class Post extends Component {
	state = {
		data: {},
		loaded: false,
	};

	componentDidMount = () => {
		this.load();
	};

	show = () => {
		this.props.history.push(`/post/${this.props.match.params.id}`);
	};

	formatDataForForm = arr => {
		return arr.map(el => {
			return {
				value: el.id,
				id: el.id,
				label: `${el.title ? el.title : el.label}`,
			};
		});
	};

	load = async () => {
		const { locals, defaultLocal } = this.props;
		let data = await postService.view(this.props.match.params.id);

		data = merge(JSON.parse(JSON.stringify(tplt[data.template])), data);
		locals.map(local => {
			if (local != defaultLocal) {
				if (!data.translate) data.translate = {};
				if (!data.translate[local]) data.translate[local] = {};
				if (!data.translate[local].builder) {
					data.translate[local].builder = JSON.parse(
						JSON.stringify(tplt[data.template].builder),
					);
				}
				if (!data.translate[local].status) {
					data.translate[local].status = 'draft'
				}
				if (typeof data.translate[local].builder !== "object")
					data.translate[local].builder = washJson(
						data.translate[local].builder,
					);
			}
		});
		data.cities = this.formatDataForForm(data.cities);
		data.residences = this.formatDataForForm(data.residences);
		this.setState({
			data,
			loaded: true,
		});
	};

	copy = async (locale) =>
	{
		var localeWord = locale === 'en' ? 'l\'Anglais' : 'le Chinois';

		if (
			window.confirm("Voulez vous vraiment copier le contenu de lactualité vers " + localeWord  + "? Si des traductions sur cette page existe elles seront supprimées")
		) {

			return residenceService.copy(this.state.data.id, locale)
				.then(() => {
					window.location.reload();
				})
				.catch(error => {
					throw new SubmissionError(error);
				});
		}
	}

	submit = values => {
		const edit = !values.id ? postService.create : postService.update;

		return edit(values)
			.then(data => {
				toast.success("Enregistrement réussi");
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	};

	render = () => {
		const { local, defaultLocal } = this.props;
		const { data } = this.state;

		return (
			<div className="animated fadeIn">
				{!this.state.loaded && <Loading />}
				{this.state.loaded && (
					<div>
						<Locals />
						<Form
							onSubmit={this.submit}
							initialValues={data}
							show={this.show}
							local={local}
							defaultLocal={defaultLocal}
							copy={this.copy}
						/>
					</div>
				)}
			</div>
		);
	};
}

Post.defaultProps = {
	local: null,
};

Post.propTypes = {
	local: PropTypes.string,
	locals: PropTypes.array.isRequired,
	defaultLocal: PropTypes.string,
};

const mapStateToProps = state => ({
	local: getLocal(state),
	locals: getLocals(state),
	defaultLocal: getDefaultLocal(state),
	templates: getTemplates(state),
});

export const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			setLocal,
		},
		dispatch,
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Post);
