import { fetch } from "../../../utils/fetch";
import QueryBuilder from "../../../components/WHTable/queryBuilder";
import configApi from "../../../constants/ApiConfig";
import { formatISO, add } from "date-fns";

const endPoints = "/api/contact_logs";

export const search = (data) => {
	console.log({ data });
	let query = QueryBuilder(data);

	const entryDate = query["tenant.booking.entryDate"];
	const endDate = query["tenant.booking.endDate"];
	const createdAt = query["createdAt"];
	const askDate = query["askDate"];
	const deletedAt = query["deletedAt"];

	if (entryDate) {
		query = {
			...query,
			"tenant.booking.entryDate[strictly_before]": formatISO(
				add(entryDate, { days: 1 })
			),
			"tenant.booking.entryDate[after]": formatISO(entryDate),
		};
	}

	if (endDate) {
		query = {
			...query,
			"tenant.booking.endDate[strictly_before]": formatISO(
				add(endDate, { days: 1 })
			),
			"tenant.booking.endDate[after]": formatISO(endDate),
		};
	}

	if (createdAt) {
		query = {
			...query,
			"createdAt[strictly_before]": formatISO(
				add(createdAt, { days: 1 })
			),
			"createdAt[after]": formatISO(createdAt),
		};
	}

	if (askDate) {
		query = {
			...query,
			"askDate[strictly_before]": formatISO(add(askDate, { days: 1 })),
			"askDate[after]": formatISO(askDate),
		};
	}

	if (deletedAt) {
		query = {
			...query,
			"deletedAt[strictly_before]": formatISO(
				add(deletedAt, { days: 1 })
			),
			"deletedAt[after]": formatISO(deletedAt),
		};
	}

	delete query["tenant.booking.entryDate"];
	delete query["tenant.booking.endDate"];
	delete query["createdAt"];
	delete query["askDate"];
	delete query["deletedAt"];

	return fetch(`${configApi.url}${endPoints}`, "get", query);
};

export const view = (id) => fetch(`${configApi.url}${endPoints}/${id}`, "get");

export const update = (data) =>
	fetch(`${configApi.url}${endPoints}/${data.id}`, "put", data);

export const remove = (id) =>
	fetch(`${configApi.url}${endPoints}/remove/${id}`, "get");
