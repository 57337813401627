import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

class WHTable extends Component {
	filterMethod(filter, row) {
		const id = filter.pivotId || filter.id;

		if (row[id] === undefined || !row[id]) return true;
		if (filter.value === undefined || !filter.value) return true;

		const value = String(row[id].toLowerCase());
		const search = String(filter.value.toLowerCase());

		if (value.indexOf(search) > -1) return true;

		return false;
	}

	render() {
		const {
			data,
			pages,
			loading,
			columns,
			filterable,
			pageSize,
			defaultPageSize,
			showPagination,
		} = this.props;

		return (
			<ReactTable
				manual
				defaultFilterMethod={this.filterMethod}
				data={data}
				pageSize={pageSize}
				defaultPageSize={defaultPageSize}
				pages={pages}
				loading={loading}
				columns={columns}
				filterable={filterable}
				onFetchData={this.props.onFetchData}
				showPagination={showPagination}
				className="-striped -highlight"
			/>
		);
	}
}

export default WHTable;
