/**
 * SLIDER PHOTOS VIDEO
 */
const paragraphe01 =
	'<h4>Sous-titre</h4><h3>Titre du bloc</h3><p><span class="icon-hover"></span></p><p>Etenim si attendere diligenter, </p><p><a class="btn button" data-hover="click me!" href="#"><span>D&eacute;couvrir</span></a></p>';

export default {
	admin: {
		template: "BlocCollection",
		tplt: "/blocs/slider/tplt-slider-photo-video-right.html.twig",
		title: "Slider Photo Video, Media a droite",
		type: "tplt-document-list",
		add: true,
		editable: true,
		defaultTemplates: [
			{
				admin: {
					editable: true,
					add: false,
					template: "BlocContainer",
					type: "tplt-document",
					title: "Video droite texte à gauche",
					fields: {
						video: {
							admin: {
								template: "BlocMedia",
								type: "tplt-video",
							},
						},
						content: {
							admin: {
								template: "BlocText1",
								type: "tplt-paragraph",
								content: paragraphe01,
							},
						},
					},
				},
			},
			{
				admin: {
					editable: true,
					add: false,
					template: "BlocContainer",
					type: "tplt-document",
					title: "Photo droite texte à gauche",
					fields: {
						picture: {
							admin: {
								template: "BlocMedia",
								type: "tplt-document",
							},
						},
						content: {
							admin: {
								template: "BlocText1",
								type: "tplt-paragraph",
								content: paragraphe01,
							},
						},
					},
				},
			},
		],
	},
};
