/**
 * Manage breadcrump name
 */
const routes = {
    '/': 'Accueil',
    '/dashboard': 'Dashboard',
    '/villes': 'Liste des villes',
    '/residences': 'Liste des résidences'
};
export default routes;
