import React, { Component } from "react";
import { Form, ModalBody, ModalFooter, Alert } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import {
	WhFieldTxt,
	WhFieldTranslate,
	WhParseInt,
} from "../../components/Form/whFields";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { getLocal } from "../../reducers/params";
import { connect } from "react-redux";

class thematicForm extends Component {
	test = () => {};

	render = () => {
		const { error, handleSubmit, submitting, local } = this.props;

		return (
			<Form onSubmit={handleSubmit} className="form-vertical">
				<ModalBody>
					<WhFieldTranslate
						label="Titre :"
						name="title"
						component={WhFieldTxt}
						require
						translatable={local}
						type="text"
					/>

					<WhFieldTranslate
						label="Sous titre :"
						name="subTitle"
						component={WhFieldTxt}
						require
						translatable={local}
						type="text"
					/>

					<Field
						label="Priorité d'affichage:"
						name="priority"
						component={WhFieldTxt}
						require
						type="number"
						parse={WhParseInt}
					/>

					{error && (
						<Alert color="danger">
							<div dangerouslySetInnerHTML={{ __html: error }} />
						</Alert>
					)}
				</ModalBody>

				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type="submit"
					>
						<i className="fa fa-save" />
						&nbsp;Enregistrer
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	};
}

const mapStateToProps = state => ({
	local: getLocal(state),
});

thematicForm = connect(mapStateToProps)(thematicForm);

export default reduxForm({
	form: "thematicForm",
})(thematicForm);
