// import { fetch } from "../../utils/fetch";
import configApi from "../../constants/ApiConfig";
import saveAs from 'file-saver';
import * as sessionService from '../../utils/session';



// export const downloadFile = ( name, documentId) =>
// 	fetch(`${configApi.url}/api/${documentId}/download-document`, 'get')
// 		.then(response => response.blob())
// 		.then(blob => saveAs(blob, name));

export const downloadFile = ( name, documentId) =>
	fetch(`${configApi.url}/api/${documentId}/download-document`, {
		mode: 'cors', // TO DO CHANGE MODE CORS
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${sessionService.getJwtToken()}`,
		},
	})
		.then(response => response.blob())
		.then(blob => saveAs(blob, name));
