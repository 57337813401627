/**
 * HOME 01
 */
const home01Container = {
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Je suis Parent / Enfant ",
		fields: {
			title: {
				admin: {
					template: "BlocTitle",
					content:
						'<h2><span>Light</span><span class="bold"> Gras</span></h2>',
				},
			},
			children: {
				admin: {
					template: "BlocRequest",
					title: "Slider",
					type: "tplt-slider-horizontal",
					add: false,
					editable: true,
					content: {
						service: "/api/services",
						filters: { itemsPerPage: 20, order: [] },
					},
				},
			},
			button: {
				admin: {
					template: "BlocText1",
					content:
						'<a href="#" class="my-sm-0 mt-2 mb-5 btn button" data-hover="click me!"><div>Button</div></a>',
				},
			},
		},
	},
};

const home01 = {
	//Cette partie à externaliser en liste de blocs (issue de SF ?)
	tplt: "/home/home01/home01.html.twig",
	admin: {
		template: "BlocCollection",
		title: "Je suis ... ",
		editable: true,
	},
	contents: [home01Container, home01Container],
};
/**
 * HOME 02
 */
const home02 = {
	//Cette partie à externaliser en liste de blocs (issue de SF ?)
	tplt: "/home/home02/home02.html.twig",
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Life style ",
		fields: {
			title: {
				admin: {
					template: "BlocTitle",
					content:
						'<div class="container"><h2><span>#Suiteetudede</span><span class="bold">lifestyle</span></h2><h3 class="subtitle">suivez suitétude sur les réseaux sociaux</h3></div>',
				},
			},
			children: {
				admin: {
					template: "BlocRequest",
					title: "Slider",
					type: "tplt-slider-horizontal",
					add: false,
					editable: true,
					content: {
						service: "/api/posts",
						filters: { itemsPerPage: 5, order: [] },
					},
				},
			},
		},
	},
};

/**
 * HOME 03
 */

const home03 = {
	//Cette partie à externaliser en liste de blocs (issue de SF ?)
	tplt: "/home/home03/home03.html.twig",
	admin: {
		editable: true,
		add: false,

		template: "BlocContainer",
		title: "Guides populaire ",
		fields: {
			title: {
				admin: {
					template: "BlocTitle",
					content:
						'<div class="container"><h2><span>Les guides </span><span class="bold">Populaires !</span></h2><h3 class="subtitle">Hop hop hop</h3></div>',
				},
			},
			children: {
				type: "tplt-slider-horizontal",
				admin: {
					template: "BlocRequest",
					title: "Slider",

					add: false,
					editable: true,
					content: {
						service: "/api/posts",
						filters: { itemsPerPage: 5, order: [] },
					},
				},
			},
		},
	},
};

/**
 * HOME 04
 */
const home04 = {
	//Cette partie à externaliser en liste de blocs (issue de SF ?)
	tplt: "/home/home04/home04.html.twig",
	admin: {
		editable: true,
		add: false,

		template: "BlocContainer",
		title: "Ca se passe en ce moment ",
		fields: {
			title: {
				admin: {
					template: "BlocTitle",
					content:
						'<div class="container"><h2><span>Ca se passe  </span><span class="bold">en ce moment</span></h2><h3 class="subtitle">Les actus à ne pas rater</h3></div>',
				},
			},
			children: {
				type: "tplt-slider-horizontal",
				admin: {
					template: "BlocRequest",
					title: "Slider",
					add: false,
					editable: true,
					content: {
						service: "/api/posts",
						filters: { itemsPerPage: 5, order: [] },
					},
				},
			},
		},
	},
};
/**
 * HOME 05
 */
const Home05Media = {
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Photo",
		fields: {
			media: {
				admin: {
					template: "BlocMedia",
				},
			},
		},
	},
};

const home05 = {
	//Cette partie à externaliser en liste de blocs (issue de SF ?)
	tplt: "/home/home05/home05.html.twig",
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Résidences à venir",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content:
						'<div class="col-md-4 offset-md-1  order-1 order-md-2 mt-5 mb-5"><h3><span>Résidence<br>étudiante,</span><span class="bold"> à venir</span></h3><p>Lorem ipsum dolor s</p></div>',
				},
			},
			children: {
				type: "tplt-slider-horizontal",
				admin: {
					template: "BlocRequest",
					title: "Slider",
					add: false,
					editable: true,
					content: {
						service: "/api/residences",
						filters: {
							itemsPerPage: 5,
							order: [],
							pushHome: 1,
							status: "published",
						},
					},
				},
			},
		},
	},
};
/**
 * EXPORT
 */
export default {
	template: "home",
	name: "Accueil",
	editable: true,
	add: false,
	seo: {
		robots: "index,follow",
	},
	builder: [home01, home02, home03, home04, home05],
};
