import React, {Component} from 'react';
import WHTable from '../../components/WHTable';
import {Card, CardHeader, CardBody} from 'reactstrap';
import * as importService from '../../services/import';
import Moment from "react-moment";

class Import extends Component {

    state = {
        data: [],
        col: [],
        pages: null,
        loaded: true
    };

    columns = () => {
        return [
            {
                Header: 'Famille',
                accessor: 'family',
                maxWidth: 120
            },
            {
                Header: 'Type',
                accessor: 'type'
            },
            {
                Header: 'Sous Type',
                accessor: 'subType'
            },
            {
                Header: "Heure d'import",
                accessor: 'start',
                Cell: row => <Moment format="DD/MM/YYYY">{row.value}</Moment>,
            },
            {
                Header: "Nombre d'enregistrement bdd",
                accessor: 'nbRecords'
            },
            {
                Header: "Statut",
                accessor: 'status',
                Cell: row => row.value === 0 ? 'Invalide' : 'Valide',
            },
        ];
    };

    load = (state) => {
        this.setState({
            loaded: false
        });

        return importService.search(state).then((data) => {
            this.setState({
                    data: []
                },
                () => {
                    this.setState({
                        data: data['hydra:member'],
                        pages: Math.ceil(data['hydra:totalItems'] / state.pageSize),
                        loaded: true
                    })
                });
        });
    };

    remove = (toDelete) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer cet item ?')) {
            this.setState({
                loaded: false
            });

            importService.remove(toDelete).then(() => {
                let lst = [...this.state.data];

                let index = lst.findIndex((el) => el.id === toDelete);

                if (index > -1) {
                    lst.splice(index, 1);

                    this.setState({
                        data: lst,
                        loaded: true
                    });
                }
            }).catch(() => {
                alert('Il existe des dépendances cet item, il ne peut donc être supprimé');
            });
        }
    };

    newData = (data) => {
        let lst = [...this.state.data];

        let index = lst.findIndex((el) => el.id === data.id);

        if (index > -1) {
            lst[index] = data;
        } else {
            lst.push(data);
        }

        this.setState({
            data: lst
        });
    };

    render() {
        const {data, pages} = this.state;
        return (
            <div className="animated fadeIn">

                <Card>

                    <CardHeader>

                        <i className="fa fa-align-justify"/>
                        &nbsp;Liste des imports

                    </CardHeader>

                    <CardBody>

                        <WHTable
                            data={data}
                            pages={pages}
                            columns={this.columns()}
                            filterable={false}
                            onFetchData={this.load}
                            loading={!this.state.loaded}
                        />

                    </CardBody>

                </Card>

            </div>
        )
    }
}

export default Import;