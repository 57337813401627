import React, { Component } from "react";
import * as typologyService from "../../../services/typology";
import { Field } from "redux-form";
import { FieldNumeric } from "../../../components/Form/wh-field";
import { Col, Row } from "reactstrap";

class TypologyFeatures extends Component {
	componentWillMount() {
		this.load();
	}

	load = async () => {
		const { fields } = this.props;

		return await typologyService.search().then(data => {
			data["hydra:member"].map(el => {
				if (fields.getAll()) {
					if (
						!fields
							.getAll()
							.some(field => field.typology.id === el.id)
					) {
						fields.push({ typology: el });
					}
				} else {
					fields.push({ typology: el });
				}
			});
		});
	};

	render() {
		const { fields } = this.props;
		return (
			<Row>
				{fields.map((el, index) => (
					<Col key={`col_${index}`} md={3}>
						<Field
							label={`${fields.get(index).typology ? fields.get(index).typology.name : ''} :`}
							key={index}
							name={`${el}.number`}
							component={FieldNumeric}
							parse={value => parseInt(value)}
						/>
					</Col>
				))}
			</Row>
		);
	}
}

export default TypologyFeatures;
