import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import ArticleForm from "./ArticleForm";
import * as articleService from "../../services/press";
import { SubmissionError } from "redux-form";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import PropTypes from "prop-types";

class CreateEditPopup extends Component {

	state = {
		open: false,
		data: {},
		loaded: true,
	};

	handleOpen = async (id = null) => {
		this.setState({
			open: true,
		});

		if (id) {
			this.setState({
				loaded: false,
			});

			let data = await articleService.view(id);

			Object.assign(data.city, {value: data.city.id, label: data.city.title});

			this.setState({
				data,
				loaded: true,
			});
		}
	}

	handleClose = () => {
		this.setState({
			open: false,
			data: {},
		});
	}

	onSubmit = (data) => {
		const edit = !data.id ? articleService.create : articleService.update;

		return edit(data)
			.then(data => {
				this.props.newData(data);

				toast.success("Enregistrement réussi");

				this.handleClose();
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	}

	render = () => {
		const { loaded } = this.state;

		return (
			<Modal size="lg" isOpen={this.state.open} fade={false}>
				<ModalHeader toggle={() => this.handleClose()}>
					Editer
				</ModalHeader>

				{!loaded && <Loading />}

				{loaded && (
					<ArticleForm
						onSubmit={this.onSubmit}
						initialValues={this.state.data}
					/>
				)}
			</Modal>
		);
	}
}

CreateEditPopup.propTypes = {
	local: PropTypes.string,
};

export default CreateEditPopup;
