import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import FormEdit from "./Form";
import { SubmissionError } from "redux-form";
import { toast } from "react-toastify";
import Loading from "../../../../components/Loading";
import * as tpltService from "../../../../services/cmsTemplate";

class EditPopup extends Component {
	state = {
		open: false,
		data: {},
		loaded: true,
	};

	handleOpen = (data = null) => {
		if (data) {
			this.setState({ loaded: true, data, open: true });
		} else {
			this.setState({ loaded: true, open: true });
		}
	};

	handleClose = () => {
		this.setState({ open: false, data: {} });
	};

	onSubmit = async data => {
		const edit = !data.id ? tpltService.create : tpltService.update;
		if (!data.params) delete data.params;

		const newData = await edit(data);

		this.props.newData(newData);

		toast.success("Enregistrement réussi");

		this.handleClose();
	};

	render = () => {
		const { className } = this.props;
		const { loaded } = this.state;

		return (
			<Modal isOpen={this.state.open} className={className} fade={false}>
				<ModalHeader toggle={() => this.handleClose()}>
					Editer
				</ModalHeader>

				{!loaded && <Loading />}
				{loaded && (
					<FormEdit
						onSubmit={this.onSubmit}
						initialValues={this.state.data}
					/>
				)}
			</Modal>
		);
	};
}

export default EditPopup;
