import React, { Component } from "react";
import WHTable from "../../../components/WHTable";
import { Card, CardHeader, Button } from "reactstrap";
import * as contactService from "../../../services/rgpd/contact";
import PropTypes from "prop-types";
import PopUp from './SubmitPopup';
import Moment from "react-moment";
import EditPopup from "./EditPopup";

class Contact extends Component {
	state = {
		data: [],
		col: [],
		pages: null,
		loaded: true,
	};

	remove(id) {
		return contactService.remove(id).then(data => {
		});
	}

	newData = data => {
		let lst = [...this.state.data];

		let index = lst.findIndex(el => el.id === data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({
			data: lst,
		});
	};

	columns() {
		return [
			{
				Header: "",
				accessor: "id",
				filterable: false,
				Cell: row => (
					<div>
						{row.original.deletedAt == null &&
						<Button
							color={"danger"}
							onClick={() =>
								this.refs.popup.handleOpen(row.value)
							}
						>
							<i className={"fas fa-user-times"}/>
						</Button>
						}
						<Button
							color={"warning"}
							onClick={() =>
								this.refs.editpopup.handleOpen(row.value)
							}
						>
							<i className={"fas fa-edit"}/>
						</Button>
					</div>
				),
				maxWidth: 120,
			},
			{
				Header: "Nom",
				accessor: "firstName",
			},
			{
				Header: "Prénom",
				accessor: "lastName",
			},
			{
				Header: "Téléphone",
				accessor: "phone",
			},
			{
				Header: "Email",
				accessor: "email",
			},
			{
				Header: "Message",
				accessor: "message",
			},
			{
				Header: "Résidence",
				accessor: "residence",
			},
			{
				Header: "date de création",
				accessor: "createdAt",
				Cell: row => <Moment format="DD/MM/YYYY HH:mm">{row.value}</Moment>,
			},
			{
				Header: "date de demande",
				accessor: "askDate",
				Cell: row => row.value && <Moment format="DD/MM/YYYY">{row.value}</Moment>,
			},
			{
				Header: "date de suppression",
				accessor: "deletedAt",
				Cell: row => row.value && <Moment format="DD/MM/YYYY HH:mm">{row.value}</Moment>,
			},
		];
	}

	load = state => {
		this.setState({
			loaded: false,
		});

		return contactService.search({...state,  filtered: state.filtered.concat({ id: "adressContact[exists]", value: '1' })}).then(data => {
			this.setState(
				{
					data: [],
				},
				() => {
					this.setState({
						data: data["hydra:member"],
						pages: Math.ceil(
							data["hydra:totalItems"] / state.pageSize,
						),
						loaded: true,
					});
				},
			);
		});
	};

	render() {
		const { data, pages } = this.state;
		return (
			<div className="animated fadeIn">

				<PopUp
					ref={"popup"}
					newData={this.newData}
				/>

				<EditPopup
					ref={"editpopup"}
					newData={this.newData}
				/>

				<Card>
					<CardHeader>
						<i className="fa fa-align-justify" />
						&nbsp;Liste des demandes de contact
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable={true}
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
				</Card>
			</div>
		);
	}
}

Contact.propTypes = {
	local: PropTypes.string.isRequired,
	locals: PropTypes.array.isRequired,
};

export default Contact;
