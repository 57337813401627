import React, { Component } from "react";
import Loading from "../../../components/Loading";
import Form from "./Form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { SubmissionError } from "redux-form";
import { bindActionCreators } from "redux";
import merge from "lodash/merge";
import washJson from "../../../utils/washJson";
import {
	getLocal,
	getLocals,
	setLocal,
	getDefaultLocal,
} from "../../../reducers/params";
import { toast } from "react-toastify";
import Locals from "../../../components/Locals";
import * as pageService from "../../../services/cmsPage";

import tplt from "../../../constants/tplt/pages";
import {getUser} from "../../Authentication/redux";
import {ROLE_ADMIN} from "../../../constants/roles";

class Page extends Component {
	state = {
		data: {},
		loaded: false,
	};

	componentDidMount = () => {
		this.load();
	};

	copy = async (locale) =>
	{
		var localeWord = locale === 'en' ? 'l\'Anglais' : 'le Chinois';

		if (
			window.confirm("Voulez vous vraiment copier le contenu de la page vers " + localeWord  + "? Si des traductions sur cette page existe elles seront supprimées")
		) {

		return pageService.copy(this.state.data.id, locale)
			.then(() => {
				window.location.reload();
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
		}
	}

	load = async () => {
		const { locals, defaultLocal } = this.props;
		const { id } = this.props.match.params;

		if (id) {
			let data = await pageService.view(id);

			// data = merge(JSON.parse(JSON.stringify(tplt[data.template])), data);

			if (!data.translate) data.translate = {};

			locals.map(local => {
				if (local != defaultLocal) {
					if (!data.translate[local]) data.translate[local] = {};
					if (!data.translate[local].builder) {
						data.translate[local].builder = JSON.parse(
							JSON.stringify(tplt[data.template].builder),
						);
					}
					if (typeof data.translate[local].builder !== "object")
						data.translate[local].builder = washJson(
							data.translate[local].builder,
						);
				}
			});

			this.setState({
				data,
				loaded: true,
			});
		} else {
			this.setState({
				loaded: true,
				data: tplt["home"],
			});
		}
	};

	submit = values => {
		const edit = !values.id ? pageService.create : pageService.update;
		const caseForm = !values.id ? "Création réussie" : "Modification réussie";

		(this.props.user.roles.includes('ROLE_RGPD') && !this.props.user.roles.includes('ROLE_ADMIN')) && delete values.parent;

		return edit(values)
			.then(() => {
				toast.success(caseForm);
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	};

	render() {
		const { data } = this.state;
		const { local, defaultLocal } = this.props;

		return (
			<div className="animated fadeIn">
				<h1>Editer une page</h1>
				{!this.state.loaded && <Loading />}
				{this.state.loaded && (
					<div>
						<Locals />
						<Form
							ref="form"
							onSubmit={this.submit}
							pageId={this.props.match.params}
							local={local}
							defaultLocal={defaultLocal}
							initialValues={data}
							copy={this.copy}
						/>
					</div>
				)}
			</div>
		);
	}
}

Page.defaultProps = {
	local: null,
	defaultLocal: null,
};

Page.propTypes = {
	local: PropTypes.string,
	defaultLocal: PropTypes.string,
	locals: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
	local: getLocal(state),
	defaultLocal: getDefaultLocal(state),
	locals: getLocals(state),
	user: getUser(state),
});

export const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			setLocal,
		},
		dispatch,
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Page);
