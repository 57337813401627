const body = {
	tplt: "/page/cgu.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Introduction",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content:
						"<h2>Conditions générales <br><span>de vente et d’utilisation</span></h2>",
				},
			},
			subTitle: {
				admin: {
					template: "BlocText1",
					content:
						'<h3 class="underline black">Conditions générales de vente</h3>',
				},
			},
			content: {
				admin: {
					template: "BlocCollection",
					title: "Paragraphes",
					add: true,
					editable: true,
					type: "tplt-paragraphe",
					defaultTemplates: [
						{
							admin: {
								editable: true,
								add: false,
								template: "BlocContainer",
								type: "tplt-paragraphe",
								title: "Paragraphes",
								fields: {
									title: {
										admin: {
											template: "BlocTitle",
											type: "tplt-title",
											content:
												'<h5 class="mb-0">Article 1. </h5 >',
										},
									},
									content: {
										admin: {
											template: "BlocText1",
											type: "tplt-paragraph",
											content:
												"<p>Les présentes conditions générales</p>",
										},
									},
								},
							},
						},
					],
				},
			},
		},
	},
};

export default {
	template: "cgu",
	name: "CGU",
	editable: false,
	add: false,
	seo: {
		robots: "noindex,follow",
	},
	builder: [body],
};
