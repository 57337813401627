import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import FormCreate from "./FormCreate";
import merge from "lodash/merge";
import * as Service from "../../../../services/cmsPost";
import templates from "../../../../constants/tplt/post";

class CreatePopup extends Component {
	state = {
		open: false,
	};

	handleOpen = () => {
		const tplts = [];
		let template = null;

		Object.keys(templates).map(key => {
			tplts.push({
				value: key,
				name: templates[key].name,
			});
			if (templates[key].default) template = templates[key];
		});

		this.setState({ open: true, data: template, tplts });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	onSubmit = async data => {
		const { onCreate, locals, defaultLocal } = this.props;

		data.builder = [];
		data = merge(
			JSON.parse(JSON.stringify(templates[data.template])),
			data,
		);

		data.translate = {};

		if (data.builder) {
			locals.map(el => {
				if (el != defaultLocal)
					data.translate[el] = { builder: data.builder };
			});
		}
		const res = await Service.create(data);
		onCreate(res.id);
	};

	render() {
		const { data, tplts } = this.state;

		return (
			<Modal isOpen={this.state.open} className="xs" fade={false}>
				<ModalHeader toggle={() => this.handleClose()}>
					Créer
				</ModalHeader>

				<FormCreate
					onSubmit={this.onSubmit}
					initialValues={data}
					tplts={tplts}
				/>
			</Modal>
		);
	}
}

export default CreatePopup;
