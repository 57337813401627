import React, { Component } from "react";
import WHTable from "../../../components/WHTable";
import { Card, CardHeader } from "reactstrap";
import * as consentService from "../../../services/rgpd/consent";
import PropTypes from "prop-types";
import Moment from "react-moment";

class Consent extends Component {
	state = {
		data: [],
		col: [],
		pages: null,
		loaded: true,
	};

	columns() {
		return [
			{
				Header: "Nom",
				accessor: "name",
				maxWidth: 200,
			},
			{
				Header: "Consentement",
				accessor: "text",
				filterable: false,
				minWidth: 500,
			},
			{
				Header: "date",
				accessor: "created",
				filterable: false,
				Cell: row => row.value && <Moment format="DD/MM/YYYY HH:mm">{row.value}</Moment>,
			},
			{
				Header: "Adresse IP",
				accessor: "ipAddress",
			},
		];
	}

	load = state => {
		this.setState({
			loaded: false,
		});

		return consentService.search(state).then(data => {
			this.setState(
				{
					data: [],
				},
				() => {
					this.setState({
						data: data["hydra:member"],
						pages: Math.ceil(
							data["hydra:totalItems"] / state.pageSize,
						),
						loaded: true,
					});
				},
			);
		});
	};

	render() {
		const { data, pages } = this.state;
		return (
			<div className="animated fadeIn">

				<Card>
					<CardHeader>
						<i className="fa fa-align-justify" />
						&nbsp;Liste des Consentement
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable={true}
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
				</Card>
			</div>
		);
	}
}

Consent.propTypes = {
	local: PropTypes.string.isRequired,
	locals: PropTypes.array.isRequired,
};

export default Consent;
