const bloc01 = {
	tplt: "/page/logements/bloc-01.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Introduction",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content: "<h1>Des appart's...</h1><p>bolobo</p>",
				},
			},
			image: {
				admin: {
					template: "BlocMedia",
				},
			},
		},
	},
};

const bloc02 = {
	tplt: "/page/logements/bloc-02.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Logement répondent ...",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content: "<h2>Des logements ...</h2><p>bolobo</p>",
				},
			},
			background: {
				admin: {
					template: "BlocMedia",
				},
			},
		},
	},
};

const bloc03Item = {
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Photo",
		fields: {
			photo: {
				admin: {
					template: "BlocMedia",
					type: "tplt-picture",
				},
			},
		},
	},
};

const bloc03 = {
	tplt: "/page/logements/bloc-03.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Inclus à la carte",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content:
						"<h2>Des services inclus ou à la carte</h2><h3>et des avantages qui facilitent votre quotidien</h3>",
				},
			},
			children: {
				type: "tplt-slider-horizontal",
				admin: {
					template: "BlocRequest",
					title: "Slider",
					add: false,
					editable: true,
					content: {
						service: "/api/services",
						filters: { itemsPerPage: 50, order: [] },
					},
				},
			},
		},
	},
};

const bloc04 = {
	tplt: "/page/logements/bloc-04.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Situation géographique",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content:
						"<h2>Une situation géographique privilégiée</h2><p>bolobo</p>",
				},
			},
			background: {
				admin: {
					template: "BlocMedia",
				},
			},
		},
	},
};

const bloc05 = {
	tplt: "/page/logements/bloc-05.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Dans une volonté constante ... ",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content:
						'<div class="container"><p>bolboo <b>bolobolo</b></p></div>',
				},
			},
		},
	},
};

const bloc06 = {
	tplt: "/page/logements/bloc-06.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Un esprit ... ",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content:
						"<h2>Un esprit et une identité ...</h2><p>bolobo</p>",
				},
			},
			background: {
				admin: {
					template: "BlocMedia",
				},
			},
		},
	},
};

const bloc07Item = {
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Blocs",
		fields: {
			photo: {
				admin: {
					template: "BlocText1",
					content:
						'<div class="picto"><span>iconn</span></div><h4>Signalement d’un incident....</h4>',
				},
			},
		},
	},
};

const bloc07 = {
	tplt: "/page/logements/bloc-07.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "L’app suite étude .. ",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content: "<h2>L'App Suite étude</h2><p>bolobo</p>",
				},
			},
			caroussel: {
				admin: {
					template: "BlocCollection",
					title: "Portolfio",
					type: "tplt-slider-horizontal",
					add: true,
					editable: true,
					defaultTemplates: [bloc07Item],
				},
			},
		},
	},
};

export default {
	template: "logements",
	name: "Page nos logements",
	editable: false,
	add: false,
	seo: {
		robots: "index,follow",
	},
	builder: [bloc01, bloc02, bloc03, bloc04, bloc05, bloc06, bloc07],
};
