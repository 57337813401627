import React, { Component } from "react";
import WHTable from "../../components/WHTable";
import { Card, CardHeader, Button } from "reactstrap";
import CreateEditPopup from "./CreateEditPopup";
import * as PoiService from "../../services/poiFamilies";
import PropTypes from "prop-types";

class Poi extends Component {
	state = {
		data: [],
		col: [],
		pages: null,
		loaded: true,
	};

	columns() {
		return [
			{
				Header: "",
				accessor: "id",
				filterable: false,
				Cell: row => (
					<div>
						<Button
							color={"info"}
							onClick={() =>
								this.refs.createEditPopup.handleOpen(row.value)
							}
						>
							<i className={"fa fa-edit"} />
						</Button>
						<Button
							color={"danger"}
							onClick={() => this.remove(row.value)}
						>
							<i className={"fa fa-trash"} />
						</Button>
					</div>
				),
				maxWidth: 120,
			},
			{
				Header: "Réference",
				accessor: "id",
			},
			{
				Header: "Nom",
				accessor: "name",
			},
			{
				Header: "Couleur",
				accessor: "color",
				filterable: false,
				Cell: row => (
					<div
						style={{
							maxWidth: "100px",
							width: "100%",
							height: "100%",
							backgroundColor: row.value,
							borderRadius: "2px",
							margin: "auto",
						}}
					/>
				),
			},
			{
				Header: "Priorité",
				accessor: "position",
			},
			{
				Header: "Icone",
				accessor: "icon",
				filterable: false,
				Cell: row =>
					row.value && (
						<div className={"text-center"}>
							<i className={row.value + " fa-3x"} />
						</div>
					),
			},
		];
	}

	load = state => {
		this.setState({
			loaded: false,
		});

		return PoiService.search(state).then(data => {
			this.setState(
				{
					data: [],
				},
				() => {
					this.setState({
						data: data["hydra:member"],
						pages: Math.ceil(
							data["hydra:totalItems"] / state.pageSize,
						),
						loaded: true,
					});
				},
			);
		});
	};

	remove = toDelete => {
		if (window.confirm("Êtes-vous sûr de vouloir supprimer cet item ?")) {
			this.setState({
				loaded: false,
			});

			PoiService.remove(toDelete)
				.then(() => {
					let lst = [...this.state.data];

					let index = lst.findIndex(el => el.id === toDelete);

					if (index > -1) {
						lst.splice(index, 1);

						this.setState({
							data: lst,
							loaded: true,
						});
					}
				})
				.catch(() => {
					alert(
						"Il existe des dépendances cet item, il ne peut donc être supprimé",
					);
				});
		}
	};

	newData = data => {
		let lst = [...this.state.data];

		let index = lst.findIndex(el => el.id === data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({
			data: lst,
		});
	};

	render() {
		const { data, pages } = this.state;
		return (
			<div className="animated fadeIn">
				<CreateEditPopup
					ref={"createEditPopup"}
					newData={this.newData}
				/>

				<Card>
					<CardHeader>
						<i className="fa fa-align-justify" />
						&nbsp;Gestion des POI
						<div className="card-actions">
							<button
								onClick={() =>
									this.refs.createEditPopup.handleOpen()
								}
								className={"btn btn-success"}
							>
								<i className={"fa fa-plus"} />
								&nbsp;Nouveau
							</button>
						</div>
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable={true}
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
				</Card>
			</div>
		);
	}
}
CreateEditPopup.defaultProps = {};

Poi.propTypes = {
	local: PropTypes.string.isRequired,
	locals: PropTypes.array.isRequired,
};

export default Poi;
