import React, {Component} from 'react';
import {Modal, ModalHeader} from 'reactstrap';
import PromotionForm from './TypologyForm';
import * as TypologyService from '../../../services/typology';
import {SubmissionError} from 'redux-form';
import {toast} from 'react-toastify';
import Loading from '../../../components/Loading';
import Locals from "../../../components/Locals";

class CreateEditPopup extends Component {


    state = {
        open: false,
        data: {},
        loaded: true
    };


    handleOpen = (id = null) => {
        this.setState({
            open: true
        });

        if (id) {
            this.setState({
                loaded: false
            });

            TypologyService.view(id).then((data) => {
                this.setState({
                    data,
                    loaded: true
                });
            });
        }
    };

    handleClose = () => {
        this.setState({
            open: false,
            data: {}
        });
    };

    onSubmit = (data) => {
        const edit = (!data.id) ? TypologyService.create : TypologyService.update;

        return edit(data).then((data) => {
            this.props.newData(data);

            toast.success('Enregistrement réussi');

            this.handleClose();
        }).catch((error) => {
            throw new SubmissionError(error);
        });
    };

    render() {
        const {className} = this.props;
        const {loaded, open} = this.state;

        return (

            <Modal isOpen={open} className={className} fade={false}>

                <ModalHeader toggle={this.handleClose}>Editer</ModalHeader>

                {!loaded &&
                <Loading/>
                }

                {loaded &&
                <div>
                    <Locals/>
                    <PromotionForm onSubmit={this.onSubmit} initialValues={this.state.data}/>
                </div>
                }

            </Modal>

        );

    }
}

export default CreateEditPopup
