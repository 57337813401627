import React, { Component } from "react";
import { Form, ModalBody, ModalFooter, Alert } from "reactstrap";
import { Field, reduxForm, getFormValues } from "redux-form";
import {
	WhFieldTxt,
	WhFieldSelect,
	WhFieldFile,
} from "../../components/Form/whFields";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { connect } from "react-redux";

class ParameterForm extends Component {
	test = () => {};

	render() {
		const { error, handleSubmit, submitting, currentValues } = this.props;

		return (
			<Form onSubmit={handleSubmit} className="form-vertical">
				<ModalBody>
					<Field
						label="Nom :"
						name="name"
						component={WhFieldTxt}
						type="text"
					/>

					<Field
						label="Slug :"
						name="slug"
						component={WhFieldTxt}
						type="text"
					/>

					<Field
						label="Type : "
						name="type"
						component={WhFieldSelect}
						type="select"
						options={[
							{ value: "string", name: "Texte" },
							{ value: "text", name: "Paragraphe" },
							{ value: "external-link", name: "Lien externe" },
							{ value: "string", name: "text" },
						]}
						onChange={this.change}
					/>

					{currentValues && currentValues.type == "string" && (
						<Field
							label="Valeur courte :"
							name="valueString"
							component={WhFieldTxt}
							type="text"
						/>
					)}

					{currentValues && currentValues.type == "text" && (
						<Field
							label="Valeur longue :"
							name="valueText"
							component={WhFieldTxt}
							type="textarea"
						/>
					)}

					{currentValues &&
						(currentValues.type == "external-link" ||
							currentValues.type == "internal-link") && (
							<Field
								label="Lien  :"
								name="valueLink"
								component={WhFieldTxt}
								type="text"
							/>
						)}

					{currentValues && currentValues.type == "file" && (
						<Field
							label="File : "
							name="file"
							component={WhFieldFile}
						/>
					)}

					{currentValues && currentValues.type == "image" && (
						<Field
							label="Image : "
							name="image"
							component={WhFieldFile}
							type="text"
						/>
					)}

					{error && (
						<Alert color="danger">
							<div
								dangerouslySetInnerHTML={{
									__html: error,
								}}
							/>
						</Alert>
					)}
				</ModalBody>

				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type="submit"
					>
						<i className="fa fa-save" />
						&nbsp;Enregistrer
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

const formName = "ParameterForm";
const ReduxFormWrapped = reduxForm({
	form: formName,
})(ParameterForm);

const mapState = state => ({
	currentValues: getFormValues(formName)(state),
});

const FullWrappedParametersForm = connect(mapState)(ReduxFormWrapped);

export default FullWrappedParametersForm;
