import React from "react";
import PropTypes from "prop-types";
import {
	Field,
	FormSection,
	reduxForm,
	FieldArray,
	getFormValues,
} from "redux-form";
import { connect } from "react-redux";
import ApiConfig from "../../../constants/ApiConfig";
import Save from "../../../components/Save";
import classnames from "classnames";
import {
	WhFieldTxt,
	WhFile,
	WhFieldBuilder,
	WhFieldSelectAsync,
	WhFieldTranslate,
	WhValidator,
	WhFieldSelect,
	WhParseDate,
	WhFieldCheckbox,
	WhParseInt,
	whParseSelect,
} from "../../../components/Form/whFields";
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Form,
	Alert,
	Button,
	Row,
	Col,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
} from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { getCmsStatus, getSeoRobots } from "../../../reducers/params";
import * as pageService from "../../../services/cmsPage";
import * as cityService from "../../../services/city";
import * as residenceService from "../../../services/residence";
import FormSeo from "../Seo/Form";

class FormEdit extends React.Component {
	state = {
		activeTab: "1",
	};

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}

	loadParents = async input => {
		const data = await pageService.search({
			filtered: [
				{ id: "title", value: input },
				{ id: "template", value: "category" },
			],
		});

		const lst = data["hydra:member"].map(el => ({
			value: el.id,
			label: `${el.title}`,
		}));

		return { options: lst };
	};

	loadCities = async input => {
		const data = await cityService.search({
			filtered: [{ id: "title", value: input }],
		});

		const lst = data["hydra:member"].map(el => ({
			value: el.id,
			id: el.id,
			label: `${el.title}`,
		}));
		return { options: lst };
	};

	loadResidences = async input => {
		const data = await residenceService.search({
			filtered: [{ id: "name", value: input }],
		});

		const lst = data["hydra:member"].map(el => ({
			value: el.id,
			id: el.id,
			label: `${el.title}`,
		}));

		return { options: lst };
	};

	render() {
		const {
			error,
			handleSubmit,
			initialValues,
			submitting,
			change,
			cmsStatus,
			currentValues,
			local,
			defaultLocal,
			seoRobots,
		} = this.props;
		return (
			<Form onSubmit={handleSubmit}>
				<Row>
					<Col sm="8" xs={12} className="form-vertical">
						<Card>
							<CardHeader>
								<Nav tabs>
									<NavItem>
										<NavLink
											className={classnames({
												active:
													this.state.activeTab ===
													"1",
											})}
											onClick={() => {
												this.toggle("1");
											}}
										>
											Contenu
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({
												active:
													this.state.activeTab ===
													"2",
											})}
											onClick={() => {
												this.toggle("2");
											}}
										>
											Medias
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({
												active:
													this.state.activeTab ===
													"3",
											})}
											onClick={() => {
												this.toggle("3");
											}}
										>
											SEO
										</NavLink>
									</NavItem>
								</Nav>
								<Save
									data={currentValues}
									setHistory={change}
									type="post"
									copy={this.props.copy}
								/>
							</CardHeader>
							<TabContent activeTab={this.state.activeTab}>
								<TabPane tabId="1">
									<WhFieldTranslate
										label={false}
										name="title"
										validate={[WhValidator.required]}
										component={WhFieldTxt}
										type="text"
										translatable={local}
										placeholder="Saisir un titre"
										bsSize="lg"
									/>

									<WhFieldTranslate
										label={false}
										name="slug"
										validate={[WhValidator.required]}
										component={WhFieldTxt}
										translatable={local}
										type="text"
										placeholder="Non dans l'url"
										bsSize="xs"
									/>

									<WhFieldTranslate
										label={false}
										name="summary"
										translatable={local}
										component={WhFieldTxt}
										type="textarea"
										placeholder="Résumé"
									/>

									<div className="content">
										<FieldArray
											name={
												local === defaultLocal
													? `builder`
													: `translate.${local}.builder`
											}
											component={WhFieldBuilder}
										/>
									</div>
								</TabPane>
								<TabPane tabId="2">
									<Row>
										<Col md={4}>
											<Field
												withHeader={true}
												label="Vignette liste actu"
												name="vignette"
												width="640"
												height="427"
												component={WhFile}
											/>
										</Col>
										<Col md={4}>
											<Field
												withHeader={true}
												label="Vignette home & ville"
												name="vignetteHome"
												component={WhFile}
											/>
										</Col>
										<Col md={4}>
											<Field
												withHeader={true}
												label="Vignette résidence"
												name="vignetteResidence"
												component={WhFile}
											/>
										</Col>
									</Row>
								</TabPane>
								<TabPane tabId="3">
									<FormSection name="seo">
										<FormSeo
											seoRobots={seoRobots}
											local={local}
										/>
									</FormSection>
								</TabPane>
							</TabContent>
						</Card>
					</Col>
					<Col sm="4" xs={12}>
						<Card>
							<CardHeader>
								<i className="fa fa-align-justify" /> Edition
							</CardHeader>

							<CardBody className="form-vertical">
								<WhFieldTranslate
									label="Statut de publication :"
									name="status"
									validate={[WhValidator.required]}
									nullValue="Choisir un statut"
									required={false}
									component={WhFieldSelect}
									type="select"
									options={cmsStatus}
									translatable={local}
								/>

								<Field
									label="Date de publication :"
									name="publicationDate"
									format={WhParseDate}
									component={WhFieldTxt}
									type="date"
								/>
								<Field
									label="Date de fin de publication :"
									name="publicationDateEnd"
									format={WhParseDate}
									component={WhFieldTxt}
									type="date"
								/>
								<Field
									label="Score :"
									name="score"
									parse={WhParseInt}
									component={WhFieldTxt}
									type="number"
								/>

								{error && (
									<Alert color="danger">
										<div
											dangerouslySetInnerHTML={{
												__html: error,
											}}
										/>
									</Alert>
								)}
							</CardBody>

							<CardFooter>
								<LaddaButton
									className="btn btn-success btn-ladda"
									loading={submitting}
									data-style={EXPAND_LEFT}
									type="submit"
								>
									<i className="fa fa-save" /> Enregistrer{" "}
								</LaddaButton>
							</CardFooter>
						</Card>
						{currentValues.id && (
							<Card>
								<a
									href={`${ApiConfig.url}/redirect/post/${
										currentValues.id
									}`}
									target="_blank"
									className="btn btn-block btn-success btn-ld"
								>
									Voir
								</a>
							</Card>
						)}

						<Card>
							<CardHeader>
								<i className="fa fa-align-justify" /> Push(s)
							</CardHeader>

							<CardBody>
								<Field
									label="Accueil ça se passe en ce moment"
									reverted
									name="pushHomeNow"
									component={WhFieldCheckbox}
								/>
								<Field
									label="Accueil guide populaires"
									reverted
									name="pushHomePopular"
									component={WhFieldCheckbox}
								/>
								<Field
									label="Side bar blog guide populaires"
									reverted
									name="pushSideBarPopularBlog"
									component={WhFieldCheckbox}
								/>
								<Field
									label="Side bar blog modèle doc"
									reverted
									name="pushSideBarDocBlog"
									component={WhFieldCheckbox}
								/>
							</CardBody>
						</Card>

						<Card>
							<CardHeader>
								<i className="fa fa-align-justify" /> Catégories
							</CardHeader>

							<CardBody className="form-vertical">
								<Field
									label="Catégorie principale :"
									name="category"
									parse={whParseSelect}
									component={WhFieldSelectAsync}
									type="select"
									loadOptions={this.loadParents}
								/>
								<Field
									label="Catégories secondaires :"
									name="categories"
									parse={whParseSelect}
									component={WhFieldSelectAsync}
									type="select"
									multi
									loadOptions={this.loadParents}
								/>
								<Field
									label="Villes :"
									name="cities"
									parse={whParseSelect}
									component={WhFieldSelectAsync}
									type="select"
									multi
									loadOptions={this.loadCities}
								/>
								<Field
									label="Résidences :"
									name="residences"
									parse={whParseSelect}
									component={WhFieldSelectAsync}
									type="select"
									multi
									loadOptions={this.loadResidences}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}
}

const formName = "FormPost";

const rxf = reduxForm({ form: formName })(FormEdit);

FormEdit.defaultProps = {
	error: "",
	categories: [],
	cmsStatus: [],
	seoRobots: [],
	currentValues: {},
};
FormEdit.propTypes = {
	error: PropTypes.string,
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	currentValues: PropTypes.shape({}),
	cmsStatus: PropTypes.array,
	seoRobots: PropTypes.array,
};

export const mapStateToProps = state => ({
	currentValues: getFormValues(formName)(state),
	cmsStatus: getCmsStatus(state),
	seoRobots: getSeoRobots(state),
});

export default connect(mapStateToProps)(rxf);
