import React, { Component } from "react";
import { Field } from "redux-form";
import {Col, Row, Button} from "reactstrap";
import {WhFile} from "../../../components/Form/whFields";
import ModalFloor from "./ModalFloor";
import {toast} from 'react-toastify';

class Floors extends Component {

    alertNoPlan = () => {
        toast.error("Veuillez d'abord ajouter un plan");
    }

    render() {
        const { fields } = this.props;
        return (
            <div>
                <ModalFloor
                    ref={"modalFloor"}
                />
                {fields.map((el, index) => (
                    <Row>
                        <Col key={`col_${index}`} md={8}>
                            <Field
                                label={`Etage ${fields.get(index).value}`}
                                name={`${el}.plan`}
                                component={WhFile}
                                type="text"
                            />
                        </Col>
                        <Col key={`col_${(index + 1) * 10}`} md={3}>
                            <Button
                                color={"info"}
                                onClick={fields.get(index).plan ? () => this.refs.modalFloor.handleOpen(fields.get(index)) : () => this.alertNoPlan()}
                            > <i style={{marginBottom: "1rem"}} className="fa fa-crosshairs"></i> Placer les points pour l'étage {fields.get(index).value} </Button>
                        </Col>
                    </Row>
                    )
                )}
            </div>
        );
    }
}

export default Floors;
