const introductionRes = {
	tplt: "/residence/list.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Introduction",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content:
						'<div class="row"><div class= "col-12"><h1>Je recherche ma résidence</h1></div><div class="col-8 offset-2"><p>Etenim si attendere diligenter, existimare vere de omni hac causa volueritis, sic constituetis, iudices, nec descensurum quemquam ad hanc accusationem fuisse, cui, utrum vellet, liceret, nec, cum descendisset, qui</p></div></div>',
				},
			},
			content: {
				admin: {
					template: "BlocModule",
					title: "Liste des résidences / map",
				},
			},
		},
	},
};

export default {
	template: "listresidence",
	name: "Liste des residences",
	editable: false,
	add: false,
	seo: {
		robots: "index,follow",
	},
	builder: [introductionRes],
};
