export const cityHeaderTitle =
	'<h1>Trouvez vos résidences étudiante à <span class="blold">Ville</span></h1><h2>Apprenez ...</h2><p>Equitis Romani ...</p>';

export const cityDescriptionTitle =
	'<h3 class="underline black">NOS RÉSIDENCES SUITÉTUDES À NANTES</h3>';

export const cityDescriptionDesc =
	"<h2>Ville</h2><h3>La ville...</h3><p>In vehicula urna a est vulputate...</p>";

export const cityBlocAvantage =
	'<div class="picto"><img src="/build/images/picto/etudiant.svg"></div><h4>Atout</h4><h5>Avantages 2nd</h5></div>';

export const cityFooterTitle =
	'<h2><span>Ça se passe</span><span class="bold"> à Ville !</span></h2><h3 class="subtitle">les actus à ne pas manquer</h3>';

export const practicesPlacesTitle =
	'<h3 class="underline black">Les lieux pratiques à nantes</h3>';

export const practicesPlacesListFlap =
	'<a class="btn choice" href="#" target="_blank">La caf de Nantes</a>';

export const headerContent = {
	tplt: "/address/city/header.html.twig",
	admin: {
		template: "BlocContainer",
		title: "Contenu entête",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content: cityHeaderTitle,
				},
			},
		},
	},
};

export const blockMap = {
	tplt: "/address/city/map.html.twig",
	admin: {
		template: "BlocContainer",
		title: "Map",
		fields: {},
	},
};

export const blockDescription = {
	tplt: "/address/city/description.html.twig",
	admin: {
		template: "BlocContainer",
		title: "Résidences",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content: cityDescriptionTitle,
				},
			},
			description: {
				admin: {
					template: "BlocText1",
					content: cityDescriptionDesc,
				},
			},
		},
	},
};
export const blockAvantagesFlap = {
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Item",
		fields: {
			media: {
				admin: {
					template: "BlocText1",
					content: cityBlocAvantage,
				},
			},
		},
	},
};

export const blockAvantages = {
	tplt: "/address/city/avantages.html.twig",
	admin: {
		template: "BlocContainer",
		title: "Avantages",
		fields: {
			description: {
				admin: {
					template: "BlocMedia",
				},
			},
			children: {
				admin: {
					template: "BlocCollection",
					title: "Liste avantages",
					type: "tplt-slider-horizontal",
					add: true,
					editable: true,
					defaultTemplates: [blockAvantagesFlap],
				},
			},
		},
	},
};

export const blockPracticesPlacesListFlap = {
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Item",
		fields: {
			media: {
				admin: {
					template: "BlocTitle",
					content: practicesPlacesListFlap,
				},
			},
		},
	},
};

export const blockFooter = {
	tplt: "/address/city/footer.html.twig",
	admin: {
		template: "BlocContainer",
		title: "Bas de bage",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content: cityFooterTitle,
				},
			},
			posts: {
				admin: {
					template: "BlocContainer",
					title: "Posts",
					fields: {},
				},
			},
			practicesPlaces: {
				admin: {
					template: "BlocText1",
					content: practicesPlacesTitle,
				},
			},
			practicesPlacesList: {
				admin: {
					template: "BlocCollection",
					title: "Liste liens",
					type: "tplt-slider-horizontal",
					add: true,
					editable: true,
					defaultTemplates: [blockPracticesPlacesListFlap],
				},
			},
		},
	},
};

export default {
	template: "city",
	name: "Ville",
	nbFlats: 0,
	new: false,
	nbResidences: 0,
	seo: {
		robots: "index,follow",
	},
	builder: [
		headerContent,
		blockMap,
		blockDescription,
		blockAvantages,
		blockFooter,
	],
};
