import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import CategoryForm from "./FaqQuestionForm";
import * as FaqQuestionService from "../../services/faqQuestion";
import { SubmissionError } from "redux-form";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import * as thematicService from "../../services/faqThematic";
import Locals from "../../components/Locals";
import PropTypes from "prop-types";

class CreateEditPopup extends Component {
	state = {
		open: false,
		data: {},
		loaded: true,
		options: null,
	};

	componentDidMount() {
		const thematics = thematicService.options();

		this.setState({
			options: {
				thematics,
			},
		});
	}

	handleOpen = (id = null) => {
		this.setState({
			open: true,
		});

		if (id) {
			this.setState({
				loaded: false,
			});

			FaqQuestionService.view(id).then(data => {
				data.thematic = data.thematic["@id"];

				this.setState({
					data,
					loaded: true,
				});
			});
		}
	};

	handleClose = () => this.setState({ open: false, data: {} });

	onSubmit = data => {
		const edit = !data.id
			? FaqQuestionService.create
			: FaqQuestionService.update;

		return edit(data)
			.then(data => {
				this.props.newData(data);

				toast.success("Enregistrement réussi");

				this.handleClose();
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	};

	render() {
		const { loaded, options } = this.state;

		if (!options) {
			return null;
		}

		return (
			<Modal size="xs" isOpen={this.state.open} fade={false}>
				<ModalHeader toggle={() => this.handleClose()}>
					Editer <Locals />
				</ModalHeader>

				{!loaded && <Loading />}

				{loaded && (
					<CategoryForm
						options={options}
						onSubmit={this.onSubmit}
						initialValues={this.state.data}
					/>
				)}
			</Modal>
		);
	}
}

CreateEditPopup.defaultProps = {
	className: "",
};

CreateEditPopup.propTypes = {
	className: PropTypes.string,
};

export default CreateEditPopup;
