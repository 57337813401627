import React from "react";
import CKEditor from "ckeditor4-react";
import { change } from "redux-form";
import ApiConfig from "../../../../../constants/ApiConfig";

class Icomoon extends React.Component {
    onChange = evt => {
        const data = evt.editor.getData();
        this.props.meta.dispatch(
            change(this.props.meta.form, this.props.input.name, data),
        );
    };

    //Source, style, blocks, icones
    returnEditor = data => {
        CKEditor.editorUrl = ApiConfig.ckeditorInstance;

        return (
            <CKEditor
                data={data}
                type="inline"
                config={{
                    startupOutlineBlocks: "true",
                    toolbar: [
                        [
                            "Sourcedialog",
                            "Glyphicons",
                        ],
                    ],
                }}
                onChange={this.onChange}
            />
        );
    };

    returnContent = () => {
        const { data, input } = this.props;

        if (input && input.value) {
            return this.returnEditor(input.value);
        }

        if (data && data.admin && data.admin.content) {
            return this.returnEditor(data.admin.content);
        }
    };

    render = () => {
        const{label}=this.props;
        return <div className="Icomoon">{label}{this.returnContent()}</div>;
    };
}

export default Icomoon;
