import React, {Component} from "react";
import {
	Form, ModalBody, ModalFooter, Alert, CardBody,
	Card,
} from "reactstrap";
import {Field, reduxForm} from "redux-form";
import {
	WhFieldTxt,
	WhFieldCkEditor, WhFieldSelect, whParseSelect, WhFieldSelectAsync, WhFile,
} from "../../components/Form/whFields";
import LaddaButton, {EXPAND_LEFT} from "react-ladda";
import * as cityService from "../../services/city";
import * as typeService from "../../services/rh/type";
import {getCmsStatus} from "../../reducers/params";
import {connect} from "react-redux";

class ArticleForm extends Component {


	loadCities = async input => {
		const data = await cityService.search({
			filtered: [{
				id: "title",
				value: input
			}],
		});

		const lst = data["hydra:member"].map(el => ({
			value: el.id,
			id: el.id,
			label: `${el.title}`,
		}));
		return {options: lst};
	};

	loadTypes = async input => {
		const data = await typeService.search({
			filtered: [{
				id: "name",
				value: input
			}],
		});

		const lst = data["hydra:member"].map(el => ({
			value: el.id,
			id: el.id,
			label: `${el.name}`,
		}));
		return {options: lst};
	};

	render = () => {
		const {error, handleSubmit, submitting, cmsStatus} = this.props;

		return (

			<Form onSubmit={handleSubmit} className="form-vertical">
				<ModalBody>
					<Card>
						<CardBody>
							<Field
								label="Titre :"
								name="title"
								component={WhFieldTxt}
								require
								type="text"
							/>

							<Field
								label="Type : "
								name="type"
								component={WhFieldSelect}
								type="select"
								options={[
									{
										value: "revue",
										name: "Revue de presse"
									},
									{
										value: "communique",
										name: "Communiqué de presse"
									},
								]}
								onChange={this.change}
							/>

							<Field
								label="Contenu :"
								name="content"
								component={WhFieldCkEditor}
								require
								type="text"
							/>

							<Field
								label="Ville :"
								name="city"
								parse={whParseSelect}
								component={WhFieldSelectAsync}
								type="select"
								loadOptions={this.loadCities}
							/>

							<Field
								label="Logo"
								name="logo"
								component={WhFile}
								type="text"
							/>

							<Field
								label="Document"
								name="document"
								component={WhFile}
								type="text"
							/>

							{error && (
								<Alert color="danger">
									<div dangerouslySetInnerHTML={{__html: error}}/>
								</Alert>
							)}
						</CardBody>

					</Card>
				</ModalBody>

				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type="submit"
					>
						<i className="fa fa-save"/>
						&nbsp;Enregistrer
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	};
}

const mapStateToProps = state => ({
	cmsStatus: getCmsStatus(state),
});

ArticleForm = connect(
	mapStateToProps,
)(ArticleForm);

export default reduxForm({
	form: "ArticleForm",
})(ArticleForm);
