import React, { Component } from "react";
import WHTable from "../../../components/WHTable";
import * as lotService from "../../../services/lot";
import EuroFormat from "../../../components/EuroFormat/EuroFormat";
import Moment from "react-moment";

class Lots extends Component {
	state = {
		pages: null,
		data: [],
	};

	load = async state => {
		this.setState({
			loaded: false,
		});

		const data = await lotService.search({
			...state,
			filtered: [{ id: "residence.id", value: this.props.residenceId }],
		});

		this.setState(
			{
				data: [],
			},
			() => {
				this.setState({
					data: data["hydra:member"],
					pages: Math.ceil(data["hydra:totalItems"] / state.pageSize),
					loaded: true,
				});
			},
		);
	};

	columns = () => {
		return [
			{
				Header: "Type",
				accessor: "typology.name",
			},
			{
				Header: "Numéro",
				accessor: "number",
			},
			{
				Header: "Etage",
				accessor: "floor.value",
			},
			{
				Header: "Surface",
				accessor: "surface",
				Cell: row => (
					<div>
						{row.value}m<sup>2</sup>
					</div>
				),
			},
			{
				Header: "Terrasse",
				accessor: "outdoor",
				Cell: row => <div>{row.value === 1 ? "Oui" : "Non"}</div>,
			},
			{
				Header: "Prix",
				accessor: "priceTtc",
				Cell: row => <EuroFormat value={row.value} />,
			},
			{
				Header: "Date Dispo",
				accessor: "availabilityDate",
				Cell: row => (
					<div>
						{row.value ? (
							<Moment date={row.value} format="DD/MM/YYYY" />
						) : (
							`-`
						)}
					</div>
				),
			},
		];
	};

	render = () => {
		const { data, pages } = this.state;

		return (
			<WHTable
				data={data}
				pages={pages}
				columns={this.columns()}
				filterable={false}
				loading={false}
				onFetchData={this.load}
			/>
		);
	};
}

export default Lots;
