import React, {Component} from "react";
import {
	Form, ModalBody, ModalFooter, Alert, TabPane, NavItem, NavLink, Nav, CardBody,
	CardHeader,
	Card, TabContent,
} from "reactstrap";
import {Field, FormSection, reduxForm} from "redux-form";
import {
	WhFieldTxt,
	WhFieldCkEditor, WhFieldSelect, whParseSelect, WhFieldSelectAsync, WhValidator,
} from "../../../components/Form/whFields";
import LaddaButton, {EXPAND_LEFT} from "react-ladda";
import * as cityService from "../../../services/city";
import * as typeService from "../../../services/rh/type";
import FormSeo from "../../Cms/Seo/Form";
import {getCmsStatus, getSeoRobots} from "../../../reducers/params";
import {connect} from "react-redux";
import classnames from "classnames";

class JobForm extends Component {

	state = {
		activeTab: "content"
	}

	toggle = tab => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	};

	loadCities = async input => {
		const data = await cityService.search({
			filtered: [{
				id: "title",
				value: input
			}],
		});

		const lst = data["hydra:member"].map(el => ({
			value: el.id,
			id: el.id,
			label: `${el.title}`,
		}));
		return {options: lst};
	};

	loadTypes = async input => {
		const data = await typeService.search({
			filtered: [{
				id: "name",
				value: input
			}],
		});

		const lst = data["hydra:member"].map(el => ({
			value: el.id,
			id: el.id,
			label: `${el.name}`,
		}));
		return {options: lst};
	};

	render = () => {
		const {error, handleSubmit, submitting, cmsStatus, seoRobots} = this.props;
		const {activeTab} = this.state;

		return (

			<Form onSubmit={handleSubmit} className="form-vertical">
				<ModalBody>
					<Card>
						<CardHeader>
							<Nav tabs>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === "content",
										})}
										onClick={() => {
											this.toggle("content");
										}}
									>
										{" "}
										Contenus
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink
										className={classnames({active: activeTab === "seo"})}
										onClick={() => {
											this.toggle("seo");
										}}
									>
										{" "}
										SEO
									</NavLink>
								</NavItem>

							</Nav>
						</CardHeader>

						<CardBody>
							<TabContent activeTab={activeTab}>
								<TabPane tabId="content">
									<Field
										label="Titre :"
										name="title"
										component={WhFieldTxt}
										require
										type="text"
									/>

									<Field
										label="Description :"
										name="description"
										component={WhFieldCkEditor}
										require
										type="text"
									/>

									<Field
										label="Type de contrat :"
										name="type"
										parse={whParseSelect}
										component={WhFieldSelectAsync}
										type="select"
										loadOptions={this.loadTypes}
									/>

									<Field
										label="Email de reception des candidatures :"
										name="email"
										component={WhFieldTxt}
										type="text"
									/>

									<Field
										label="Salaire :"
										name="salary"
										component={WhFieldTxt}
										type="text"
									/>

									<Field
										label="Ville :"
										name="city"
										parse={whParseSelect}
										component={WhFieldSelectAsync}
										type="select"
										loadOptions={this.loadCities}
									/>

									<Field
										label="Phrase d'accroche :"
										name="catchphrase"
										component={WhFieldTxt}
										type="text"
									/>

									<Field
										label="Statut de publication :"
										name="status"
										required
										validate={[WhValidator.required]}
										component={WhFieldSelect}
										type="select"
										options={cmsStatus}
									/>

									<Field
										label="Langue : "
										name="locale"
										component={WhFieldSelect}
										type="select"
										options={[
											{
												value: "fr",
												name: "Français"
											},
											{
												value: "en",
												name: "Anglais"
											},
											{
												value: "zh",
												name: "Chinois"
											},
										]}
										onChange={this.change}
									/>
								</TabPane>

								<TabPane tabId="seo">
									<FormSection name="seo">
										<FormSeo
											seoRobots={seoRobots}
										/>
									</FormSection>
								</TabPane>
							</TabContent>

							{error && (
								<Alert color="danger">
									<div dangerouslySetInnerHTML={{__html: error}}/>
								</Alert>
							)}
						</CardBody>

					</Card>
				</ModalBody>

				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type="submit"
					>
						<i className="fa fa-save"/>
						&nbsp;Enregistrer
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	};
}

const mapStateToProps = state => ({
	cmsStatus: getCmsStatus(state),
	seoRobots: getSeoRobots(state),
});

JobForm = connect(
	mapStateToProps,
)(JobForm);

export default reduxForm({
	form: "JobForm",
})(JobForm);
