import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, ModalBody, ModalFooter, Alert, Row, Col } from "reactstrap";
import { Field, reduxForm, getFormValues } from "redux-form";
import {
	WhFieldTxt,
	WhFieldCheckbox,
	WhFieldSelect,
	WhFieldTranslate,
} from "../../components/Form/whFields";
import { getContactTypes } from "../../reducers/params";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { connect } from "react-redux";

class ContactForm extends Component {
	state = {
		open: false,
	};

	render() {
		const {
			error,
			handleSubmit,
			submitting,
			currentValues,
			contactTypes,
			local,
		} = this.props;

		return (
			<Form onSubmit={handleSubmit} className="form-vertical">
				<ModalBody>
					<WhFieldTranslate
						label="Nom : "
						name="name"
						translatable={local}
						component={WhFieldTxt}
						type="text"
					/>

					<Field
						label="Nom technique : "
						name="slug"
						component={WhFieldTxt}
						type="text"
					/>

					<Field
						label="Afficher dans la liste des contacts "
						name="avaibleOnContactPage"
						component={WhFieldCheckbox}
						type="text"
					/>

					{error && (
						<Alert color="danger">
							<div
								dangerouslySetInnerHTML={{
									__html: error,
								}}
							/>
						</Alert>
					)}
				</ModalBody>

				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type={"submit"}
					>
						<i className="fa fa-save" />
						&nbsp;Enregistrer
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

const formName = "FormContact";

const rxf = reduxForm({ form: formName })(ContactForm);

ContactForm.defaultProps = {
	error: "",
	currentValues: {},
	contactTypes: [],
};
ContactForm.propTypes = {
	error: PropTypes.string,
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	currentValues: PropTypes.shape({}),
	contactTypes: PropTypes.array.isRequired,
};

export const mapStateToProps = state => ({
	currentValues: getFormValues(formName)(state),
	contactTypes: getContactTypes(state),
});

export default connect(mapStateToProps)(rxf);
