const introduction = {
	tplt: "/page/sectorEntry.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Introduction",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content:
						'<div class="row"><div class= "col-12"><h1><span>Découvrir</span><span class="bold"> suitétudes</span></h1><h2>Apprenez à connaitre suitétudes: notre offre de logement, nos services, notre société, etc… </h2></div></div>',
				},
			},
			content: {
				admin: {
					template: "BlocModule",
					title: "Liste des pages enfants",
				},
			},
		},
	},
};

export default {
	template: "sectorEntry",
	name: "Entré de secteur",
	editable: false,
	add: false,
	seo: {
		robots: "index,follow",
	},
	builder: [introduction],
};
