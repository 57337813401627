import React from "react";
import { Field, FormSection, FieldArray } from "redux-form";
import { Card, CardHeader, CardBody, Button } from "reactstrap";
import PopupBloc from "./FormBuilder/popupBloc/PopupBloc";
import * as tplts from "./FormBuilder/tplts";
import { WhFieldChildren } from ".";
import Actions from "./FormBuilder/Action";
import classnames from "classnames";

class FieldBuilder extends React.Component {
	_popupEditBloc = React.createRef();

	editBloc = (data, index = null) => {
		const { fields } = this.props;

		if (index != null) {
			fields.remove(index);
			fields.insert(index, data);
		} else {
			fields.push(data);
		}
	};

	orderUp = (fields, index) => {
		let newIndex = index - 1;
		if (newIndex < 0) newIndex = 0;

		fields.move(index, newIndex);
	};

	orderDown = (fields, index) => {
		let newIndex = index + 1;
		if (newIndex == fields.length) newIndex = index;

		fields.move(index, newIndex);
	};

	copy = data => {
		const toCopy = JSON.stringify(data);
		const copyText = document.getElementById("copyText");
		copyText.value = toCopy;
		copyText.select();
		document.execCommand("copy");
		alert("Copié !");
	};

	render = () => {
		const {
			fields,
			title,
			add,
			type,
			editable,
			defaultTemplates,
		} = this.props;

		return (
			<div>
				<div className="tplt-container">
					<div className={classnames([type])}>
						<PopupBloc
							ref={this._popupEditBloc}
							editBloc={this.editBloc}
						/>

						{fields.map((el, index) => {
							const data = fields.get(index);

							return (
								<Card key={index}>
									<CardHeader>
										<strong>
											{data &&
												data.admin &&
												data.admin.title}
										</strong>{" "}
										#{index}
										{editable && (
											<Actions
												remove={() =>
													fields.remove(index)
												}
												copy={() => this.copy(data)}
												editable={editable}
												orderDown={() =>
													this.orderDown(
														fields,
														index,
													)
												}
												orderUp={() =>
													this.orderUp(fields, index)
												}
												popupEdit={() =>
													this._popupEditBloc.current.handleOpen(
														{
															data,
															index,
														},
													)
												}
											/>
										)}
									</CardHeader>

									{data.admin.template == "BlocContainer" && (
										<CardBody className="tplt-content">
											{Object.keys(data.admin.fields).map(
												(objectKey, objectIndex) => {
													const children =
														data.admin.fields[
															objectKey
														];

													const Tplt =
														children.admin &&
														children.admin
															.template &&
														tplts[
															children.admin
																.template
														]
															? tplts[
																	children
																		.admin
																		.template
															  ]
															: null;

													/**
													 * Si ???
													 */
													if (
														![
															"BlocCollection",
															"BlocContainer",
															"BlocModule",
														].includes(
															children.admin
																.template,
														)
													) {
														return (
															<div
																key={
																	objectIndex
																}
															>
																{Tplt && (
																	<Field
																		name={`${el}.${objectKey}`}
																		field={
																			objectKey
																		}
																		component={
																			Tplt
																		}
																		data={
																			children
																		}
																	/>
																)}
															</div>
														);
													}

													/**
													 *
													 */
													if (
														[
															"BlocCollection",
														].includes(
															children.admin
																.template,
														)
													) {
														return (
															<div
																className="tpl-bloc"
																key={
																	objectIndex
																}
															>
																<FieldArray
																	name={`${el}.${objectKey}.contents`}
																	component={
																		WhFieldChildren
																	}
																	props={
																		children.admin
																	}
																/>
															</div>
														);
													}

													if (
														["BlocModule"].includes(
															children.admin
																.template,
														)
													) {
														return (
															<div
																className="tpl-bloc"
																key={
																	objectIndex
																}
															>
																<div>
																	{
																		children
																			.admin
																			.title
																	}
																</div>
															</div>
														);
													}

													return null;
												},
											)}
										</CardBody>
									)}

									{data.admin.template ==
										"BlocCollection" && (
										<CardBody className="tplt-content">
											<FieldArray
												name={`${el}.contents`}
												component={WhFieldChildren}
												add={data.admin.add}
												props={data.admin}
											/>
										</CardBody>
									)}
								</Card>
							);
						})}
					</div>
				</div>
				{add && (
					<Button
						type="button"
						color="primary"
						className="btn-block btn-lg"
						onClick={() =>
							this._popupEditBloc.current.handleOpen({
								defaultTemplates,
							})
						}
					>
						Ajouter un block {title ? `dans "${title}"` : null}
					</Button>
				)}
			</div>
		);
	};
}

export default FieldBuilder;

FieldBuilder.defaultProps = {
	editable: true,
};
