const introduction = {
	tplt: "/page/category.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Introduction",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content:
						'<h2 class="blog-title">actualités <b>Catégorie</b></h2>',
				},
			},
		},
	},
};

export default {
	template: "category",
	name: "Categorie de blog",
	seo: {
		robots: "index,follow",
	},
	builder: [introduction],
};
