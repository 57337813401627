import { fetch } from "../../../utils/fetch";
import QueryBuilder from "../../../components/WHTable/queryBuilder";
import configApi from "../../../constants/ApiConfig";

const endPoints = "/api/consents";

export const search = data => {
	const query = QueryBuilder(data);

	return fetch(`${configApi.url}${endPoints}`, "get", query);
};

export const update = data =>
	fetch(`${configApi.url}${endPoints}/${data.id}`, "put", data);

