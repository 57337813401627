export default str => {
	// const regex = /^([\"\\]*)\[\{([\\]*)/;
	// if (str.match(regex)) {
	// 	str = str.replace(/\\n/g, "");
	// 	str = str.replace(/&quot;Arial&quot;/g, "");
	//
	// 	const exp = new RegExp(
	// 		`${regex.exec(str)[2].replace(/\\/g, "\\\\")}`,
	// 		"gm",
	// 	);
	//
	// 	str = str.replace(exp, "");
	//
	// 	const subRegex = /^([\"\\]*)\[(.*)\]([\"\\]*)$/;
	//
	// 	str = `[${subRegex.exec(str)[2]}]`;
	//
	// 	//les classs bloques
	// 	str = str.replace(
	// 		new RegExp('(=")([/a-zA-Z-0-9 ;_:%,\\.\\?=#]+)(")', "gm"),
	// 		'=\\"$2\\"',
	// 	);
	//
	// 	str = str.replace(
	// 		new RegExp('(data-hover=")([/a-zA-Z !;_%,]+)(")', "gm"),
	// 		'=\\"$2\\"',
	// 	);
	// }

	try {
		while (typeof str == 'string') {
			str = JSON.parse(str)
		}
		return str;
	} catch (error) {
		alert(
			"Une erreur est survenue, merci de contacter votre administrateur et ne pas enregistrer.",
		);

		return str;
	}
};
