import React, { Component } from "react";
import Loading from "../../../components/Loading";
import Form from "./Form";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { SubmissionError } from "redux-form";
import ApiConfig from "../../../constants/ApiConfig";

import {
	Card,
	CardHeader,
	CardBody,
	Table,
	Badge,
	DropdownItem,
	Button,
} from "reactstrap";
import {
	TYPE_TITLE,
	TYPE_TXT,
	TYPE_COLLECTION,
	TYPE_IMG,
	TYPE_TXTIMG,
} from "../../../components/Form/whFields/constants";

import * as pageService from "../../../services/cmsPage";

class PageView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: {},
			loaded: false,
		};

		this.load = this.load.bind(this);
		this.edit = this.edit.bind(this);
	}

	componentWillMount() {
		this.load();
	}

	load() {
		if (this.props.match.params.id) {
			pageService.view(this.props.match.params.id).then(data => {
				this.setState({
					data,
					loaded: true,
				});
			});
		} else {
			this.setState({ loaded: true });
		}
	}

	edit(id) {
		this.props.history.push(`/pages/edit/${id}`);
	}

	render() {
		const { history, match } = this.props;
		const { data, loading } = this.state;

		if (!this.state.loaded) return <Loading />;
		else
			return (
				<div className="animated fadeIn">
					<Button
						color="success"
						className="pull-right"
						onClick={() => this.edit(this.state.data.id)}
					>
						<i className="fa fa-edit" /> Editer
					</Button>

					<h1>{this.state.data.title}</h1>

					<Card>
						<CardBody>
							{this.state.data.contents && this.state.data.contents.map((content, index) => {
								switch (content.type) {
									case TYPE_TITLE:
										return (
											<h2 key={index}>{content.title}</h2>
										);
										break;
									case TYPE_TXT:
										return (
											<div
												key={index}
												dangerouslySetInnerHTML={{
													__html: content.text,
												}}
											/>
										);
										break;
									case TYPE_TXTIMG:
										return (
											<div key={index}>
												<img
													src={`${
														ApiConfig.assetUrl
													}/${content.media.url}`}
													style={{
														maxWidth: "100%",
													}}
												/>
											</div>
										);
										break;
									case TYPE_IMG:
										return (
											<div key={index}>
												<img
													src={`${
														ApiConfig.assetUrl
													}/${content.media.url}`}
													style={{
														maxWidth: "100%",
													}}
												/>
											</div>
										);
										break;
								}
							})}
						</CardBody>
					</Card>
				</div>
			);
	}
}

export default PageView;
