import {
	containerParagraphe1,
	containerParagraphe2,
	picture,
	video,
	foliosLeftTextRight,
	sliderAstuce,
	sliderFlap01,
	twoColumns,
	threeColumns,
	fourColumns,
	sliderNavLeftPhotoTxt,
} from "../block";

import sliderPhotoVideoLeft from "../block/sliderPhotoVideoLeft";
import sliderPhotoVideoRight from "../block/sliderPhotoVideoRight";

export const builder = {
	admin: {
		editable: true,
		add: true,
		tplt: "/builder",
		template: "BlocCollection",
		title: "Body",
		defaultTemplates: [
			foliosLeftTextRight,
			sliderFlap01,
			twoColumns,
			threeColumns,
			fourColumns,
			sliderAstuce,
			sliderNavLeftPhotoTxt,
			containerParagraphe1,
			containerParagraphe2,
			picture,
			video,
			sliderPhotoVideoLeft,
			sliderPhotoVideoRight
		],
	},
};

export default {
	template: "page",
	name: "Page",
	default: true,
	seo: {
		robots: "index,follow",
	},
	builder: [builder],
};
