import React, {Component} from 'react';
import {Form} from 'reactstrap';
import {reduxForm} from 'redux-form'
import {FieldTxt} from '../../../components/Form/wh-field';
import {ModalBody, ModalFooter} from 'reactstrap';
import LaddaButton, {EXPAND_LEFT} from 'react-ladda';

import {connect} from 'react-redux';
import FieldTranslate from "../../../components/Form/whFields/FieldTranslate";
import PropTypes from "prop-types";
import {getLocal} from "../../../reducers/params";

class PromotionForm extends Component {
    state = {
        open: false
    };

    render = () => {
        const {local,error, handleSubmit, submitting, currentValues} = this.props;

        return (

            <Form onSubmit={handleSubmit} className="form-horizontal">

                <ModalBody>

                    <FieldTranslate
                        label="Nom :"
                        name="name"
                        translatable={local}
                        component={FieldTxt}
                        type="text"
                    />

                </ModalBody>

                <ModalFooter>

                    <LaddaButton
                        className="btn btn-success btn-ladda"
                        loading={submitting}
                        data-style={EXPAND_LEFT}
                        type={'submit'}
                    >
                        <i className="fa fa-save"> </i>
                        &nbsp;Enregistrer
                    </LaddaButton>

                </ModalFooter>

            </Form>

        );

    }
}

PromotionForm.propTypes = {
    error: PropTypes.shape({}).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    local: PropTypes.string
};
PromotionForm.defaultProps = {
    error: "",
};

const mapStateToProps = (state) => ({
    local: getLocal(state)
});

PromotionForm = connect(
    mapStateToProps
)(PromotionForm);

export default reduxForm({
    form: 'PromotionForm'
})(PromotionForm);
