import React, { Component } from "react";
import { Form, Button, ModalBody, ModalFooter, Alert } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import {
	WhFieldTxt,
	WhFieldSelectAsync,
	WhFieldSelect,
	WhFieldCheckbox,
} from "../../../../components/Form/whFields";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import * as tpltService from "../../../../services/cmsTemplate";
import {
	TYPE_TXTIMG,
	TYPE_COLLECTION,
	TYPE_TXT,
	TYPE_IMG,
} from "../../../../components/Form/whFields/constants";

class FormPopup extends Component {
	loadParents = input =>
		tpltService
			.search({
				filtered: [
					{ id: "name", value: input },
					{ id: "type", value: "collection" },
				],
			})
			.then(data => {
				let lst = data["hydra:member"].map((el, index) => {
					return { id: el.id, label: `${el.name}` };
				});
				return { options: lst };
			});

	render() {
		const { error, handleSubmit, pristine, reset, submitting } = this.props;

		return (
			<Form onSubmit={handleSubmit} className="form-vertical">
				<ModalBody>
					<Field
						label="Type : "
						name="type"
						component={WhFieldSelect}
						options={[
							{ value: TYPE_IMG, name: "Image" },
							{ value: TYPE_TXT, name: "Text" },
							{
								value: TYPE_TXTIMG,
								name: "Text et Image",
							},
							{
								value: TYPE_COLLECTION,
								name: "Collection",
							},
						]}
					/>
					<Field
						label="Collection parente : "
						name="parent"
						component={WhFieldSelectAsync}
						loadOptions={this.loadParents}
					/>
					<Field
						label="Nom : "
						name="name"
						component={WhFieldTxt}
						type="text"
						placeholder="Saisir un nom"
					/>
					<Field
						label="Nom de la classe : "
						name="slug"
						component={WhFieldTxt}
						type="text"
						placeholder="Saisir un slug"
					/>
					<Field
						label="Type d'appel : "
						name="renderType"
						component={WhFieldSelect}
						options={[
							{ value: "twig", name: "Twig" },
							{ value: "controller", name: "Controller" },
						]}
					/>
					<Field
						label="Chemin du template : "
						name="route"
						component={WhFieldTxt}
						placeholder="App\Controller.."
					/>

					<Field
						label="Note : "
						name="note"
						component={WhFieldTxt}
						type="textarea"
					/>
					<Field
						label="Champ technique : "
						name="slugField"
						component={WhFieldTxt}
					/>
					<Field
						label="Champ title : "
						name="titleField"
						component={WhFieldTxt}
					/>
					<Field
						label="Champ subTitle : "
						name="subTitleField"
						component={WhFieldTxt}
					/>
					<Field
						label="Champ url : "
						name="urlField"
						component={WhFieldTxt}
					/>
					<Field
						label="Champ media : "
						name="mediaField"
						component={WhFieldTxt}
					/>
					<Field
						label="Champ text : "
						name="textField"
						component={WhFieldTxt}
					/>
					<Field
						label="Champ body : "
						name="bodyField"
						component={WhFieldTxt}
					/>

					{error && (
						<Alert color="danger">
							<div
								dangerouslySetInnerHTML={{
									__html: error,
								}}
							/>
						</Alert>
					)}
				</ModalBody>
				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type={"submit"}
					>
						<i className="fa fa-save" /> Enregistrer{" "}
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

FormPopup = reduxForm({
	form: "TpltFormPopup",
})(FormPopup);

export default FormPopup;
