import React, { Component } from "react";
import { Form, ModalBody, ModalFooter, Alert } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import {
	WhFieldTxt,
	WhFieldSelect,
} from "../../../../components/Form/whFields";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";

class FormPopup extends Component {
	render() {
		const {
			error,
			handleSubmit,
			pristine,
			reset,
			tplts,
			submitting,
		} = this.props;

		return (
			<Form onSubmit={handleSubmit} className="form-vertical">
				<ModalBody>
					<Field
						label="Titre : "
						name="title"
						component={WhFieldTxt}
						type="text"
						placeholder="Saisir un titre"
						bsSize="lg"
					/>

					<Field
						label="Modèle :"
						name="template"
						required
						component={WhFieldSelect}
						type="select"
						options={tplts}
					/>

					{error && (
						<Alert color="danger">
							<div
								dangerouslySetInnerHTML={{
									__html: error,
								}}
							/>
						</Alert>
					)}
				</ModalBody>
				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type={"submit"}
					>
						<i className="fa fa-save" /> Enregistrer{" "}
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

FormPopup = reduxForm({
	form: "PageCreateFormPopup",
})(FormPopup);

export default FormPopup;
