import React, {Component} from 'react';
import {Modal, ModalHeader} from 'reactstrap';
import Form from './Form';
import * as seoService from "../../../../services/seo";
import {SubmissionError} from 'redux-form';
import {toast} from 'react-toastify';
import Loading from '../../../../components/Loading';
import Locals from "../../../../components/Locals";

class CreateEditPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            data: {},
            loaded: true
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleOpen(id = null) {

        this.setState({
            open: true
        });


        if (id) {
            this.setState({
                loaded: false
            });

            seoService.view(id).then((data) => {

                this.setState({
                    data,
                    loaded: true
                });
            });
        }
    }

    handleClose() {
        this.setState({
            open: false,
            data: {}
        });
    }

    onSubmit(data) {

        const edit = (!data.id) ? seoService.create : seoService.update;

        return edit(data).then((data) => {
            this.props.newData(data);

            toast.success('Enregistrement réussi');

            this.handleClose();
        }).catch((error) => {
            throw new SubmissionError(error);
        });
    }

    render() {
        const {className} = this.props;
        const {loaded} = this.state;

        return (

            <Modal isOpen={this.state.open} className={className} fade={false}>

                <ModalHeader toggle={() => this.handleClose()}>Editer</ModalHeader>

                {!loaded &&
                <Loading/>
                }

                {loaded &&
                <div>
                    <Locals/>
                    <Form onSubmit={this.onSubmit} initialValues={this.state.data}/>
                </div>
                }

            </Modal>

        );

    }
}

export default CreateEditPopup
