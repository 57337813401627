import React from "react";
import CKEditor from "ckeditor4-react";
import { change } from "redux-form";
import ApiConfig from "../../../../../constants/ApiConfig";

class Paragraph extends React.Component {
	onChange = evt => {
		try {
			const data = evt.editor.getData();
			this.props.meta.dispatch(
				change(this.props.meta.form, this.props.input.name, data),
			);
		} catch (error) {}
	};

	//Source, style, blocks, icones
	returnEditor = data => {
		CKEditor.editorUrl = ApiConfig.ckeditorInstance;

		return (
			<CKEditor
				data={data}
				type="inline"
				config={{
					startupOutlineBlocks: "true",
					autoParagraph: false,
					toolbar: [
						[
							"Heading",
							"Bold",
							"Italic",
							"Link",
							"FontSize",
							"lineheight",
							"Format",
							"Styles",
							"Source",
							"Sourcedialog",
							"Showblock",
							"Table",
							"HorizontalRule",
							"JustifyLeft",
							"JustifyCenter",
							"JustifyRight",
							"JustifyBlock",
							"ShowBlocks",
							"Glyphicons",
						],
					],
				}}
				onChange={this.onChange}
			/>
		);
	};

	returnContent = () => {
		const { data, input } = this.props;

		if (input && input.value) {
			return this.returnEditor(input.value);
		}

		if (data && data.admin && data.admin.content) {
			return this.returnEditor(data.admin.content);
		}
	};

	render = () => {
		return <div className="tpl-txt">{this.returnContent()}</div>;
	};
}

export default Paragraph;
