const introduction = {
	tplt: "/page/contact.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Introduction",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content:
						'<h1><span>Une question sur nos résidences ? Laissez nous un message !</span></h1><div class="soustitre"><h2 class= "black" > Nous contacter </h2></div >',
				},
			},
			formulaire: {
				admin: {
					template: "BlocModule",
					title: "Formulaire",
				},
			},
			flap: {
				admin: {
					template: "BlocModule",
					title: "FlapContact",
				},
			},
		},
	},
};

export default {
	template: "contact",
	name: "Page Contact",
	editable: false,
	add: false,
	seo: {
		robots: "index,follow",
	},
	builder: [introduction],
};
