import React, {Component} from 'react';
import {Form} from 'reactstrap';
import {Field, getFormValues, reduxForm} from 'redux-form'
import {FieldTxt} from '../../../components/Form/wh-field';
import {ModalBody, ModalFooter, Alert} from 'reactstrap';
import LaddaButton, {EXPAND_LEFT} from 'react-ladda';
import {getLocal} from "../../../reducers/params";
import {connect} from "react-redux";
import FieldTranslate from "../../../components/Form/whFields/FieldTranslate";

class postTypeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };
    }

    render() {
        const {local,error, handleSubmit, submitting} = this.props;

        return (

            <Form onSubmit={handleSubmit} className="form-horizontal">

                <ModalBody>

                    <FieldTranslate
                        label="Nom :"
                        name="name"
                        translatable={local}
                        component={FieldTxt}
                        type="text"
                    />

                    {error &&
                    <Alert color="danger">
                        <div dangerouslySetInnerHTML={{__html: error}}/>
                    </Alert>
                    }

                </ModalBody>

                <ModalFooter>

                    <LaddaButton
                        className="btn btn-primary btn-ladda"
                        loading={submitting}
                        data-style={EXPAND_LEFT}
                        type={'submit'}
                    >
                        <i className="fa fa-save"></i>
                        &nbsp;Enregistrer
                    </LaddaButton>

                </ModalFooter>

            </Form>

        );

    }
}

postTypeForm = reduxForm({
    form: 'postTypeForm'
})(postTypeForm);

const mapState = state => ({
    local: getLocal(state)
});


postTypeForm = connect(mapState)(postTypeForm);

export default postTypeForm;
