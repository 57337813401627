import React, { Component } from "react";
import {Modal, ModalHeader, ModalBody, Button} from "reactstrap";
import Dropzone from "react-dropzone";
import {toast} from "react-toastify";
import * as schoolService from "../../services/school";

class ModalImport extends Component {

	constructor(props) {
		super(props);
		this.state = {
			open: false,
			file: null
		};
	}

	handleOpen = () => {
		this.setState({
			open: true,
		});
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	onDrop = file => {
		this.setState({
			file,
		});
	};

	import = () => {
		const { file } = this.state;
		const { initialValues } = this.props;

		if (!file) {
			toast.error("Pas de fichier séléctionné");
			return;
		}

		let formData = new FormData();
		formData.append("file", file[0]);

		schoolService
			.importSchool(formData)
			.then(() => {
				this.setState({
					file: null,
				});
				toast.success("Import réussi");
			})
			.catch(error => {
				if (error && error.message) {
					toast.error(error.message);
				}
			});
	};

	render() {
		const { className } = this.props;

		return (
			<Modal isOpen={this.state.open} className={className} fade={false}>
				<ModalHeader toggle={() => this.handleClose()}>
					Importer des Ecoles
				</ModalHeader>

				<ModalBody>
					<Dropzone
						className="dropzone"
						multiple={false}
						onDrop={this.onDrop}
					>
						{({
							  isDragActive,
							  isDragReject,
							  acceptedFiles,
							  rejectedFiles,
						  }) => {
							if (isDragActive) {
								return "This file is authorized";
							}
							if (isDragReject) {
								return "This file is not authorized";
							}
							return acceptedFiles.length ||
							rejectedFiles.length
								? `Accepted ${
									acceptedFiles.length
								}, rejected ${
									rejectedFiles.length
								} files`
								: "Déposer votre fichier csv dans cette zone";
						}}
					</Dropzone>
					<Button
						color="btn btn-primary btn-block"
						onClick={this.import}
					>
						Importer
					</Button>
				</ModalBody>
			</Modal>
		);
	}
}

export default ModalImport;
