/* eslint-disable import/no-unresolved */
import {
	SET_PARAMS,
	SET_LOCAL,
	SET_DEFAULT_LOCAL,
	PARAMS_RK,
	PARAMS_LOCAL_RK,
	SET_TEMPLATES,
} from "./constants";
import { getParams } from "../../services/parameter";
import * as tpltService from "../../services/cmsTemplate";

export const setParams = () => async (dispatch, getState) => {
	const params = await getParams();

	if (params) {
		dispatch({
			type: SET_PARAMS,
			payload: params,
		});

		const state = getState();
		if (!state[PARAMS_RK][PARAMS_LOCAL_RK]) {
			dispatch({
				type: SET_LOCAL,
				payload: params.locals[0],
			});
		}
		dispatch({
			type: SET_DEFAULT_LOCAL,
			payload: params.locals[0],
		});
	}

	dispatch(setTemplates());
};

export const setTemplates = () => async dispatch => {
	const templates = await tpltService.search({});

	const data = [];

	templates["hydra:member"].map(el => {
		if (el.parent) {
			let parent = data.filter(theParent => theParent.id == el.parent.id);
			parent = parent[0];
			if (!parent.childrens) parent.childrens = [];
			parent.childrens.push(el);
		} else {
			data.push(el);
		}
	});

	dispatch({
		type: SET_TEMPLATES,
		payload: data,
	});
};

export const setLocal = local => {
	return {
		type: SET_LOCAL,
		payload: local,
	};
};

export default null;
