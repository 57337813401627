import React from "react";

class CollectionContent extends React.Component {
	render = () => {

		return "collection";
	};
}

export default CollectionContent;
